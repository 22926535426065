import { detect } from 'detect-browser';

const setHeight = () => {
  document.body.style.setProperty('--window-height', `${window.innerHeight}px`);
};

const initializeWindowHeight = () => {
  window.addEventListener('resize', setHeight);

  setHeight();
};

const initialize = (): void => {
  const browser = detect();
  if (browser?.os === 'iOS') {
    initializeWindowHeight();
  }
};
export default initialize;
