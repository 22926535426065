/* eslint-disable @typescript-eslint/indent */
import { useCallback, useEffect, useRef, useState } from 'react';

type TimeHandler = (...args: unknown[]) => void;

const useInterval = (
  callback: TimeHandler,
  delay: number,
): {
  reset: () => void;
  stop: () => void;
  start: () => void;
  exec: () => void;
} => {
  const [state, setState] = useState(false);
  const [stopped, setStopped] = useState(false);

  const callbackRef = useRef<TimeHandler>(callback);

  const handleReset = useCallback(() => setState((old) => !old), []);
  const handleStop = useCallback(() => setStopped(true), []);
  const handleStart = useCallback(() => setStopped(false), []);
  const handleExec = useCallback(() => callbackRef?.current(), []);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!stopped) {
      const handler = (...args: unknown[]) => callbackRef.current(...args);
      const interval = setInterval(handler, delay);
      return () => clearInterval(interval);
    }

    return () => null;
  }, [delay, state, stopped]);

  return {
    reset: handleReset,
    stop: handleStop,
    start: handleStart,
    exec: handleExec,
  };
};
export default useInterval;
