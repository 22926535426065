/* eslint-disable @typescript-eslint/indent */
import styled, { keyframes, css } from 'styled-components';

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;
const slideInBottom = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;
const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;
const slideOutBottom = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;
const slideInSmall = keyframes`
  from {
    transform: translateX(10%);
  }
  to {
    transform: translateX(0);
  }
`;
const slideOutSmall = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(10%);
  }
`;
const slideInMedium = keyframes`
  from {
    transform: translateX(25%);
  }
  to {
    transform: translateX(0);
  }
`;
const slideOutMedium = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(25%);
  }
`;

interface IOverlayProps {
  bottom?: boolean;
  isVisible: boolean;
  zindex?: number;
}
export const Overlay = styled.div<IOverlayProps>`
  position: fixed;
  display: flex;
  align-items: ${({ bottom }) => (bottom ? 'end' : 'center')};
  place-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ zindex }) => zindex || '9999'};
  overflow: hidden;
  display: flex;
  justify-content: ${({ bottom }) => (bottom ? 'end' : 'center')};
  backdrop-filter: blur(2px) brightness(0.6);
  will-change: backdrop-filter;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  transition: opacity 100ms ease, visibility 150ms linear;
  will-change: opacity, visibility;
`;
interface IModalWrapperProps {
  bg?: string;
  width?: string;
  bottom?: boolean;
  isVisible: boolean;
  allowFullscreen: boolean;
}
export const ModalWrapper = styled.div<IModalWrapperProps>`
  ${({ bottom }) =>
    bottom &&
    css`
      bottom: 0;
      position: sticky;
    `}
  width: ${({ width }) => width || '450px'};
  height: auto;
  background-color: ${({ theme, bg }) => bg || theme.palette.background};
  box-shadow: -2px 2px 7px -1px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  overflow: hidden;
  padding: 16px;
  z-index: 99999; /* Garante que fique sobreposta aos outros elementos */
  ${({ isVisible, bottom }) =>
    isVisible
      ? css`
          animation: ${!bottom ? slideIn : slideInBottom} 0.5s forwards;
        `
      : css`
          animation: ${!bottom ? slideOut : slideOutBottom} 0.5s forwards;
        `}
  ${({ bottom }) =>
    bottom &&
    css`
      width: 100%;
    `}
  @media (max-width: 1280px) {
    ${({ isVisible, bottom }) =>
      isVisible
        ? css`
            animation: ${!bottom ? slideInMedium : slideInBottom} 0.5s forwards;
          `
        : css`
            animation: ${!bottom ? slideOutMedium : slideOutBottom} 0.5s forwards;
          `}
  }
  @media (max-width: 700px) {
    width: 100%;
    height: ${({ allowFullscreen }) => (allowFullscreen ? '100%' : 'auto')};
    border-radius: ${({ bottom }) => (!bottom ? '0' : '16px 16px  0px 0px')};
    ${({ isVisible, bottom }) =>
      isVisible
        ? css`
            animation: ${!bottom ? slideInSmall : slideInBottom} 0.5s forwards;
          `
        : css`
            animation: ${!bottom ? slideOutSmall : slideOutBottom} 0.5s forwards;
          `}
  }
`;

export const ContainerModal = styled.div<{ bottom: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${({ bottom }) =>
    !bottom &&
    css`
      padding-top: 40px;

      @media (max-width: 700px) {
        padding-top: 20px;
      }
    `}
`;
export const Header = styled.div`
  position: fixed;
  max-width: 40px;
  max-height: 40px;
  top: 16px;
  display: flex;
  align-items: center;
`;

export const IconButton = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
  background: ${({ theme }) => theme.palette.background_white};
  border: none;
  padding: 5px;
  border-radius: 100%;
  transition: filter 500ms ease;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
`;
