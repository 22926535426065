/* eslint-disable no-plusplus */
export const getDeliveryTypeName = (name: string): string => {
  switch (name) {
    case 'DRIVE_THRU':
      return 'Drive Thru';
    case 'ON_TABLE':
      return 'Na Mesa';
    case 'CHECKOUT':
      return 'Retira/Take-Away';
    default:
      return 'Delivery';
  }
};

export const getPaymentTypeName = (name: string): string => {
  switch (name) {
    case 'CASH':
      return 'Dinheiro';
    case 'DEBIT_CARD':
      return 'Cartão de Débito';
    case 'PIX':
      return 'Pix';
    case 'PIC_PAY':
      return 'PicPay';
    case 'AME':
      return 'Ame';
    case 'FB_PAYMENT':
      return 'Whatsapp (Facebook Payment)';
    case 'CHECK':
      return 'Cheque';
    case 'VOUCHER':
      return 'Vale Refeição';
    case 'MONTHLY_PAYER':
      return 'Mensalista';
    default:
      return 'Cartão de Crédito';
  }
};

export const compareString = (text?: string, ...strs: string[]): boolean =>
  text ? strs.includes(text?.toLowerCase().trim()) : false;

export const normalizeString = (string: string): string =>
  string
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim();

export const levenshteinDistance = (a: string, b: string): number => {
  let tmp;
  if (a.length === 0) {
    return b.length;
  }
  if (b.length === 0) {
    return a.length;
  }
  if (a.length > b.length) {
    tmp = a;
    a = b;
    b = tmp;
  }

  let i;
  let j;
  let res;
  const alen = a.length;
  const blen = b.length;
  const row = Array(alen);
  for (i = 0; i <= alen; i++) {
    row[i] = i;
  }

  for (i = 1; i <= blen; i++) {
    res = i;
    for (j = 1; j <= alen; j++) {
      tmp = row[j - 1];
      row[j - 1] = res;
      res = b[i - 1] === a[j - 1] ? tmp : Math.min(tmp + 1, Math.min(res + 1, row[j] + 1));
    }
  }
  return res;
};

export const similarity = (string0?: string, string1?: string, normalize?: boolean): number => {
  if (!string0 || !string1) {
    return 0;
  }

  let longer = string0;
  let shorter = string1;
  if (string0.length < string1.length) {
    longer = string1;
    shorter = string0;
  }

  const longerNorm = normalize ? normalizeString(longer) : longer;
  const shorterNorm = normalize ? normalizeString(shorter) : shorter;

  const longerLength = longerNorm.length;
  if (longerLength === 0) {
    return 1.0;
  }

  return (longerLength - levenshteinDistance(longerNorm, shorterNorm)) / longerLength;
};

export const upperCaseFirstChar = (string: string): string =>
  `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

export const upperCaseFirstCharEachWord = (string: string): string =>
  string
    .split(' ')
    .map((char) => upperCaseFirstChar(char.toLowerCase()))
    .join(' ');
