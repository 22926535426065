import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

const Container = styled(ContentLoader).attrs(({ theme }) => ({
  backgroundColor: theme.palette.shimmer_background,
  foregroundColor: theme.palette.shimmer_color,
}))`
  height: 40px;
  width: 250px;
  margin-right: 32px;
`;

export default Container;
