import styled, { css } from 'styled-components';
import color from 'color';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      color: #4db6ac;
    }
  }
`;

export const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  .counter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    p {
      color: #4db6ac;
    }
  }
`;

export const Image = styled.div`
  width: 50px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 35px;
    height: 35px;
  }
`;
export const QrCode = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 250px;
    height: 250px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  }

  @media (max-height: 700px) {
    img {
      width: 200px;
      height: 200px;
    }
  }
  @media (max-width: 700px) {
    img {
      width: 200px;
      height: 200px;
    }
  }
`;

export const PixHash = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  p {
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
  }
`;

export const Button = styled.button<{ back?: boolean }>`
  display: flex;
  width: 100%;
  padding: 12px;
  background: ${({ theme, back }) => (back ? theme.palette.primary : theme.palette.background)};
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  border-radius: 8px;
  gap: 2rem;
  justify-content: ${({ back }) => (back ? 'center' : 'start')};
  align-items: center;
  cursor: pointer;
  border: none;

  ${({ back }) =>
    back &&
    css`
      margin-top: 1rem;
    `}

  p {
    color: ${({ theme, back }) => (back ? theme.palette.background_white : null)};
  }

  transition: 400ms;

  &:hover {
    transform: scale(1.02);
  }

  @media (max-height: 840px) {
    padding: 8px;
  }
`;

export const LinkButton = styled.a`
  display: flex;
  text-decoration: none;
  width: 100%;
  padding: 12px;
  background: ${({ theme }) => theme.palette.background};
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  border-radius: 8px;
  gap: 2rem;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  border: none;

  transition: 400ms;

  &:hover {
    transform: scale(1.02);
  }

  @media (max-height: 840px) {
    padding: 8px;
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  border: none;
  background: ${({ theme }) => theme.palette.background_white};
  border: 1px solid ${({ theme }) => theme.palette.control};
  padding: 16px 5px 16px 5px;
  border-radius: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:-webkit-autofill,
  &:focus:-webkit-autofill {
    box-shadow: 0 0 0px 1000px
      ${({ theme }) => color(theme.palette.primary).lighten(0.9).alpha(0.8).rgb().string()} inset;
  }
`;
