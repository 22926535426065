/* eslint-disable react/jsx-indent */
import React, { useRef, useEffect, useCallback, SetStateAction } from 'react';

import { FiCopy } from 'react-icons/fi';
import { RiWhatsappLine } from 'react-icons/ri';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Lottie from 'lottie-react';
import animationData from '../../assets/animations/loading-check-2.json';
import pixsvg from '../../assets/icons/pix-flat.svg';

import {
  Image,
  QrCode,
  Center,
  Button,
  PixHash,
  Wrapper,
  Container,
  LinkButton,
  StyledInput,
} from './styles';

import { IPix } from '../../models/IOrder';

import { usePix } from '../../hooks/pix';
import { useToast } from '../../hooks/toast';

import theme from '../../styles/themes/light.theme';

import Modal from '../Modal';
import PixCounter from '../PixCounter';

interface IModalPixProps {
  pix: IPix;
  open: boolean;
  handleCheckPix: () => Promise<void>;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
const ModalPix: React.FC<IModalPixProps> = ({ pix, open, setOpen, setLoading, handleCheckPix }) => {
  const { deletePix } = usePix();
  const { addToast } = useToast();
  const hashRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      handleCheckPix();
    }, 5000);

    return () => clearInterval(interval);
  }, [addToast, handleCheckPix]);

  const handleClose = useCallback(async () => {
    try {
      setLoading(true);
      await deletePix(pix.pixId);
      setOpen(false);
    } catch (error) {
      addToast({ description: `Erro ao deletar chave pix: ${error}` });
    } finally {
      setLoading(false);
    }
  }, [addToast, deletePix, pix.pixId, setLoading, setOpen]);

  const handleCopyFallback = () => {
    // Cria um elemento temporário
    const tempInput = document.createElement('textarea');
    tempInput.value = pix.qrCodeText;
    document.body.appendChild(tempInput);

    // Seleciona e copia o texto
    tempInput.select();
    document.execCommand('copy'); // Método mais antigo, mas funcional em mais navegadores móveis
    document.body.removeChild(tempInput);

    // Notifica o usuário
    addToast({
      description: 'Código copiado para a área de transferência!',
      type: 'success',
    });
  };

  const handleCopy = async () => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(pix.qrCodeText);
        addToast({
          description: 'Código copiado para a área de transferência!',
          type: 'success',
        });
      } catch (error) {
        console.error('Erro ao copiar:', error);
        addToast({ description: 'Falha ao copiar o código.', type: 'error' });
      }
    } else {
      handleCopyFallback();
    }
  };

  const hash = pix.qrCodeText;
  const text = '*Abaixo segue o código do PIX!:*\n\n\n';
  const message = `${text} ${hash}`;
  const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      onCancel={handleClose}
      zindex={99999}
      bg={theme.palette.background_white}
    >
      {pix.status === 'APPROVED' ? (
        <Center>
          <Lottie
            animationData={animationData}
            // segments={[10, 53]}
            height={200}
            width={200}
          />
          <strong>pagamento aprovado!</strong>
        </Center>
      ) : pix.status === 'CANCELLED' ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
      ) : (
        <Wrapper>
          <div className="title">
            <h1>Pix Gerado!</h1>
            <Image>
              <LazyLoadImage src={pixsvg} alt="Dinheiro" />
            </Image>
          </div>
          <Container>
            <div className="counter">
              <p>chave expira em</p>
              <PixCounter handleClose={handleClose} />
            </div>
            <QrCode>
              <LazyLoadImage src={pix.qrCodeImage} alt="QRCode" />
            </QrCode>
            <PixHash>
              <StyledInput readOnly defaultValue={pix.qrCodeText} ref={hashRef} />
            </PixHash>

            <Button onClick={handleCopy}>
              <FiCopy size={22} />
              <p>Copiar</p>
            </Button>

            <LinkButton href={whatsappUrl} target="_blank" rel="noreferrer">
              <RiWhatsappLine
                size={22}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(whatsappUrl, '_blank');
                }}
              />
              <p>Enviar por Whatsapp</p>
            </LinkButton>
          </Container>

          <Button back onClick={handleClose}>
            <p>Voltar</p>
          </Button>
        </Wrapper>
      )}
    </Modal>
  );
};

export default ModalPix;
