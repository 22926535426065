/* eslint-disable indent */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useRef, useMemo, useState, useEffect, useCallback } from 'react';

import { v4 as uuid } from 'uuid';
import { FiX } from 'react-icons/fi';

import { useCart } from '../../hooks/cart';
import { useOrder } from '../../hooks/order';
import { useCompany } from '../../hooks/company';
import { formatToMoney } from '../../utils/format';

import ICartItem, { IGroupChangedData } from '../../models/ICartItem';

import { IComplementGroupRef } from '../ComplementsGroup';

import pizzaPlaceholder from '../../assets/icons/pizza-placeholder.png';
import pizzaSlicePlaceholder from '../../assets/icons/pizza-slice-placeholder.png';

import {
  Top,
  Name,
  Header,
  Footer,
  Category,
  Comments,
  Container,
  PrepareTime,
  ProductInfo,
  CloseButton,
  ProductImage,
  ComplementsGroups,
  ProductDescription,
  Total,
  Separator,
} from './styles';

import ICategory from '../../models/ICategory';
import { IPizzaSize } from '../../models/IPizza';
import IComplement from '../../models/IComplement';
import IComplementsGroup from '../../models/IComplementsGroup';
import IProduct, { IProductPizzaSize } from '../../models/IProduct';
import { PizzaCharges, PizzaComplementsGroupIds } from '../../enum/Pizza';

import { useProducts } from '../../hooks/products';
import { useProductGroups } from '../../hooks/product-groups';

import { orderArrayByPosition } from '../../utils/array';
import { LocalStorageKeys } from '../../enum/Storage';
import { validateComplementsGroups } from '../../utils/complement';
import getPizzaCharge from '../../utils/charge';

import lazyWithRetry from '../../utils/react';
import SizeSelector, { ISize } from '../SizeSelector';
import BackToProductsComponent from '../BackToProductsComponent';
import FinishComponent from '../FinishComponent';

const ComplementsGroup = lazyWithRetry(() => import('../ComplementsGroup'));

const ErrorModal = lazyWithRetry(() => import('../ErrorModal'));
const ProductIcon = lazyWithRetry(() => import('../ProductIcon'));
// const AmountInput = lazyWithRetry(() => import('../AmountInput'));
const CustomPizzaDetails = lazyWithRetry(() => import('../CustomPizzaDetails'));
const Loading = lazyWithRetry(() => import('../Loading'));

interface IProductDetailsProps {
  visible: boolean;
  product?: IProduct;
  pizzaSize?: IProductPizzaSize | null;
  pizzaCategory?: ICategory;
  cartItem?: ICartItem;
  isCustomPizza?: boolean;
  onCloseClick: (id?: number) => void;
}

const ProductDetails: React.FC<IProductDetailsProps> = ({
  visible,
  product,
  pizzaCategory,
  pizzaSize,
  cartItem,
  isCustomPizza,
  onCloseClick,
}) => {
  const complementsGroupRefs = useRef<IComplementGroupRef[]>([]);
  const ref = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLHeadingElement | null>(null);

  const { table } = useOrder();
  const { products } = useProducts();
  const { productGroups } = useProductGroups();
  const { addToCart } = useCart();
  const { company, isShowcase } = useCompany();

  const [amount, setAmount] = useState(1);
  const [comments, setComments] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedPizzaSize, setSelectedPizzaSize] = useState<IPizzaSize | null>(null);
  const [selectedPizzaSlicesCount, setSelectedPizzaSlicesCount] = useState<number | null>(null);
  const [selectedComplementsGroups, setSelectedComplementsGroups] = useState<IGroupChangedData[]>(
    [],
  );

  const [selectedSize, setSelectedSize] = useState<ISize | null>(null);

  const productSizes = useMemo<ISize[]>(() => {
    if (!product || pizzaCategory) return [];

    if (!product?.halfPrice) return [];

    const sizes = [
      {
        id: uuid().toString(),
        name: 'Inteiro',
        price: product?.salePrice ? product?.salePrice : product?.unitPrice,
      },
      { id: uuid().toString(), name: 'Meio', price: product.halfPrice },
    ];

    setSelectedSize(sizes.find((size) => size.price === cartItem?.unitPrice) || sizes[0]);
    return sizes;
  }, [product, pizzaCategory, cartItem]);

  const reset = () => {
    setAmount(1);
    setComments('');
    setErrorMessage('');
    setSelectedComplementsGroups([]);
    complementsGroupRefs.current?.forEach((groupRef) => groupRef.clear());
    complementsGroupRefs.current = [];
    document.getElementById('drawer')?.scrollTo(0, 0);
  };

  useEffect(() => {
    if (visible) {
      document.getElementById('drawer')?.scrollTo(0, 0);
    } else {
      reset();
    }
  }, [visible]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (event.target && event?.target?.id !== 'drawer') {
        if (
          event.path &&
          !event.path.find(
            (p) =>
              p.id === 'drawer' ||
              p.id === 'toast' ||
              p.id === 'error-modal' ||
              (p.className && p.className.includes && p.className.includes('ReactModal__Overlay')),
          )
        )
          onCloseClick();
      }
    },
    [onCloseClick],
  );

  const clearComplementsGroups = () => {
    complementsGroupRefs?.current?.forEach((groupRef) => groupRef?.clear());
  };

  const handlePizzaSizeClick = (id: number) => {
    const foundPizzaSize = pizzaCategory?.pizzaSizes?.find((size) => size.id === id);
    setSelectedPizzaSize(foundPizzaSize || null);
    setSelectedComplementsGroups([]);
    clearComplementsGroups();
  };

  const handlePizzaSlicesCountChange = (value: number | null) => {
    setSelectedPizzaSlicesCount(value);
    setSelectedComplementsGroups([]);
    clearComplementsGroups();
  };

  useEffect(() => {
    if (localStorage?.getItem(LocalStorageKeys.COOKIES_ACCEPTED) === 'true') {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [handleClickOutside]);

  useEffect(() => {
    let pizzaFlavorGroup: IGroupChangedData = {} as IGroupChangedData;

    if (cartItem) {
      setAmount(cartItem.qty);
      setComments(cartItem.comments);
      setSelectedComplementsGroups(
        cartItem.complementsGroups.map((group) => {
          if (group.id === PizzaComplementsGroupIds.FLAVORS) {
            pizzaFlavorGroup = group;
          }

          return group;
        }),
      );
    } else {
      reset();
    }

    if (cartItem?.pizzaCategoryId) {
      if (cartItem?.isCustomPizza && pizzaFlavorGroup) {
        setSelectedPizzaSize(
          pizzaCategory?.pizzaSizes?.find((size) => size.id === cartItem?.pizzaSizeId) || null,
        );

        setSelectedPizzaSlicesCount(
          cartItem?.complementsGroups?.find(
            (group) => group.id === PizzaComplementsGroupIds.FLAVORS,
          )?.totalAmount || 0,
        );
      }
    }
  }, [cartItem, pizzaCategory, products, product]);

  const unitPrice = useMemo(() => {
    if (selectedSize) return selectedSize.price;
    return (product?.salePrice ? product?.salePrice : product?.unitPrice) || 0;
  }, [product, selectedSize]);

  const total = useMemo(() => {
    const blacklist = [PizzaComplementsGroupIds.FLAVORS];

    const flavorsGroup = selectedComplementsGroups?.find(
      (group) => group?.id === PizzaComplementsGroupIds?.FLAVORS,
    );

    const pizzaCharge =
      company?.pizzaCharge === PizzaCharges.CUSTOM && company?.pizzaChargeCustom
        ? getPizzaCharge(company.pizzaChargeCustom, {
            FLAVORS: flavorsGroup?.complements?.length || 1,
          })
        : company?.pizzaCharge;

    const complementsTotal = (
      (pizzaSize && !isCustomPizza) || (isCustomPizza && pizzaCharge === PizzaCharges.GREATER)
        ? selectedComplementsGroups.filter((group) => !blacklist.includes(group.id))
        : selectedComplementsGroups
    ).reduce((sum, groups) => {
      return (
        sum +
        groups.complements.reduce((sum2, complement) => {
          return sum2 + complement.amount * complement.unitPrice;
        }, 0)
      );
    }, 0);

    if (!isCustomPizza && pizzaSize) {
      return ((pizzaSize?.price || 0) + complementsTotal) * amount;
    }

    if (selectedComplementsGroups?.length > 0 && pizzaCharge === PizzaCharges.GREATER) {
      if (flavorsGroup) {
        const greaterComplement = Math.max(
          ...(flavorsGroup?.complements?.map((flavor) => flavor?.unitPrice || 0) || []),
        );
        const sizePrice = greaterComplement * (flavorsGroup?.totalAmount || 0);

        return (complementsTotal + sizePrice) * amount;
      }
    }

    return (unitPrice + complementsTotal) * amount;
  }, [amount, unitPrice, selectedComplementsGroups, company, pizzaSize, isCustomPizza]);

  const isTable = useMemo(() => {
    return table.tableNumber > 0;
  }, [table]);

  const productTitle = useMemo(() => {
    if (cartItem) {
      return cartItem.title;
    }

    if (isCustomPizza) {
      return 'Monte sua Pizza';
    }

    return `${product?.title}${pizzaSize ? ` - ${pizzaSize?.name}` : ''}`;
  }, [product, isCustomPizza, pizzaSize, cartItem]);

  const handleIncreaseAmount = useCallback((newAmount: number) => {
    setAmount(newAmount);
  }, []);

  const handleDecreaseAmount = useCallback((newAmount: number) => {
    setAmount(newAmount);
  }, []);

  const handleOnComplementsGroupChanged = useCallback(
    (data: IGroupChangedData) => {
      const newSelectedComplementsGroups = [...selectedComplementsGroups];
      const groupToUpdate = newSelectedComplementsGroups.find((sg) => sg.id === data.id);

      if (data.complements.length === 0) {
        setSelectedComplementsGroups((prevState) => prevState.filter((sg) => sg.id !== data.id));

        return;
      }

      if (groupToUpdate) {
        const index = newSelectedComplementsGroups.indexOf(groupToUpdate);
        newSelectedComplementsGroups[index] = data;
        setSelectedComplementsGroups(newSelectedComplementsGroups);
      } else {
        newSelectedComplementsGroups.push(data);
        setSelectedComplementsGroups(newSelectedComplementsGroups);
      }
    },
    [selectedComplementsGroups],
  );

  const handleOnComplementsGroupMaxReached = useCallback((groupIndex: number) => {
    if (!complementsGroupRefs?.current) return;
    if (groupIndex >= complementsGroupRefs?.current?.length - 1) return;

    const nextGroup = complementsGroupRefs.current[groupIndex + 1];
    nextGroup?.scrollIntoView();
  }, []);

  const handleCommentsChange = (text: string) => {
    setComments(text);
  };

  const handleOnModalCloseClicked = useCallback(() => {
    setErrorMessage('');
  }, []);

  const getSpecificGroup = useMemo(
    () => (id: number) => {
      return cartItem?.complementsGroups.find((g) => g.id === id);
    },
    [cartItem],
  );

  const sortedComplementsGroups = useMemo(() => {
    let complementsGroups: IComplementsGroup[] = [];
    if (isCustomPizza ? selectedPizzaSlicesCount : pizzaCategory) {
      if (selectedPizzaSize && selectedPizzaSlicesCount) {
        const sameCategoryProducts = products.filter((currentProduct) =>
          currentProduct.categories.some(
            (currentCategory) => currentCategory.id === pizzaCategory?.id,
          ),
        );

        const pizzaFlavorsGroup: IComplementsGroup | null = {
          id: PizzaComplementsGroupIds.FLAVORS,
          category: 'STANDARD',
          isActive: true,
          position: -4,
          title: 'sizes',
          displayName: 'Sabores',
          minAmount: selectedPizzaSlicesCount,
          maxAmount: selectedPizzaSlicesCount,
          modules: '',
          complements: (
            sameCategoryProducts.map((currentProduct, index) => {
              const currentProductPizzaSize = currentProduct?.pizzaSizes?.find(
                (size) => size.pizzaSizeId === selectedPizzaSize.id,
              );

              if (!currentProductPizzaSize) {
                return undefined;
              }

              return {
                id: currentProductPizzaSize?.id || 0,
                isActive: true,
                position: index - 1,
                unitPrice: (currentProductPizzaSize?.price || 0) / selectedPizzaSlicesCount,
                title: currentProduct.title,
                description: currentProduct.description,
                maxAmount: selectedPizzaSlicesCount,
                productId: currentProduct.id,
                productGroupId: currentProduct?.productGroup?.id,
              } as IComplement;
            }) as []
          )?.filter((complement) => complement !== undefined),
        };

        complementsGroups.push(pizzaFlavorsGroup);
      }

      if (((selectedPizzaSize || pizzaSize)?.pizzaCrusts?.length || 0) > 0) {
        const pizzaCrustsGroup: IComplementsGroup | null = {
          id: PizzaComplementsGroupIds.CRUSTS,
          category: 'STANDARD',
          isActive: true,
          position: -3,
          title: 'crusts',
          displayName: 'Massa',
          minAmount: 1,
          maxAmount: 1,
          modules: '',
          complements:
            (selectedPizzaSize || pizzaSize)?.pizzaCrusts
              ?.filter((crust) => crust?.active)
              .map((crust) => ({
                id: crust.id,
                title: crust.name,
                description: crust.name,
                unitPrice: crust.price,
                isActive: true,
                maxAmount: 1,
                position: crust.position,
              })) || [],
        };
        complementsGroups.push(pizzaCrustsGroup);
      }

      if (((selectedPizzaSize || pizzaSize)?.pizzaEdges?.length || 0) > 0) {
        const selectedCrustGroup = selectedComplementsGroups.find(
          (group) => group.id === PizzaComplementsGroupIds.CRUSTS,
        );

        const selectedComplement = selectedCrustGroup?.complements?.find(
          (complement) => complement.amount === 1,
        );

        const selectedCrust = (selectedPizzaSize || pizzaSize)?.pizzaCrusts?.find(
          (crust) => crust.id === selectedComplement?.id,
        );

        if (!selectedCrust?.disableEdges) {
          const pizzaEdgesGroup: IComplementsGroup | null = {
            id: PizzaComplementsGroupIds.EDGES,
            category: 'STANDARD',
            isActive: true,
            position: -2,
            title: 'edges',
            displayName: 'Borda',
            minAmount: 1,
            maxAmount: 1,
            modules: '',
            complements:
              (selectedPizzaSize || pizzaSize)?.pizzaEdges
                ?.filter((edge) => edge?.active)
                .map((edge) => ({
                  id: edge.id,
                  title: edge.name,
                  description: edge.name,
                  unitPrice: edge.price,
                  isActive: true,
                  maxAmount: 1,
                  position: edge.position,
                })) || [],
          };
          complementsGroups.push(pizzaEdgesGroup);
        }
      }
    }

    complementsGroups = complementsGroups.concat(
      product?.complementsGroups?.filter((group) => group?.category !== 'PIZZA') || [],
    );

    return orderArrayByPosition(complementsGroups);
  }, [
    isCustomPizza,
    selectedPizzaSlicesCount,
    pizzaCategory,
    product,
    selectedPizzaSize,
    pizzaSize,
    products,
    selectedComplementsGroups,
  ]);

  const scrollToComplementsGroup = useCallback((id?: number) => {
    complementsGroupRefs?.current
      ?.find((complementGroupRef) => complementGroupRef?.complementsGroupId === id)
      ?.scrollIntoView();
  }, []);

  const handleOnSizePress = (size: ISize) => {
    setSelectedSize(size);
  };

  const handleAddToCart = useCallback(
    ({ skipValidation }: { skipValidation?: boolean }) => {
      if (!skipValidation) {
        const validation = validateComplementsGroups({
          pizzaCategory,
          complementsGroups: sortedComplementsGroups,
          selectedComplementsGroups,
          product,
          isCustomPizza,
          selectedPizzaSize,
          selectedPizzaSlicesCount,
        });

        if (total <= 0) {
          setErrorMessage('O valor não pode ser zero.');
          return;
        }

        if (validation === null) return;

        if (validation.errorMessage) {
          setErrorMessage(validation?.errorMessage);

          if (validation?.complementGroupId) {
            scrollToComplementsGroup(validation?.complementGroupId);
          }
          return;
        }
      }

      if (pizzaCategory) {
        const sizes = selectedComplementsGroups
          .find((group) => group.id === PizzaComplementsGroupIds.FLAVORS)
          ?.complements.map((complement) => complement.title.replace(/pizza|(\sde\s)/gi, ''));

        const sizeToAdd =
          selectedPizzaSize || product?.pizzaSizes?.find((size) => size.id === pizzaSize?.id);

        const title = isCustomPizza
          ? sizes?.length === 1
            ? `${sizes[0]} - ${sizeToAdd?.name}`
            : `${/^pizza/i.test(sizeToAdd?.name || '') ? '' : 'Pizza '}${sizeToAdd?.name}`
          : productTitle || '';

        const sizeToAddId = cartItem
          ? cartItem?.pizzaSizeId
          : selectedPizzaSize
          ? selectedPizzaSize?.id
          : product?.pizzaSizes?.find((size) => size.id === pizzaSize?.id)?.pizzaSizeId;

        const flavorGroup = isCustomPizza
          ? (selectedComplementsGroups?.find(
              (group) => group?.id === PizzaComplementsGroupIds?.FLAVORS,
            ) as IGroupChangedData)
          : {
              id: PizzaComplementsGroupIds.FLAVORS,
              position: 0,
              totalAmount: 1,
              complements: [
                {
                  id: pizzaSize?.id || 0,
                  amount: 1,
                  productId: product?.id || 0,
                  unitPrice: pizzaSize?.price || 0,
                  title: product?.title || '',
                },
              ],
            };

        const pizzaCharge =
          company.pizzaCharge === PizzaCharges.CUSTOM && company?.pizzaChargeCustom
            ? getPizzaCharge(company.pizzaChargeCustom, {
                FLAVORS: flavorGroup?.complements?.length || 1,
              })
            : company.pizzaCharge;

        addToCart({
          id: cartItem ? cartItem.id : uuid(),
          companyId: company.id,
          qty: amount,
          imageUrl: isCustomPizza
            ? selectedPizzaSize && selectedPizzaSize?.slices > 1
              ? pizzaPlaceholder
              : pizzaSlicePlaceholder
            : product?.imageUrl,
          productId: product?.id || 0,
          sellerSku: '',
          title: title || '',
          unitPrice: isCustomPizza ? total / amount : (cartItem || product)?.unitPrice || 0,
          description: '',
          complementsGroups: isCustomPizza
            ? selectedComplementsGroups.map((group) => {
                if (
                  group.id === PizzaComplementsGroupIds.FLAVORS &&
                  pizzaCharge === PizzaCharges.GREATER
                ) {
                  const greaterComplementPrice = Math.max(
                    ...group.complements.map((complement) => complement.unitPrice),
                  );
                  return {
                    ...group,
                    complements: group.complements.map((complement) => ({
                      ...complement,
                      unitPrice: greaterComplementPrice,
                    })),
                  };
                }

                return group;
              })
            : selectedComplementsGroups.find((group) => group.id === flavorGroup?.id)
            ? selectedComplementsGroups.map((group) =>
                group.id === flavorGroup?.id ? flavorGroup : group,
              )
            : [flavorGroup, ...selectedComplementsGroups],

          comments,
          isCustomPizza,
          pizzaCategoryId: pizzaCategory.id,
          pizzaSizeId: sizeToAddId,
          productPizzaSize: !isCustomPizza ? pizzaSize : undefined,
          isHalfPrice: false,
        });
      } else if (product) {
        addToCart({
          id: cartItem ? cartItem.id : uuid(),
          companyId: company.id,
          qty: amount,
          imageUrl: product.imageUrl,
          productId: product.id,
          sellerSku: product.sellerSku,
          title: product.title,
          unitPrice,
          description: product.description,
          complementsGroups: selectedComplementsGroups,
          comments,
          // isHalfPrice:   unitPrice === product?.halfPrice,
          isHalfPrice:
            unitPrice === 0 && product?.halfPrice === 0 ? false : unitPrice === product?.halfPrice,
        });
      }

      return onCloseClick(product?.id);
    },
    [
      pizzaCategory,
      sortedComplementsGroups,
      selectedComplementsGroups,
      product,
      isCustomPizza,
      selectedPizzaSize,
      selectedPizzaSlicesCount,
      total,
      onCloseClick,
      scrollToComplementsGroup,
      productTitle,
      cartItem,
      pizzaSize,
      company,
      addToCart,
      amount,
      comments,
      unitPrice,
    ],
  );

  const categories = useMemo(() => {
    let message = '';

    product?.categories.forEach((c, index) => {
      if (index === product?.categories.length - 1) {
        message += `${c.name}`;
      } else {
        message += `${c.name} / `;
      }
    });

    return message;
  }, [product]);

  useEffect(() => {
    if (headerRef.current) {
      const observer = new ResizeObserver(() => {
        const height = headerRef.current?.clientHeight || 0;
        document.documentElement.style.setProperty(
          '--product-details-header-height',
          `${height}px`,
        );
      });
      observer.observe(headerRef.current);

      return () => {
        observer.disconnect();
      };
    }

    return () => null;
  }, []);

  return (
    <Container visible={visible} ref={ref} id="drawer">
      <React.Suspense fallback={<Loading radius={32} />}>
        {isCustomPizza ? (
          <CustomPizzaDetails
            products={products}
            productGroups={productGroups}
            productTitle={productTitle}
            complementsGroups={sortedComplementsGroups}
            selectedComplementsGroups={selectedComplementsGroups}
            pizzaCategory={pizzaCategory}
            selectedPizzaSize={selectedPizzaSize}
            selectedPizzaSlicesCount={selectedPizzaSlicesCount}
            productPlaceholder={product?.productPlaceholder}
            comments={comments}
            amount={amount}
            total={total}
            onPizzaSizeClick={handlePizzaSizeClick}
            onPizzaSlicesCountChange={handlePizzaSlicesCountChange}
            onComplementsGroupChanged={handleOnComplementsGroupChanged}
            onIncreaseAmount={handleIncreaseAmount}
            onDecreaseAmount={handleDecreaseAmount}
            onCommentsChange={handleCommentsChange}
            setSelectedPizzaSlicesCount={setSelectedPizzaSlicesCount}
            setErrorMessage={setErrorMessage}
            onAddToCart={() => handleAddToCart({ skipValidation: true })}
            onCloseClick={onCloseClick}
            isUpdate={!!cartItem}
          />
        ) : (
          <>
            <Header ref={headerRef}>
              <CloseButton onClick={() => onCloseClick(product?.id)}>
                <FiX size={24} />
              </CloseButton>
              <div>
                <Name>{productTitle}</Name>
                <Category>{categories}</Category>
              </div>
            </Header>
            <Top>
              {product && (
                <ProductInfo>
                  <ProductImage>
                    <ProductIcon url={product?.imageUrl} alt={product?.title} />
                    {product?.imageUrl && product?.isIllustrativeImage && (
                      <span className="illustrative-warning">- Imagem meramente ilustrativa -</span>
                    )}
                    {product?.prepareTime && table.tableNumber > 0 && (
                      <PrepareTime>
                        <span>{product?.prepareTime}</span>
                      </PrepareTime>
                    )}
                  </ProductImage>
                  <ProductDescription>{product?.description}</ProductDescription>
                </ProductInfo>
              )}

              {productSizes.length > 0 && (
                <>
                  <h4>Selecione o tamanho:</h4>
                  <SizeSelector
                    sizes={productSizes}
                    activeSizeId={selectedSize?.id}
                    onSizePress={handleOnSizePress}
                  />
                </>
              )}

              {((product?.complementsGroups?.length || 0) > 0 || pizzaCategory) && (
                <ComplementsGroups>
                  {sortedComplementsGroups?.map((complementsGroup, index) => (
                    <ComplementsGroup
                      ref={(el: IComplementGroupRef) => {
                        return el ? (complementsGroupRefs.current[index] = el) : null;
                      }}
                      key={complementsGroup.id}
                      complementsGroup={complementsGroup}
                      onGroupChanged={handleOnComplementsGroupChanged}
                      preSelected={getSpecificGroup(complementsGroup.id)}
                      onMaxReached={() => handleOnComplementsGroupMaxReached(index)}
                    />
                  ))}
                </ComplementsGroups>
              )}
              {isTable ? (
                <>
                  {!isShowcase && !company.showcaseTable && (
                    <Comments>
                      <h4>Observações</h4>
                      <textarea
                        id="comments"
                        name="comments"
                        maxLength={255}
                        value={comments}
                        placeholder={
                          product?.productPlaceholder || 'Escreva as observações do produto aqui.'
                        }
                        onChange={(e) => handleCommentsChange(e.target.value)}
                      />
                      <span>{`${comments.length}/255`}</span>
                    </Comments>
                  )}
                </>
              ) : (
                <>
                  {!isShowcase && !company.showcaseDelivery && (
                    <Comments>
                      <h4>Observações</h4>
                      <textarea
                        id="comments"
                        name="comments"
                        maxLength={255}
                        value={comments}
                        placeholder={
                          product?.productPlaceholder || 'Escreva as observações do produto aqui.'
                        }
                        onChange={(e) => handleCommentsChange(e.target.value)}
                      />
                      <span>{`${comments.length}/255`}</span>
                    </Comments>
                  )}
                </>
              )}
            </Top>
            <Footer withPrice={isTable ? company?.showcaseTable : company?.showcaseDelivery}>
              {(isTable ? company?.showcaseTable : company?.showcaseDelivery) && (
                <>
                  <Total className="total">
                    {product?.suggestedSalePrice ? (
                      <>
                        <div className="suggestedPrice">
                          <span>De:</span>
                          <span className="price">{formatToMoney(product?.unitPrice)}</span>
                        </div>
                        <div className="salePrice">
                          <span>Por:</span>
                          <strong>{formatToMoney(product?.salePrice || 0)}</strong>
                        </div>
                        <div className="normalPrice">
                          <span>Total:</span>
                          <strong>{formatToMoney(total)}</strong>
                        </div>
                      </>
                    ) : (
                      <div className="normalPrice">
                        <span>Total:</span>
                        <strong>{formatToMoney(total)}</strong>
                      </div>
                    )}
                  </Total>
                  <Separator />
                </>
              )}

              {table.tableNumber ? (
                company.showcaseTable ? (
                  <BackToProductsComponent
                    productId={product?.id ?? 0}
                    onCloseClick={onCloseClick}
                  />
                ) : (
                  <FinishComponent
                    amount={amount}
                    total={total}
                    handleAddToCart={handleAddToCart}
                    handleIncreaseAmount={handleIncreaseAmount}
                    handleDecreaseAmount={handleDecreaseAmount}
                  />
                )
              ) : company.showcaseDelivery ? (
                <BackToProductsComponent productId={product?.id ?? 0} onCloseClick={onCloseClick} />
              ) : (
                <FinishComponent
                  amount={amount}
                  total={total}
                  handleAddToCart={handleAddToCart}
                  handleIncreaseAmount={handleIncreaseAmount}
                  handleDecreaseAmount={handleDecreaseAmount}
                />
              )}
            </Footer>
          </>
        )}

        <ErrorModal
          isOpen={!!errorMessage}
          message={errorMessage}
          onClose={handleOnModalCloseClicked}
        />
      </React.Suspense>
    </Container>
  );
};

export default ProductDetails;
