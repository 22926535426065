import React from 'react';
import { formatToMoney } from '../../utils/format';
import AmountInput from '../AmountInput';
import { Finish, ProductAmount } from './styles';

interface IFinishComponent {
  amount: number;
  total: number;
  handleAddToCart: ({ skipValidation }: { skipValidation?: boolean }) => void;
  handleIncreaseAmount: (newAmount: number) => void;
  handleDecreaseAmount: (newAmount: number) => void;
}
const FinishComponent: React.FC<IFinishComponent> = ({
  total,
  amount,
  handleAddToCart,
  handleIncreaseAmount,
  handleDecreaseAmount,
}) => {
  return (
    <Finish>
      <ProductAmount>
        <span>Quantidade</span>
        <AmountInput
          value={amount}
          allowsZero={false}
          onIncreaseAmount={handleIncreaseAmount}
          onDecreaseAmount={handleDecreaseAmount}
        />
      </ProductAmount>
      <div>
        <button type="button" onClick={() => handleAddToCart({})}>
          <span>Adicionar</span>
          <span className="price">{formatToMoney(total)}</span>
        </button>
      </div>
    </Finish>
  );
};

export default FinishComponent;
