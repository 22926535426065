import ICart from '../models/ICart';

type OrderStatusTypes =
  | 'PLACED'
  | 'PREPARING'
  | 'IN_TRANSIT'
  | 'COMPLETED'
  | 'CANCELED'
  | undefined;

export const getNextStatusMessage = (orderStatus: OrderStatusTypes): string => {
  if (!orderStatus) return '';

  switch (orderStatus) {
    case 'CANCELED':
      return 'Reativar pedido';
    case 'COMPLETED':
      return 'Pedido concluído';
    case 'IN_TRANSIT':
      return 'Confirmar entrega';
    case 'PLACED':
      return 'Aprovar pedido';
    default:
      return 'Enviar para entrega';
  }
};

export const getNextStatusColor = (orderStatus: OrderStatusTypes): string => {
  if (!orderStatus) return '';

  switch (orderStatus) {
    case 'CANCELED':
      return '#27ae60';
    case 'COMPLETED':
      return '#27ae60';
    case 'IN_TRANSIT':
      return '#9b59b6';
    case 'PLACED':
      return '#27ae60';
    default:
      return '#34495e';
  }
};

export const getFormattedStatus = (status: OrderStatusTypes): string => {
  switch (status) {
    case 'COMPLETED':
      return 'Finalizado';
    case 'IN_TRANSIT':
      return 'Em entrega';
    case 'PLACED':
      return 'Em aberto';
    case 'PREPARING':
      return 'Em preparo';
    default:
      return 'Cancelado';
  }
};

export const getStatusColor = (orderStatus: OrderStatusTypes): string => {
  if (!orderStatus) return '';

  switch (orderStatus) {
    case 'CANCELED':
      return '#e74c3c';
    case 'COMPLETED':
      return '#27ae60';
    case 'IN_TRANSIT':
      return '#34495e';
    case 'PLACED':
      return '#3498db';
    default:
      return '#e67e22';
  }
};

export const getIsActiveStatus = (
  orderStatus: OrderStatusTypes,
  currentStatus: OrderStatusTypes,
): boolean => {
  switch (currentStatus) {
    case 'PLACED':
      return (
        orderStatus === 'PLACED' ||
        orderStatus === 'PREPARING' ||
        orderStatus === 'IN_TRANSIT' ||
        orderStatus === 'COMPLETED'
      );
    case 'PREPARING':
      return (
        orderStatus === 'PREPARING' || orderStatus === 'IN_TRANSIT' || orderStatus === 'COMPLETED'
      );
    case 'IN_TRANSIT':
      return orderStatus === 'IN_TRANSIT' || orderStatus === 'COMPLETED';
    case 'COMPLETED':
      return orderStatus === 'COMPLETED';
    default:
      return false;
  }
};

interface NormalizedItem extends Omit<ICart, 'productId'> {
  productId?: number;
}

export const normalizeItem = (item: ICart): NormalizedItem => {
  if (item?.isCustomPizza) {
    return { ...item, productId: undefined };
  }

  if (item?.pizzaCategoryId) {
    return { ...item, productId: undefined, isCustomPizza: true };
  }

  return item;
};
