import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import Finish from './styles';

interface IBackToProductsComponent {
  productId: number;
  onCloseClick: (productId: number) => void;
}
const BackToProductsComponent: React.FC<IBackToProductsComponent> = ({
  productId,
  onCloseClick,
}) => {
  return (
    <Finish>
      <div className="go-back">
        <button type="button" onClick={() => onCloseClick(productId)}>
          <FiArrowLeft size={24} />
          <span>Voltar ao cardápio</span>
        </button>
      </div>
    </Finish>
  );
};

export default BackToProductsComponent;
