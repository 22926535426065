import { normalizeString } from './string';

const search = <T>(data: T[], transformer: (item: T) => string, searchTerm: string): T[] => {
  if (!searchTerm) {
    return data;
  }

  const normalizedTerm = normalizeString(searchTerm);

  return data.filter((item) => {
    const searchString = normalizeString(transformer(item));
    return searchString.includes(normalizedTerm);
  });
};
export default search;
