import React from 'react';
import { DefaultTheme } from 'styled-components';
import Spinner from 'react-spinner-material';
import Container from './styles';

interface ILoadingProps {
  color?: keyof DefaultTheme['palette'];
  stroke?: number;
  radius?: number;
}

const Loading: React.FC<ILoadingProps> = ({ color, stroke, radius }) => {
  return (
    <Container color={color}>
      <Spinner color="inherit" visible stroke={stroke || 4} radius={radius || 36} />
    </Container>
  );
};

export default Loading;
