import axios from 'axios';
import axiosRetry from 'axios-retry';

import { getCartId } from '../utils/cart';
import { getSubdomain } from '../utils/subdomain';
import redirectToAuth from '../utils/redirect';

const retriesConfig = [2000, 2000, 2000, 2000, 3000, 3000, 4000, 4000, 5000, 5000, 8000];

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    'X-Tenant-Id': getSubdomain(),
  },
});

axiosRetry(api, {
  retries: retriesConfig.length,
  retryDelay: (retryCount) => retriesConfig[retryCount],
});

api.interceptors.request.use((config) => {
  const cartId = getCartId();
  config.headers['X-Cart-Id'] = cartId;
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response && error?.response?.status === 401) {
      redirectToAuth({ redirect: 'menu' });
    }

    if (error?.response && error?.response?.status === 404) {
      window.location.pathname = '/error';
    }

    if (error?.response && error?.response?.status >= 500) {
      window.location.pathname = '/server-error';
    }

    return Promise.reject(error);
  },
);

export default api;
