/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable indent */
/* eslint-disable no-return-assign */
import React, { useRef, useMemo, useState, useEffect, FormEvent, useCallback } from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useMediaQuery } from 'react-responsive';

import { DateTime } from 'luxon';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';
import { AiFillHeart } from 'react-icons/ai';
import { useTheme } from 'styled-components';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import {
  FiX,
  FiMenu,
  FiUser,
  FiMapPin,
  FiSearch,
  FiArrowUp,
  FiArrowLeft,
  FiArrowRight,
  FiChevronDown,
  FiShoppingCart,
} from 'react-icons/fi';

import { FaRegHandPaper } from 'react-icons/fa';
import { ReactComponent as SaleSvg } from '../../assets/sale.svg';

import { useCart } from '../../hooks/cart';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { useOrder } from '../../hooks/order';
import useInterval from '../../hooks/time';
import { useCompany } from '../../hooks/company';
import { useProducts } from '../../hooks/products';
import { useCategories } from '../../hooks/categories';

import AddressType from '../../enum/Company';
import { formatToDocument, formatToMoney } from '../../utils/format';
import { chooseCategoryIcon } from '../../utils/icons';

import IAddress from '../../models/IAddress';
import { ILocalProduct, IProductPizzaSize } from '../../models/IProduct';
import ICategory from '../../models/ICategory';
import ICartItem from '../../models/ICartItem';

import LoadingPage from '../LoadingPage';

import { throttle, debounce } from '../../utils/time';

import {
  Main,
  Badge,
  Icons,
  Share,
  Badges,
  Header,
  Search,
  Footer,
  DoneBy,
  Address,
  Content,
  MinValue,
  Products,
  Companies,
  Container,
  Highlight,
  CartButton,
  CategoriesSubcategories,
  TopWrapper,
  AddressIcon,
  CompanyName,
  HeaderRight,
  SearchSmall,
  ShareButton,
  Subcategory,
  AddressTitle,
  FinishButton,
  NothingFound,
  SearchButton,
  ClosedCompany,
  HighlightBlur,
  HighlightText,
  HighlightPrice,
  IconContainer,
  ProductsTitle,
  Subcategories,
  TabsContainer,
  AddressWrapper,
  CategorySubcategoryTitle,
  AddressContainer,
  SubcategoryTitle,
  SubcategoryGroupTitle,
  ProductsContainer,
  ScrollToTopButton,
  NoSubcategoryTitle,
  ProductPlaceholder,
  CategoriesContainer,
  HighlightsContainer,
  SmallAddressWrapper,
  ScrollCategorySubcategoryButton,
  FinishButtonContainer,
  SmallAddressContainer,
  DistanceMessageContainer,
  CollapsibleButton,
} from './styles';

import CategoryType from '../../enum/Category';
import { useProductGroups } from '../../hooks/product-groups';
import { orderArrayByPosition } from '../../utils/array';
import search from '../../utils/search';
import Loading from '../../components/Loading';
import ProductDetails from '../../components/ProductDetails';

import lazyWithRetry from '../../utils/react';
import ModalCallWaiter from '../../components/ModalCallWaiter';
// import CustomerInfoModal from '../../components/CustomerInfoModal';

const StateCollapsible = lazyWithRetry(() =>
  import('../../components/Collapsible').then((module) => ({
    default: module.StateCollapsible,
  })),
);
// eslint-disable-next-line import/no-cycle
const Product = lazyWithRetry(() => import('../../components/Product'));

const Tab = lazyWithRetry(() => import('../../components/Tab'));
const Carousel = lazyWithRetry(() => import('../../components/Carousel'));
const CategorySubcategory = lazyWithRetry(() => import('../../components/CategorySubcategory'));
const ErrorModal = lazyWithRetry(() => import('../../components/ErrorModal'));
const ProductIcon = lazyWithRetry(() => import('../../components/ProductIcon'));
const ProfileModal = lazyWithRetry(() => import('../../components/ProfileModal'));
const WarningBadge = lazyWithRetry(() => import('../../components/WarningBadge'));

const CategoriesModal = lazyWithRetry(() => import('../../components/CategoriesModal'));
const DistanceMessage = lazyWithRetry(() => import('../../components/DistanceMessage'));
const BusinessHourModal = lazyWithRetry(() => import('../../components/BusinessHourModal'));
const CustomPizzaCard = lazyWithRetry(() => import('../../components/CustomPizzaCard'));
const CompanyInfoModal = lazyWithRetry(() => import('../../components/CompanyInfoModal'));
const CompanyRegionsModal = lazyWithRetry(() => import('../../components/CompanyRegionsModal'));
const AddressCreationModal = lazyWithRetry(() => import('../../components/AddressCreationModal'));
const AddressSelectionModal = lazyWithRetry(() => import('../../components/AddressSelectionModal'));
const Cart = lazyWithRetry(() => import('../../components/Cart'));

interface IState {
  edit: string;
  productId: number;
  categoryId: number;
}

export interface ILocalCategory {
  id: number;
  label: string;
  type: CategoryType;
  pizzaCategoryId?: number;
  subcategories: {
    id: number;
    name: string;
    products: ILocalProduct[];
  }[];
  productsWithoutSubcategory: ILocalProduct[];
}

const ProductsPage: React.FC = () => {
  const theme = useTheme();

  const categoriesRefs = useRef<(HTMLDivElement | null)[]>([]);

  const mainRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const categoriesRef = useRef<HTMLDivElement>(null);
  const subcategoriesRef = useRef<HTMLDivElement>(null);
  const tabContainerRef = useRef<HTMLDivElement>(null);
  const subTabContainerRef = useRef<HTMLDivElement>(null);
  const categoriesContainerRef = useRef<HTMLDivElement>(null);
  const subcategoriesContainerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['visit-date']);
  const { table } = useOrder();
  const location = useLocation();
  const { addToast } = useToast();
  const { categories, loadCategories } = useCategories();
  const { loadProductGroups } = useProductGroups();
  const { cart, cartAmount, cartTotal, clearCart } = useCart();

  const {
    isOpen,
    company,
    messages,
    isShowcase,
    loadTimes,
    checkStatus,
    loadCompany,
    loadMessages,
    visitCompany,
  } = useCompany();

  const {
    products,
    highlights,
    topProducts,
    selectedProduct,
    selectedPizzaCategory,
    selectedPizzaSize,
    discountProducts,
    loading: productsLoading,
    loadProducts,
    loadHighlights,
    setSelectedProduct,
    setSelectedPizzaCategory,
    setSelectedPizzaSize,
  } = useProducts();

  const {
    customer,
    addresses,
    selectedAddress,
    deliveryAvailable,
    getDistance,
    chooseAddress,
    deleteAddress,
    loadCustomerAddresses,
  } = useAuth();

  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(true);
  const [lastRoute, setLastRoute] = useState('');
  const [openCallWaiter, setOpenCallWaiter] = useState(false);
  const [isInputfocused, setIsInputfocused] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);
  const [isOrderSummaryVisible, setIsOrderSummaryVisible] = useState(false);
  const [isCategoriesModalOpen, setIsCategoriesModalOpen] = useState(false);
  const [isDistanceWarningShown, setIsDistanceWarningShown] = useState(true);
  const [isCompanyInfoModalOpen, setIsCompanyInfoModalOpen] = useState(false);

  const isTable = useMemo(() => {
    return table.tableNumber > 0;
  }, [table]);

  useEffect(() => {
    if (isTable && location?.pathname === '/') {
      setOpenCallWaiter(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isCompanyRegionModalOpen, setIsCompanyRegionModalOpen] = useState(false);

  const [itemToUpdate, setItemToUpdate] = useState<ICartItem | undefined>(undefined);
  const [isCustomPizza, setIsCustomPizza] = useState(false);

  const [blockScroll, setBlockScroll] = useState<'LEFT' | 'RIGHT' | 'NONE'>('LEFT');

  const [isTabVisible, setIsTabVisible] = useState(false);
  const [focusedCategory, setFocusedCategory] = useState(0);

  const [addressToEdit, setAddressToEdit] = useState<IAddress | undefined>(undefined);

  const [isAddressCreationDialogOpen, setIsAddressCreationDialogOpen] = useState(false);

  const [isAddressSelectionDialogOpen, setIsAddressSelectionDialogOpen] = useState(false);

  const [isBusinessHourModalOpen, setIsBusinessHourModalOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState<{
    type: 'NONE' | 'ADDITIONALS' | 'PRICE';
    description: string;
  }>({ type: 'NONE', description: '' });

  const [collapsedCategories, setCollapsedCategories] = useState<number[]>([]);

  const isMobileScreen = useMediaQuery({ query: '(max-width: 430px)' });

  useEffect(() => {
    if (company && cookies && !table.tableNumber) {
      const date = cookies['visit-date'];

      if (date && typeof date === 'string') {
        const { hours } = DateTime.fromMillis(Number(date)).diffNow('hours').toObject();

        if (hours && hours < -24) {
          visitCompany();
          setCookie('visit-date', Date.now());
        }
      } else {
        visitCompany();
        setCookie('visit-date', Date.now());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (company.id && !isOpen && !loading) {
      clearCart();
    }
  }, [company, loading, isOpen, clearCart]);

  useEffect(() => {
    if (
      company?.companyDelivery &&
      company?.companyDelivery?.type === AddressType.DISTANCE &&
      selectedAddress
    ) {
      getDistance(selectedAddress);
    }
  }, [getDistance, selectedAddress, company]);

  useEffect(() => {
    window.scrollTo(0, 0);

    loadProducts(!!table.tableNumber, selectedCategory);
  }, [selectedCategory, loadProducts, table]);

  useInterval(checkStatus, 15000);

  const checkIsVisible = useMemo(() => {
    return (el: HTMLDivElement | null) => {
      if (!el) {
        return false;
      }

      const rect = el.getBoundingClientRect();
      const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);

      const above = rect.bottom - 250 < 0;
      const below = rect.top - viewHeight + 250 >= 0;

      return !above && !below;
    };
  }, []);

  const handleOnScroll = useCallback(() => {
    if (categoriesContainerRef && categoriesContainerRef.current) {
      const rect = categoriesContainerRef.current.getBoundingClientRect();
      const condition = rect.y < -100;

      if (isTabVisible !== condition) {
        setBlockScroll('NONE');
        setIsTabVisible(condition);
      }

      const foundCategory = categoriesRefs.current.find(checkIsVisible);

      const foundCategoryId = Number(foundCategory?.getAttribute('data-id'));

      if (foundCategoryId && foundCategoryId !== focusedCategory) {
        setFocusedCategory(foundCategoryId);
      }
    }

    if (mainRef && mainRef.current && mainRef?.current.scrollTop > window.screen.height / 1.8) {
      setIsScrollButtonVisible(true);
    } else {
      setIsScrollButtonVisible(false);
    }
  }, [isTabVisible, focusedCategory, checkIsVisible, setFocusedCategory]);

  useEffect(() => {
    const debouncedScroll = debounce(handleOnScroll, 1400) as any;
    const throttleScroll = throttle(() => {
      debouncedScroll();
      handleOnScroll();
    }, 500) as any;

    mainRef.current?.addEventListener('scroll', throttleScroll, {
      passive: true,
    });

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      mainRef.current?.removeEventListener('scroll', throttleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleOnScroll, loading, productsLoading]);

  useEffect(() => {
    const isCart = location.pathname === '/cart';

    setIsOrderSummaryVisible(isCart);

    if (location.state as IState) {
      const { categoryId, edit } = location.state as IState;
      if (isCart && (location.state as IState)?.edit) {
        const cartItem = cart.find((c) => c.id === edit);
        setItemToUpdate(cartItem);
        setSelectedPizzaCategory(cartItem?.pizzaCategoryId || null);
        setSelectedProduct(cartItem?.productId || 0);
        setIsCustomPizza(cartItem?.isCustomPizza || false);
        setSelectedPizzaSize(cartItem?.productPizzaSize || null);
      } else {
        setSelectedCategory(
          location.pathname === '/category' || location.pathname === '/product' ? categoryId : 0,
        );

        setFocusedCategory(
          location.pathname === '/category' || location.pathname === '/product' ? categoryId : 0,
        );

        // if (productId) {
        //   setSelectedProduct(
        //     location.pathname === '/product' ? productId : null,
        //   );
        // }
      }
    } else if (location.pathname !== '/product' && !itemToUpdate && !selectedProduct) {
      setSelectedCategory(0);
      setSelectedProduct(0);
    }
  }, [
    cart,
    company,
    products,
    location.state,
    location.pathname,
    itemToUpdate,
    selectedProduct,
    setSelectedProduct,
    setSelectedPizzaCategory,
    setSelectedPizzaSize,
  ]);

  const loadData = useCallback(async () => {
    if (company?.id > 0) {
      await checkStatus();
      await loadCategories(!!table.tableNumber);
      await loadProductGroups();
      await loadHighlights(!!table.tableNumber);
      await loadMessages();
      await loadTimes();

      if (customer) {
        await loadCustomerAddresses();
      }

      setLoading(false);
    } else {
      loadCompany();
    }
  }, [
    table,
    company,
    customer,
    loadTimes,
    checkStatus,
    loadCompany,
    loadMessages,
    loadCategories,
    loadHighlights,
    loadProductGroups,
    loadCustomerAddresses,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (location.pathname === '/product') {
      const parsed = queryString.parse(location.search);
      if (parsed && parsed.hash) {
        const product = products.find((p) => p.hash === parsed.hash || 0);

        if (product) {
          setSelectedProduct(product.id);

          if (company.enablesSubcategories) {
            setSelectedCategory(product.categories[0].id);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedProduct, company, navigate, products]);

  useEffect(() => {
    if (location.pathname !== lastRoute) {
      setLastRoute(location.pathname);
    }
  }, [location, lastRoute]);

  useEffect(() => {
    setIsBusinessHourModalOpen(!isOpen);
  }, [isOpen]);

  const handleSelectCategory = useCallback(
    (id: number, enableIncludes?: boolean) => {
      setFilter('');

      if (inputRef.current) {
        inputRef.current.value = '';
      }

      if (company.enablesSubcategories) {
        navigate('/category', { state: { categoryId: id } });
      } else {
        const element = document.querySelector(
          !enableIncludes ? `[id="category-${id}"]` : `[id^="category-${id}"]`,
        ) as HTMLDivElement;

        if (element && mainRef.current) {
          mainRef.current.scrollTo({
            top: element.offsetTop - element.clientHeight - 82,
            behavior: 'smooth',
          });
          setTimeout(() => setFocusedCategory(id), 480);
          // element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    },
    [navigate, company],
  );

  const handleScrollToSubcategory = useCallback((id: number) => {
    const element = document.getElementById(`subcategory-${id}`) as HTMLDivElement;

    if (element && mainRef.current) {
      mainRef.current.scrollTo({
        top: element.offsetTop - element.clientHeight - 82,
        behavior: 'smooth',
      });
    }
  }, []);

  const handleSelectProduct = useCallback(
    (product: ILocalProduct) => {
      if (window.location.pathname === '/product') {
        navigate('/product', {
          state: {
            productId: product.id,
            categoryId: selectedCategory,
          },
        });
      } else {
        navigate('/product', {
          state: {
            productId: product.id,
            categoryId: selectedCategory,
          },
        });
      }

      setItemToUpdate(undefined);

      if (product?.pizzaSize) {
        const category = product.categories.find((current) => current.type === CategoryType.PIZZA);

        setSelectedPizzaSize(product?.pizzaSize);
        setSelectedPizzaCategory(category?.id || null);
      } else {
        setSelectedPizzaSize(null);
        setSelectedPizzaCategory(null);
      }

      setSelectedProduct(product.id);
      setIsCustomPizza(false);
      setIsScrollButtonVisible(false);
    },
    [
      setSelectedProduct,
      setSelectedPizzaCategory,
      setSelectedPizzaSize,
      navigate,
      selectedCategory,
    ],
  );

  const handleCustomPizzaSelect = (categoryId: number) => {
    setSelectedProduct(null);
    setSelectedPizzaCategory(categoryId);
    setIsCustomPizza(true);
    setIsScrollButtonVisible(false);
  };

  const handleOnCloseProductDetailsClick = useCallback(() => {
    setIsCustomPizza(false);
    setSelectedProduct(null);
    setSelectedPizzaCategory(null);
    setSelectedPizzaSize(null);
    setItemToUpdate(undefined);
    navigate(lastRoute, { state: { categoryId: selectedCategory } });

    // if (id) {
    //   const domId = `product-${id}`;
    //   document.getElementById(domId)?.scrollIntoView({
    //     block: 'center',
    //   });
    // }
  }, [
    lastRoute,
    setSelectedProduct,
    setSelectedPizzaCategory,
    setSelectedPizzaSize,
    navigate,
    selectedCategory,
  ]);

  const handleFilterChanged = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      setSelectedCategory(0);
      setFilter(e.currentTarget.value);

      if (!e.currentTarget.value) {
        navigate('/');
      } else if (location.pathname !== '/filter') {
        navigate('/filter');
      }
    },
    [location.pathname, navigate],
  );

  const handleFilterReseted = useCallback(() => {
    setFilter('');
    navigate('/');
  }, [navigate]);

  const handleCartOpen = useCallback(() => {
    if (isShowcase && table.tableNumber > 0) {
      addToast({
        type: 'error',
        description: 'Função desabilitada pelo estabelecimento.',
      });

      return;
    }

    setIsOrderSummaryVisible(true);
    navigate('/cart');
  }, [navigate, isShowcase, table.tableNumber, addToast]);

  const handleCartClose = useCallback(() => {
    setIsOrderSummaryVisible(false);
    navigate('/');
  }, [navigate]);

  const handleScrollToTop = useCallback(() => {
    setTimeout(() => setIsTabVisible(false), 500);
    mainRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const handleCompanyNameClick = useCallback(() => {
    setIsCompanyInfoModalOpen(true);
  }, []);

  const handleOnHideDistanceWarning = useCallback(() => {
    setIsDistanceWarningShown(false);
  }, []);

  const handleToggleBusinessHourModal = useCallback(() => {
    setIsBusinessHourModalOpen((old) => !old);
  }, []);

  const handleToggleAddressCreationDialog = useCallback(() => {
    setIsAddressCreationDialogOpen(!isAddressCreationDialogOpen);
  }, [isAddressCreationDialogOpen]);

  const handleToggleAddressSelectionDialog = useCallback(() => {
    setIsAddressSelectionDialogOpen(!isAddressSelectionDialogOpen);
  }, [isAddressSelectionDialogOpen]);

  const handleOnAddressSelected = useCallback(
    (address: IAddress) => {
      setIsAddressSelectionDialogOpen(false);
      chooseAddress(address);
    },
    [chooseAddress],
  );

  const handleOnEditAddress = useCallback(() => {
    setIsAddressSelectionDialogOpen(true);
  }, []);

  const handleOnAddressEdit = useCallback((address: IAddress) => {
    setAddressToEdit(address);
    setIsAddressCreationDialogOpen(true);
    setIsAddressSelectionDialogOpen(false);
  }, []);

  const handleOnNewAddress = useCallback(() => {
    setAddressToEdit(undefined);
    setIsAddressCreationDialogOpen(true);
    setIsAddressSelectionDialogOpen(false);
  }, []);

  const handleOnAddressDeleted = useCallback(
    async (id: number) => {
      if (addresses.length === 1) {
        addToast({
          type: 'error',
          description: 'Impossível remover último endereço.',
        });
        return;
      }

      try {
        await deleteAddress(id);

        addToast({
          type: 'success',
          description: 'Endereço excluído com successo.',
        });
      } catch (err) {
        const errors = (err as any)?.response?.data?.errors?.messages;

        addToast({
          type: 'error',
          description: (Array.isArray(errors) && errors[0]) || 'Ocorreu um erro inesperado.',
        });
      }
    },
    [addToast, addresses.length, deleteAddress],
  );

  const handleOnProductErrored = useCallback(() => {
    setErrorMessage({
      type: 'ADDITIONALS',
      description: 'Olá, para alterar este produto, acesse o carrinho.',
    });
  }, []);

  const handleOnErrorModalClosed = useCallback(() => {
    setErrorMessage({ type: 'NONE', description: '' });
  }, []);

  const handleFinishOrder = useCallback(async () => {
    if (loading) return;

    if (isShowcase && table.tableNumber > 0) {
      addToast({
        type: 'error',
        description: 'Função desabilitada pelo estabelecimento.',
      });

      return;
    }

    navigate('/cart');
  }, [table, isShowcase, navigate, loading, addToast]);

  const handleCompanyInfoModalClose = useCallback(() => {
    setIsCompanyInfoModalOpen(false);
  }, []);

  const categoryProducts = useMemo(() => {
    if (selectedCategory === -2) {
      return products.filter((product) => product.suggestedSalePrice > 0);
    }

    const selectedCategoryObject = categories.find(
      (currentCategory) => currentCategory.id === selectedCategory,
    );

    const productsWithCategory = products.filter((product) =>
      product.categories.some((c) => c.id === selectedCategory),
    );

    if (selectedCategoryObject?.type === CategoryType.PIZZA) {
      let resultProducts: typeof products = [];

      selectedCategoryObject?.pizzaSizes?.forEach((size) => {
        resultProducts = resultProducts.concat(
          (
            productsWithCategory.map((product) => {
              const productSize = product?.pizzaSizes?.find(
                (pSize) => pSize.pizzaSizeId === size?.id,
              );

              if (!productSize) {
                return undefined;
              }

              const pizzaSize: IProductPizzaSize = {
                ...productSize,
                id: productSize?.id || 0,
                pizzaCrusts: size?.pizzaCrusts,
                pizzaEdges: size?.pizzaEdges,
                price: productSize?.price || 0,
                pizzaSizeId: size.id,
                name: productSize?.name || '',
                slices: size?.slices || 0,
                deleted: productSize?.deleted || false,
                flavours: size?.flavours || 0,
              };

              const minCrustPrice =
                pizzaSize?.pizzaCrusts?.length > 0
                  ? Math.min(...pizzaSize?.pizzaCrusts.map((crust) => crust.price))
                  : 0;

              const minEdgePrice =
                pizzaSize?.pizzaEdges?.length > 0
                  ? Math.min(...pizzaSize?.pizzaEdges.map((edge) => edge.price))
                  : 0;

              return {
                ...product,
                complementsGroups: product?.complementsGroups?.filter(
                  (group) => group?.category !== 'PIZZA',
                ),
                subcategory: {
                  id: size.id,
                  active: true,
                  name: `${selectedCategoryObject?.name} - ${size.name}`,
                  position: 0,
                  type: CategoryType.PIZZA,
                },
                title: `${product.title} - ${size.name}`,
                unitPrice: productSize?.price || 0,
                suggestedPrice: (productSize?.price || 0) + minCrustPrice + minEdgePrice,
                pizzaSize,
              };
            }) as []
          )?.filter((item) => item !== undefined),
        );
      });

      return resultProducts;
    }

    return productsWithCategory;
  }, [selectedCategory, products, categories]);

  const productsWithoutSubcategory = useMemo(() => {
    if (selectedCategory === -1) {
      return topProducts;
    }

    if (selectedCategory === -2) {
      return products.filter((product) => !product.subcategory && product.salePrice > 0);
    }

    const selectedCategoryObject = categories.find(
      (currentCategory) => currentCategory.id === selectedCategory,
    );

    if (selectedCategoryObject?.type === CategoryType.PIZZA) {
      return [];
    }

    return products.filter(
      (product) =>
        !product.subcategory && product.categories.some((c) => c.id === selectedCategory),
    );
  }, [products, selectedCategory, topProducts, categories]);

  const categorySubcategories = useMemo(() => {
    const aux: ICategory[] = [];
    const auxIds: number[] = [];

    categoryProducts
      .filter((product) => {
        return product.subcategory;
      })
      .forEach((product) => {
        const hasSubcategory = !!auxIds.find((id) => {
          return id === product.subcategory.id;
        });

        if (!hasSubcategory) {
          aux.push(product.subcategory);
          auxIds.push(product.subcategory.id);
        }
      });

    return aux.sort((current, next) => {
      if (current.position < next.position) {
        return -1;
      }

      if (current.position === next.position) {
        return 0;
      }

      return 1;
    });
  }, [categoryProducts]);

  const categoriesGrouped = useMemo(() => {
    const productsCategory: ILocalCategory[] = [];

    if (company.hasPromotionalProducts) {
      productsCategory.push({
        id: -2,
        label: 'Promoções',
        type: CategoryType.STANDARD,
        productsWithoutSubcategory: discountProducts,
        subcategories: [],
      });
    }

    if (topProducts.length > 0) {
      productsCategory.push({
        id: -1,
        label: 'Mais vendidos',
        productsWithoutSubcategory: topProducts,
        subcategories: [],
        type: CategoryType.STANDARD,
      });
    }

    categories.forEach((c) => {
      const productsWithCategory = products.filter((p) => {
        return p.categories.some((category) => category.id === c.id);
      });

      if (c.type === CategoryType.PIZZA) {
        return orderArrayByPosition(c?.pizzaSizes || [])?.forEach((size) => {
          return productsCategory.push({
            id: size.id,
            label: `${c.name} - ${size.name}`,
            type: c.type,
            pizzaCategoryId: c.id,
            subcategories: [],
            productsWithoutSubcategory: (
              productsWithCategory.map((product) => {
                const productSize = product?.pizzaSizes?.find(
                  (pSize) => pSize.pizzaSizeId === size?.id,
                );

                if (!productSize) {
                  return undefined;
                }

                const pizzaSize: IProductPizzaSize = {
                  ...productSize,
                  id: productSize?.id || 0,
                  pizzaCrusts: size?.pizzaCrusts || [],
                  pizzaEdges: size?.pizzaEdges || [],
                  price: productSize?.price || 0,
                  pizzaSizeId: size.id,
                  name: productSize?.name || '',
                  slices: size?.slices || 0,
                  deleted: productSize?.deleted || false,
                  flavours: size?.flavours || 0,
                };

                return {
                  ...product,
                  complementsGroups: product?.complementsGroups?.filter(
                    (group) => group?.category !== 'PIZZA',
                  ),
                  title: `${product.title} - ${size.name}`,
                  unitPrice: productSize?.price || 0,
                  suggestedPrice: productSize?.price || 0,
                  pizzaSize,
                };
              }) as []
            )?.filter((item) => item !== undefined),
          });
        });
      }

      const subcategories: ILocalCategory['subcategories'] = [];
      const withoutSubcategory: ILocalProduct[] = [];

      productsWithCategory?.forEach((product) => {
        if (!product?.subcategory) {
          return withoutSubcategory.push(product);
        }

        const foundIndex = subcategories?.findIndex((sub) => sub?.id === product.subcategory?.id);

        if (foundIndex !== -1) {
          const sub = subcategories[foundIndex];
          return (subcategories[foundIndex] = {
            ...sub,
            products: sub?.products?.concat([product]),
          });
        }

        return subcategories.push({
          id: product?.subcategory?.id,
          name: product?.subcategory?.name,
          products: [product],
        });
      });

      return productsCategory.push({
        id: c.id,
        label: c.name,
        type: c?.type,
        subcategories,
        productsWithoutSubcategory: withoutSubcategory,
      });
    });

    const filteredProductsCategory = productsCategory?.filter((category) =>
      category?.subcategories?.length === 0
        ? category?.productsWithoutSubcategory?.length > 0
        : true,
    );

    if (company?.collapseCategories && filteredProductsCategory.length > 0) {
      setCollapsedCategories(filteredProductsCategory.map((category) => category.id));
    }

    return filteredProductsCategory;
  }, [products, categories, topProducts, discountProducts, company]);

  const categoryName = useMemo(() => {
    const name =
      selectedCategory === -1
        ? 'Mais vendidos'
        : categories.find((category) => category.id === selectedCategory)?.name?.toLowerCase();

    if (name) {
      return ` / ${name}`;
    }

    return '';
  }, [selectedCategory, categories]);

  const filteredProducts = useMemo(() => {
    if (filter) {
      return search(
        products,
        (product) => {
          const categoryNames = product.categories.map((c) => c.name).join('');
          return `${product.title}${
            product.subcategory?.name || ''
          }${categoryNames}${product.tags.join()}`;
        },
        filter,
      );
    }

    if (selectedCategory) {
      return products.filter((product) =>
        product.categories.find((c) => c.id === selectedCategory),
      );
    }

    return products;
  }, [filter, products, selectedCategory]);

  const allCategories = useMemo<ICategory[]>(() => {
    const productsAllCateogires: ICategory[] = [
      {
        id: 0,
        name: 'Todas',
        position: 0,
        active: true,
        type: CategoryType.STANDARD,
      },
    ];

    if (company.hasPromotionalProducts) {
      productsAllCateogires.push({
        id: -2,
        name: 'Promoções',
        position: 0,
        active: true,
        type: CategoryType.STANDARD,
      });
    }

    if (topProducts.length > 0) {
      productsAllCateogires.push({
        id: -1,
        name: 'Mais vendidos',
        position: 0,
        active: true,
        type: CategoryType.STANDARD,
      });
    }

    return productsAllCateogires.concat(categories);
  }, [categories, topProducts, company]);

  const normalizedAddress = useMemo(() => {
    const completeAddress = `${selectedAddress.streetName}, ${selectedAddress.streetNumber}`;

    if (completeAddress.length > 25) {
      return `${completeAddress.substr(0, 22)}...`;
    }

    return completeAddress;
  }, [selectedAddress]);

  const tabs = useMemo(() => {
    const tabsArray = [
      {
        id: 0,
        value: 'Todas',
      },
    ];

    if (company.hasPromotionalProducts) {
      tabsArray.push({
        id: -2,
        value: 'Promoções',
      });
    }

    if (topProducts?.length > 0) {
      tabsArray.push({
        id: -1,
        value: 'Mais vendidos',
      });
    }

    return [
      ...tabsArray,
      ...categories.map((c) => ({
        id: c.id,
        value: c.name,
        enableIncludes: c.type === CategoryType.PIZZA,
      })),
    ];
  }, [categories, topProducts, company]);

  const subcategoryTabs = useMemo(() => {
    return [...categorySubcategories.map((sub) => ({ id: sub.id, value: sub.name }))];
  }, [categorySubcategories]);

  const handleSmallSearchClick = useCallback(() => {
    setIsInputfocused(true);
  }, []);

  const handleSmallSearchCloseClick = useCallback(() => {
    setFilter('');
    setIsInputfocused(false);
  }, []);

  const handleCategoriesScrollRight = useCallback((ref?: React.RefObject<HTMLDivElement>) => {
    const containerSize = (ref || categoriesRef).current?.clientWidth || 1;

    const scrollLeft = ((ref || categoriesRef).current?.scrollLeft || 1) + containerSize - 62;

    (ref || categoriesRef).current?.scrollTo({
      top: 0,
      left: scrollLeft,
      behavior: 'smooth',
    });
  }, []);

  const handleCategoriesScrollLeft = useCallback((ref?: React.RefObject<HTMLDivElement>) => {
    const containerSize = (ref || categoriesRef).current?.clientWidth || 1;

    const scrollLeft = ((ref || categoriesRef).current?.scrollLeft || 1) - containerSize - 62;

    (ref || categoriesRef).current?.scrollTo({
      top: 0,
      left: scrollLeft,
      behavior: 'smooth',
    });
  }, []);

  const handleTabScrollRight = useCallback((ref?: React.RefObject<HTMLDivElement>) => {
    const containerSize = (ref || tabContainerRef).current?.clientWidth || 1;

    const scrollLeft = ((ref || tabContainerRef).current?.scrollLeft || 1) + containerSize;

    (ref || tabContainerRef).current?.scrollTo({
      top: 0,
      left: scrollLeft,
      behavior: 'smooth',
    });

    if (
      tabContainerRef &&
      tabContainerRef.current &&
      tabContainerRef.current.scrollWidth <= scrollLeft
    ) {
      setBlockScroll('RIGHT');
    } else {
      setBlockScroll('NONE');
    }
  }, []);

  const handleTabScrollLeft = useCallback((ref?: React.RefObject<HTMLDivElement>) => {
    const containerSize = (ref || tabContainerRef).current?.clientWidth || 1;

    const scrollLeft = ((ref || tabContainerRef).current?.scrollLeft || 1) - containerSize;

    (ref || tabContainerRef).current?.scrollTo({
      top: 0,
      left: scrollLeft,
      behavior: 'smooth',
    });

    if (scrollLeft <= 0) {
      setBlockScroll('LEFT');
    } else {
      setBlockScroll('NONE');
    }
  }, []);

  const handleCategoriesModalOpen = useCallback(() => {
    setIsCategoriesModalOpen(true);
  }, []);

  const handleCategoriesModalClose = useCallback(() => {
    setIsCategoriesModalOpen(false);
  }, []);

  const handleOnShowProfileModal = useCallback(() => {
    setIsProfileModalOpen(true);
  }, []);

  const handleCloseProfileModal = useCallback(() => {
    setIsProfileModalOpen(false);
  }, []);

  const handleOnEditItem = useCallback(
    (item: ICartItem) => {
      setItemToUpdate(item);
      setSelectedProduct(item.productId || 0);
      setSelectedPizzaCategory(item?.pizzaCategoryId || null);
      setSelectedPizzaSize(item?.productPizzaSize || null);
      setIsCustomPizza(item?.isCustomPizza || false);
    },
    [setSelectedProduct, setSelectedPizzaCategory, setSelectedPizzaSize],
  );

  const handleOnCompanyRegionsModalClose = useCallback(() => {
    setIsCompanyRegionModalOpen(false);
  }, []);

  const handleOnCompanyRegionsModalOpen = useCallback(() => {
    setIsCompanyRegionModalOpen(true);
  }, []);

  const handleOnCollapsibleCategoryButtonClick = (id: number) => {
    setCollapsedCategories((old) =>
      old.includes(id) ? old.filter((current) => current !== id) : old.concat([id]),
    );
  };

  const selectedProductObject = useMemo(() => {
    return products.find((item) => item.id === selectedProduct);
  }, [products, selectedProduct]);

  const activeMessages = useMemo(() => {
    return messages.filter((m) => m.active);
  }, [messages]);

  const CartComponent = useMemo(
    () => () => {
      return (
        <Cart
          small
          isFinish={false}
          onClose={handleCartClose}
          onEditItem={handleOnEditItem}
          visible={isOrderSummaryVisible}
        />
      );
    },
    [isOrderSummaryVisible, handleCartClose, handleOnEditItem],
  );

  const isTableShowcase = useMemo(() => {
    return isTable && company.showcaseTable;
  }, [company, isTable]);

  const isCallWaiter = useMemo(() => {
    return isTable && company.callWaiter;
  }, [company, isTable]);
  const IsCleanTheTable = useMemo(() => {
    return isTable && company.cleanTheTable;
  }, [company, isTable]);
  const isDetailedBill = useMemo(() => {
    return isTable && company.detailedBill;
  }, [company, isTable]);
  const isCheckBill = useMemo(() => {
    return isTable && company.checkBill;
  }, [company, isTable]);

  const isDeliveryShowcase = useMemo(() => {
    return !isTable && company.showcaseDelivery;
  }, [company, isTable]);

  const hasMoreThanOnePizzaCategory = useMemo(
    () => categories?.filter((c) => c?.type === CategoryType.PIZZA)?.length > 1,
    [categories],
  );

  const hideSearch = useMemo(() => {
    return categories?.some((category) => category?.type === CategoryType.PIZZA);
  }, [categories]);

  useEffect(() => {
    if (!loading && !productsLoading && categoriesRef.current) {
      const option = Array.from(categoriesRef.current.children).find(
        (child) => Number(child.getAttribute('data-id')) === selectedCategory,
      );

      if (categoriesRef.current && option) {
        option.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    }
  }, [loading, productsLoading, selectedCategory]);

  if (loading || productsLoading) return <LoadingPage />;

  return (
    <Container showcase={isShowcase || isTableShowcase || isDeliveryShowcase}>
      {isTable && (
        // (isCallWaiter || IsCleanTheTable || isDetailedBill || isCheckBill) &&
        <ModalCallWaiter
          hash={table.tableToken}
          open={openCallWaiter}
          setOpen={setOpenCallWaiter}
          isCallWaiter={isCallWaiter}
          IsCleanTheTable={IsCleanTheTable}
          isTableShowcase={isTableShowcase}
          isCheckBill={isCheckBill}
          isDetailedBill={isDetailedBill}
        />
      )}
      <CompanyInfoModal
        open={isCompanyInfoModalOpen}
        onClose={handleCompanyInfoModalClose}
        onShowDeliveryRegions={handleOnCompanyRegionsModalOpen}
      />
      <CategoriesModal
        categories={allCategories}
        selectedCategory={selectedCategory}
        open={isCategoriesModalOpen}
        onClose={handleCategoriesModalClose}
        onSelect={handleSelectCategory}
      />
      <Content visible={!selectedProduct}>
        <Main ref={mainRef} className="has-custom-scroll-bar">
          <Header showcase={isShowcase || isTableShowcase || isDeliveryShowcase}>
            <Companies onClick={handleCompanyNameClick} aria-label={`Sobre ${company.tradingName}`}>
              <FiMenu size={24} />
              <CompanyName isFocused={isInputfocused}>
                <strong>{company.tradingName}</strong>
              </CompanyName>
            </Companies>
            {selectedAddress.streetName && (
              <AddressContainer>
                <AddressIcon>
                  <FiMapPin size={24} />
                </AddressIcon>
                <AddressWrapper>
                  <AddressTitle>entregar em:</AddressTitle>
                  <Address>{normalizedAddress}</Address>
                </AddressWrapper>
                <AddressIcon
                  onClick={handleOnEditAddress}
                  aria-label="Selecionar endereço de entrega"
                >
                  <FiChevronDown size={24} />
                </AddressIcon>
              </AddressContainer>
            )}
            <HeaderRight>
              <Icons>
                {!(isShowcase || isTable || isTableShowcase) && (
                  <Share>
                    <ShareButton aria-label="Perfil">
                      <FiUser id="profile-icon" onClick={handleOnShowProfileModal} />
                    </ShareButton>
                  </Share>
                )}

                {isTable && (
                  <CartButton onClick={() => setOpenCallWaiter(true)} aria-label="Carrinho">
                    <FaRegHandPaper size={24} />
                  </CartButton>
                )}
                {isTable
                  ? !isTableShowcase && (
                      // eslint-disable-next-line react/jsx-indent
                      <CartButton onClick={handleCartOpen} aria-label="Carrinho">
                        <FiShoppingCart size={24} />
                        {cartAmount > 0 && <Badge>{cartAmount}</Badge>}
                      </CartButton>
                    )
                  : !isDeliveryShowcase && (
                      // eslint-disable-next-line react/jsx-indent
                      <CartButton onClick={handleCartOpen} aria-label="Carrinho">
                        <FiShoppingCart size={24} />
                        {cartAmount > 0 && <Badge>{cartAmount}</Badge>}
                      </CartButton>
                    )}
                {!hideSearch && (
                  <>
                    {!isInputfocused ? (
                      <SearchButton
                        isFocused={isInputfocused}
                        onClick={handleSmallSearchClick}
                        htmlFor={isInputfocused ? 'small-search' : ''}
                        aria-label="Pesquisar produtos"
                      >
                        <FiSearch size={24} className="search" />
                      </SearchButton>
                    ) : (
                      <SearchButton
                        isFocused={isInputfocused}
                        onClick={handleSmallSearchCloseClick}
                        htmlFor={isInputfocused ? 'small-search' : ''}
                        aria-label="Pesquisar produtos"
                      >
                        <FiX size={24} className="close" />
                      </SearchButton>
                    )}
                  </>
                )}
              </Icons>
            </HeaderRight>
            {!hideSearch && (
              <SearchSmall isFocused={isInputfocused}>
                <FiSearch />
                <input
                  tabIndex={-1}
                  type="text"
                  ref={inputRef}
                  value={filter}
                  disabled={loading}
                  placeholder="Pesquisar"
                  onChange={handleFilterChanged}
                  id="small-search"
                />
              </SearchSmall>
            )}
          </Header>
          <TopWrapper>
            {!hideSearch && (
              <Search>
                <input
                  type="text"
                  ref={inputRef}
                  value={filter}
                  disabled={loading}
                  placeholder="Pesquisar"
                  onChange={handleFilterChanged}
                />
                {filter && <FiX onClick={handleFilterReseted} aria-label="Limpar pesquisa" />}
              </Search>
            )}
            {selectedAddress.streetName && (
              <SmallAddressContainer>
                <AddressIcon dismissable>
                  <FiMapPin size={24} />
                </AddressIcon>
                <SmallAddressWrapper>
                  <AddressTitle>entregar em:</AddressTitle>
                  <Address>{normalizedAddress}</Address>
                </SmallAddressWrapper>
                <AddressIcon
                  onClick={handleOnEditAddress}
                  aria-label="Selecionar endereço de entrega"
                >
                  <FiChevronDown size={24} />
                </AddressIcon>
              </SmallAddressContainer>
            )}
            {!!company.minOrderPrice && !table.tableNumber && (
              <MinValue>
                <span>Pedido mínimo:</span>
                <strong>{formatToMoney(company.minOrderPrice)}</strong>
              </MinValue>
            )}
          </TopWrapper>
          <DistanceMessageContainer
            visible={
              !deliveryAvailable &&
              isDistanceWarningShown &&
              company?.companyDelivery?.type === AddressType.DISTANCE
            }
          >
            <DistanceMessage
              variation="PRODUCTS"
              onClose={handleOnHideDistanceWarning}
              visible={
                !deliveryAvailable &&
                isDistanceWarningShown &&
                company?.companyDelivery?.type === AddressType.DISTANCE
              }
            />
          </DistanceMessageContainer>
          {highlights.length > 0 && !(location?.state as any)?.categoryId && !filter && (
            <HighlightsContainer>
              <Carousel>
                {highlights.map((product, index) => (
                  <Highlight key={`${product.id}${index.toString()}`}>
                    <HighlightBlur
                      uri={product.imageUrl}
                      hasImage={!!product.imageUrl}
                      className="blur"
                    />
                    <ProductIcon
                      url={product.imageUrl}
                      alt={product.description || product?.title}
                    />
                    <HighlightText className="text">
                      <h1>{product.title}</h1>
                      <p>{product.description}</p>
                      <HighlightPrice className={product?.suggestedSalePrice ? 'hasSale' : ''}>
                        <span className="suggestedPrice">
                          {!!product?.suggestedSalePrice && 'De: '}
                          <span>{formatToMoney(product.suggestedPrice)}</span>
                        </span>
                        {!!product?.suggestedSalePrice && (
                          <span className="salePrice">
                            Por:&nbsp;
                            <span>{formatToMoney(product.salePrice)}</span>
                          </span>
                        )}
                      </HighlightPrice>
                      <button type="button" onClick={() => handleSelectProduct(product)}>
                        Ver Produto
                      </button>
                    </HighlightText>
                    {!!product?.suggestedSalePrice && (
                      <div className="image-container">
                        <SaleSvg />
                      </div>
                    )}
                  </Highlight>
                ))}
              </Carousel>
            </HighlightsContainer>
          )}
          <React.Suspense fallback={<Loading radius={24} />}>
            {(!isMobileScreen ? isTabVisible : true) && (
              <>
                <CategorySubcategoryTitle>
                  <strong>Categorias</strong>
                  <button type="button" onClick={handleCategoriesModalOpen}>
                    ver todas
                  </button>
                </CategorySubcategoryTitle>
                <TabsContainer showcase={isShowcase || isTableShowcase || isDeliveryShowcase}>
                  <Tab
                    tabs={tabs}
                    ref={tabContainerRef}
                    blockScroll={blockScroll}
                    onClick={handleSelectCategory}
                    scrollLeft={() => handleTabScrollLeft()}
                    scrollRight={() => handleTabScrollRight()}
                    selectedCategory={focusedCategory}
                  />
                </TabsContainer>
              </>
            )}
          </React.Suspense>
          {!filter && (
            <CategoriesContainer visible ref={categoriesContainerRef}>
              <CategorySubcategoryTitle>
                <strong>Categorias</strong>
                <button type="button" onClick={handleCategoriesModalOpen}>
                  ver todas
                </button>
              </CategorySubcategoryTitle>
              <ScrollCategorySubcategoryButton
                onClick={() => handleCategoriesScrollLeft()}
                aria-label="Rolar para esquerda"
              >
                <FiArrowLeft size={24} />
              </ScrollCategorySubcategoryButton>
              <CategoriesSubcategories className="has-custom-scroll-bar" ref={categoriesRef}>
                <div className="start" />
                {allCategories?.map((category, index) => (
                  <CategorySubcategory
                    key={category.id}
                    category={category}
                    onClick={() => handleSelectCategory(category.id)}
                    selected={selectedCategory === category.id}
                    className={index === allCategories.length - 1 ? 'last' : ''}
                  />
                ))}
                <div className="end" />
              </CategoriesSubcategories>
              <ScrollCategorySubcategoryButton
                right
                onClick={() => handleCategoriesScrollRight()}
                aria-label="Rolar para direita"
              >
                <FiArrowRight size={24} />
              </ScrollCategorySubcategoryButton>
            </CategoriesContainer>
          )}
          {categorySubcategories.length > 0 && (
            <>
              {!isMobileScreen ? (
                <CategoriesContainer visible ref={subcategoriesContainerRef}>
                  <CategorySubcategoryTitle isSubcategory>
                    <strong>Subcategorias</strong>
                  </CategorySubcategoryTitle>
                  <ScrollCategorySubcategoryButton
                    onClick={() => handleCategoriesScrollLeft(subcategoriesRef)}
                    size="SMALL"
                  >
                    <FiArrowLeft size={24} />
                  </ScrollCategorySubcategoryButton>
                  <CategoriesSubcategories className="has-custom-scroll-bar" ref={subcategoriesRef}>
                    <div className="start" />
                    {categorySubcategories?.map((category, index) => (
                      <CategorySubcategory
                        key={category.id}
                        category={category}
                        onClick={() => handleScrollToSubcategory(category.id)}
                        selected={selectedCategory === category.id}
                        className={index === allCategories.length - 1 ? 'last' : ''}
                        size="HALF"
                      />
                    ))}
                    <div className="end" />
                  </CategoriesSubcategories>
                  <ScrollCategorySubcategoryButton
                    right
                    onClick={() => handleCategoriesScrollRight(subcategoriesRef)}
                    size="SMALL"
                  >
                    <FiArrowRight size={24} />
                  </ScrollCategorySubcategoryButton>
                </CategoriesContainer>
              ) : (
                <>
                  <CategorySubcategoryTitle isSubcategory>
                    <strong>Subcategorias</strong>
                  </CategorySubcategoryTitle>
                  <TabsContainer
                    showcase={isShowcase || isTableShowcase || isDeliveryShowcase}
                    isSubcategoryTab
                  >
                    <Tab
                      tabs={subcategoryTabs}
                      ref={subTabContainerRef}
                      blockScroll={blockScroll}
                      onClick={handleScrollToSubcategory}
                      scrollLeft={() => handleTabScrollLeft(subTabContainerRef)}
                      scrollRight={() => handleTabScrollRight(subTabContainerRef)}
                      selectedCategory={selectedCategory}
                    />
                  </TabsContainer>
                </>
              )}
            </>
          )}
          <ProductsContainer visible companyOpen={isOpen}>
            <ProductsTitle>
              <strong>Produtos</strong>
              <span>
                {filter
                  ? ' / resultados da pesquisa'
                  : selectedCategory
                  ? categoryName
                  : ' / todos'}
              </span>
            </ProductsTitle>
            {activeMessages.length > 0 && (
              <Badges>
                {activeMessages.map((m) => (
                  <WarningBadge
                    key={m.id}
                    title={m.title}
                    color={m.hexColor}
                    message1={m.description}
                  />
                ))}
              </Badges>
            )}
            {filter ? (
              <Products>
                {filteredProducts.map((product) => (
                  <Product
                    key={product.id}
                    product={product}
                    onError={handleOnProductErrored}
                    onClick={() => handleSelectProduct(product)}
                  />
                ))}
                {filteredProducts.length === 0 && (
                  <NothingFound>
                    <span>nenhum produto encontrado...</span>
                  </NothingFound>
                )}
                <ProductPlaceholder />
                <ProductPlaceholder />
                <ProductPlaceholder />
              </Products>
            ) : (
              <>
                {selectedCategory ? (
                  <>
                    {categorySubcategories.length > 0 && (
                      <Subcategories>
                        {categorySubcategories.map((sub) => {
                          const isCollapsibleOpen = !collapsedCategories.includes(sub.id);

                          return (
                            <Subcategory key={sub.id}>
                              <SubcategoryTitle id={`subcategory-${sub.id}`}>
                                <LazyLoadImage
                                  alt={sub.name}
                                  src={chooseCategoryIcon(sub.name, true)}
                                />
                                <span>{sub.name}</span>
                                <CollapsibleButton
                                  aria-label={isCollapsibleOpen ? 'Encolher' : 'Expandir'}
                                  onClick={() => handleOnCollapsibleCategoryButtonClick(sub.id)}
                                  isOpen={isCollapsibleOpen}
                                >
                                  <FiChevronDown size={28} />
                                </CollapsibleButton>
                              </SubcategoryTitle>
                              <StateCollapsible isOpen={isCollapsibleOpen}>
                                <Products key={sub.id}>
                                  {categories.find((category) => category.id === selectedCategory)
                                    ?.type === CategoryType.PIZZA && (
                                    <CustomPizzaCard
                                      imageUrl={
                                        categories
                                          .find((c) => c.id === selectedCategory)
                                          ?.pizzaSizes?.find((s) => s.id === sub.id)?.imageUrl
                                      }
                                      label={sub.name}
                                      onClick={() => handleCustomPizzaSelect(selectedCategory)}
                                    />
                                  )}
                                  {categoryProducts.map((product) => {
                                    if (product.subcategory && product.subcategory.id === sub.id) {
                                      return (
                                        <Product
                                          key={product.id}
                                          product={product}
                                          onClick={() => handleSelectProduct(product)}
                                          onError={handleOnProductErrored}
                                        />
                                      );
                                    }
                                    return null;
                                  })}
                                  <ProductPlaceholder />
                                  <ProductPlaceholder />
                                  <ProductPlaceholder />
                                </Products>
                              </StateCollapsible>
                            </Subcategory>
                          );
                        })}
                      </Subcategories>
                    )}
                    {categorySubcategories.length > 0 && productsWithoutSubcategory.length > 0 && (
                      <NoSubcategoryTitle id="others">
                        <LazyLoadImage alt="Outros" src={chooseCategoryIcon('outros', true)} />
                        <span>Outros</span>
                      </NoSubcategoryTitle>
                    )}
                    <Products>
                      {productsWithoutSubcategory.map((product) => (
                        <Product
                          key={product.id}
                          product={product}
                          onError={handleOnProductErrored}
                          onClick={() => handleSelectProduct(product)}
                        />
                      ))}
                      <ProductPlaceholder />
                      <ProductPlaceholder />
                      <ProductPlaceholder />
                    </Products>
                  </>
                ) : (
                  <>
                    {categoriesGrouped.length > 0 && (
                      <Subcategories>
                        {categoriesGrouped.map((sub, index) => (
                          <Subcategory
                            key={sub.label}
                            data-id={sub?.pizzaCategoryId || sub.id}
                            // eslint-disable-next-line no-return-assign
                            ref={(el) => (categoriesRefs.current[index] = el)}
                          >
                            <SubcategoryTitle id={`category-${sub?.pizzaCategoryId || sub.id}`}>
                              <LazyLoadImage
                                src={chooseCategoryIcon(sub.label, true)}
                                alt={sub.label}
                              />
                              <span>{sub.label}</span>
                              <CollapsibleButton
                                aria-label={
                                  !collapsedCategories.includes(sub.id) ? 'Encolher' : 'Expandir'
                                }
                                onClick={() => handleOnCollapsibleCategoryButtonClick(sub.id)}
                                isOpen={!collapsedCategories.includes(sub.id)}
                              >
                                <FiChevronDown size={28} />
                              </CollapsibleButton>
                            </SubcategoryTitle>
                            <StateCollapsible isOpen={!collapsedCategories.includes(sub.id)}>
                              <Products key={sub.label}>
                                {sub.type === CategoryType.PIZZA && (
                                  <CustomPizzaCard
                                    imageUrl={
                                      categories
                                        .find((c) => c.id === sub.pizzaCategoryId)
                                        ?.pizzaSizes?.find((s) => s.id === sub.id)?.imageUrl
                                    }
                                    label={
                                      hasMoreThanOnePizzaCategory
                                        ? sub.label
                                            .replace(
                                              new RegExp(
                                                categories
                                                  ?.find((c) => c.id === sub?.pizzaCategoryId)
                                                  ?.pizzaSizes?.map((s) => s?.name)
                                                  ?.join('|') || '',
                                                'gi',
                                              ),
                                              '',
                                            )
                                            .replace(/[-]/g, '')
                                            .trim()
                                        : ''
                                    }
                                    onClick={
                                      () => handleCustomPizzaSelect(sub?.pizzaCategoryId || sub.id)
                                      // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                  />
                                )}
                                {sub.productsWithoutSubcategory.map((product) => (
                                  <Product
                                    key={product.id}
                                    product={product}
                                    onError={handleOnProductErrored}
                                    onClick={() => handleSelectProduct(product)}
                                  />
                                ))}
                                <ProductPlaceholder />
                                <ProductPlaceholder />
                                <ProductPlaceholder />
                              </Products>
                            </StateCollapsible>
                            <StateCollapsible isOpen={!collapsedCategories.includes(sub.id)}>
                              {sub?.subcategories?.length > 0 &&
                                sub?.subcategories?.map((subGroup) => (
                                  <React.Fragment key={`subcategory-${subGroup?.id}`}>
                                    <SubcategoryGroupTitle>
                                      <span>{subGroup.name}</span>
                                    </SubcategoryGroupTitle>
                                    <Products>
                                      {subGroup?.products?.map((product) => (
                                        <Product
                                          key={product.id}
                                          product={product}
                                          onError={handleOnProductErrored}
                                          onClick={() => handleSelectProduct(product)}
                                        />
                                      ))}
                                    </Products>
                                  </React.Fragment>
                                ))}
                            </StateCollapsible>
                          </Subcategory>
                        ))}
                      </Subcategories>
                    )}
                  </>
                )}
              </>
            )}
          </ProductsContainer>
          <Footer
            hasMarginBottom={cartTotal > 0 || (!isOpen && loading === false)}
            visible={!selectedProduct}
          >
            <Link to="/privacy-policy">Política de Privacidade</Link>
            <span className="company">
              {company?.companyName && company.companyName}
              {company?.companyName && company?.document && ' | '}
              {company?.document && (
                <>
                  {'CNPJ '}
                  {formatToDocument(company?.document)}
                </>
              )}
            </span>
            <DoneBy>
              <span>Feito com</span>
              <AiFillHeart size={20} />
              <span>por</span>
              <a target="_blank" rel="noreferrer noopener" href="https://buildsolutions.com.br/">
                Build Solutions
              </a>
              <span>.</span>
            </DoneBy>
          </Footer>
        </Main>
        {!isOpen && !loading ? (
          <FinishButtonContainer>
            <ClosedCompany>
              <span>A empresa encontra-se fechada.</span>
            </ClosedCompany>
          </FinishButtonContainer>
        ) : (
          <>
            {cartAmount > 0 && (
              <FinishButtonContainer>
                <FinishButton onClick={handleFinishOrder}>
                  <IconContainer>
                    <FiShoppingCart color={theme.palette.white} size={22} />
                  </IconContainer>
                  <div className="cart-text">
                    <span>ver carrinho</span>
                    <span>{formatToMoney(cartTotal)}</span>
                  </div>
                </FinishButton>
              </FinishButtonContainer>
            )}
          </>
        )}
      </Content>
      <React.Suspense fallback={<Loading radius={24} />}>
        {isTable ? (
          <>
            {!isShowcase && !company.showcaseTable && !selectedProductObject && <CartComponent />}
          </>
        ) : (
          <>
            {!isShowcase && !company.showcaseDelivery && !selectedProductObject && (
              <CartComponent />
            )}
          </>
        )}
        {(selectedProductObject || selectedPizzaCategory) && (
          <ProductDetails
            pizzaSize={selectedPizzaSize}
            cartItem={itemToUpdate}
            product={selectedProductObject}
            pizzaCategory={categories.find((category) => category.id === selectedPizzaCategory)}
            visible
            onCloseClick={handleOnCloseProductDetailsClick}
            isCustomPizza={isCustomPizza}
          />
        )}
      </React.Suspense>
      {!selectedProductObject && !selectedPizzaCategory && !selectedPizzaSize && (
        <ScrollToTopButton onClick={handleScrollToTop} visible={isScrollButtonVisible}>
          <FiArrowUp size={20} />
        </ScrollToTopButton>
      )}
      <ProfileModal visible={isProfileModalOpen} onClose={handleCloseProfileModal} />
      <ErrorModal
        message={errorMessage.description}
        isOpen={errorMessage.type !== 'NONE'}
        additionalOption={
          errorMessage.type === 'ADDITIONALS'
            ? {
                route: '/cart',
                title: 'Ir para o Carrinho',
              }
            : undefined
        }
        onClose={handleOnErrorModalClosed}
      />
      <AddressCreationModal
        address={addressToEdit}
        isOpen={isAddressCreationDialogOpen}
        showsPhoneInput={addresses.length === 0}
        onClose={handleToggleAddressCreationDialog}
      />
      <AddressSelectionModal
        addresses={addresses}
        isOpen={isAddressSelectionDialogOpen}
        onEdit={handleOnAddressEdit}
        onNewAddress={handleOnNewAddress}
        onDelete={handleOnAddressDeleted}
        onSelect={handleOnAddressSelected}
        onClose={handleToggleAddressSelectionDialog}
      />
      {isCompanyRegionModalOpen && (
        <CompanyRegionsModal onClose={handleOnCompanyRegionsModalClose} />
      )}
      {isBusinessHourModalOpen && (
        <BusinessHourModal isOpen onClose={handleToggleBusinessHourModal} />
      )}
    </Container>
  );
};

export default ProductsPage;
