export const rotateArray = <T>(array: T[], rotation: number): T[] => {
  if (rotation === 0) {
    return array;
  }

  const normalizedRotation = Math.abs(rotation);
  const factor = normalizedRotation % array.length;

  const part = array.splice(factor * Math.sign(rotation));
  return part.concat(array);
};

export const formatArray = <T>(array: T[], format: number, rotation = 0): T[] => {
  let normalized = array;

  if (format - array.length === 1) {
    normalized = [...array, ...array];
  }

  const result = normalized;

  if (array.length < format) {
    const diff = format - normalized.length;
    for (let i = 0; i < diff; i += 1) {
      const index = i % normalized.length;
      const item = normalized[index];

      result.push(item);
    }
  }

  return rotateArray(normalized, rotation).slice(0, format);
};

type IObjectWithPosition<T> = T & {
  position: number;
};

export const orderArrayByPosition = <T>(array: IObjectWithPosition<T>[], desc?: boolean): T[] =>
  array.sort((a, b) => {
    const factor = !desc ? 1 : -1;
    const result = a.position > b.position ? 1 : a.position < b.position ? -1 : 0;
    return result * factor;
  });
