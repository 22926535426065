export const formatToMoney = (value: number, noPrefix?: boolean): string => {
  if (noPrefix) {
    return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }).replace('R$', '');
  }

  return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
};

export const formatToDocument = (value: string): string =>
  value.length === 14
    ? value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    : value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

export const getFormattedPaymentType = (value: string): string => {
  switch (value) {
    case 'CREDIT_CARD':
      return 'Cartão de Crédito';
    case 'DEBIT_CARD':
      return 'Cartão de Débito';
    case 'PIX':
      return 'PIX';
    case 'PIC_PAY':
      return 'PicPay';
    case 'AME':
      return 'Ame';
    case 'FB_PAYMENT':
      return 'Whatsapp (Facebook Pay)';
    case 'CHECK':
      return 'Cheque';
    case 'VOUCHER':
      return 'Vale Refeição';
    case 'MONTHLY_PAYER':
      return 'Mensalista';
    default:
      return 'Dinheiro';
  }
};
