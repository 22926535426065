import styled, { keyframes } from 'styled-components';

interface IContainerProps {
  visible: boolean;
}

const appearFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to{
    transform: translateX(0);
  }
`;

export const Container = styled.aside<IContainerProps>`
  width: 475px;
  height: 100vh;

  display: ${({ visible }) => (visible ? 'flex' : 'none')};

  position: fixed;

  overflow-y: auto;
  overflow-x: hidden;

  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  z-index: 999;

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  animation: ${appearFromRight} 0.4s;

  border-left: 1px solid ${({ theme }) => theme.palette.border};
  background: ${({ theme }) => theme.palette.background_secondary};

  @media (max-width: 1150px) {
    width: 100%;
    height: 100%;

    display: ${({ visible }) => (visible ? 'flex' : 'none')};
  }
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 32px;
  padding-top: 0;

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  > span {
    align-self: flex-end;
    font-size: 20px;
  }
`;

export const Header = styled.div`
  width: 100%;
  position: sticky;
  top: 0;

  display: flex;
  align-items: center;

  justify-content: space-between;

  z-index: 2;
  padding: 32px;
  z-index: 5;

  background: ${({ theme }) => theme.palette.background_secondary};

  > div {
    display: flex;
    flex-direction: column;

    text-align: right;
  }

  @media (max-width: 1150px) {
    width: 100%;
  }
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  background: transparent;
`;

export const Name = styled.strong`
  font-size: 16px;
`;

export const Category = styled.span`
  text-align: right;
  font-size: 12px;
  color: #a8a8a8;
`;

export const Comments = styled.div`
  margin-top: 32px;

  textarea {
    width: 100%;
    height: 100px;

    padding: 8px;
    resize: none;
    margin-top: 8px;

    box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
    border: 1px solid ${({ theme }) => theme.palette.border};
    background: ${({ theme }) => theme.palette.background_white};
  }

  span {
    display: flex;
    justify-content: flex-end;
  }
`;

export const ProductImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;
  margin-bottom: 16px;

  width: 100%;
  overflow: hidden;

  border-radius: 16px;

  .illustrative-warning {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.palette.control};
    margin-top: 8px;
  }

  .product-icon {
    border-radius: 16px;
    width: 300px;
    height: 300px;
    object-fit: cover;
    object-position: center;
  }
`;

export const PrepareTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  left: 0;
  bottom: 0;
  position: absolute;

  border-radius: 0 16px 0 16px;

  background: ${({ theme }) => theme.palette.control_lighter};
  width: 100px;
  height: 40px;
`;

export const ProductInfo = styled.div``;

export const ProductDescription = styled.span`
  font-size: 14px;
`;

export const ComplementsGroups = styled.div`
  margin-top: 8px;
`;

interface IFooterProps {
  withPrice?: boolean;
}

export const Footer = styled.div<IFooterProps>`
  position: sticky;
  bottom: 0;
  display: flex;
  padding: 16px 32px;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.background};
  border-top: 1px solid ${({ theme }) => theme.palette.outline};
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.15);
  z-index: 5;
`;
export const Total = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  strong {
    font-size: 30px;
  }
  .suggestedPrice {
    margin-bottom: -6px;
    opacity: 0.8;
    display: flex;
    justify-content: space-between;
    span + span {
      margin-left: 12px;
    }
    .price {
      text-decoration: line-through;
    }
  }
  .salePrice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    span {
      margin-right: 12px;
      font-size: 20px;
    }
    strong {
      font-size: 20px;
    }
  }
  .normalPrice {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;

export const Separator = styled.div`
  width: 150%;
  border-bottom: 2px solid #f4f4f4;
`;
