/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useContext, createContext, useCallback } from 'react';
import api from '../services/api';
import { IPix } from '../models/IOrder';

interface PixData {
  getPix: (pixId: string) => Promise<IPix>;
  postPix: (body: PixOrder) => Promise<IPix>;
  deletePix: (pixId: string) => Promise<void>;
}
export interface PixOrder {
  orderPixTransactionId: string;
  total: number;
  orderType: string;
}

const Pix = createContext<PixData>({} as PixData);

interface IPixProvider {
  children: React.ReactNode;
}

export const PixProvider: React.FC<IPixProvider> = ({ children }) => {
  const deletePix = useCallback(async (pixId: string) => {
    await api.delete(`/restricted/pix/${pixId}`);
  }, []);
  const getPix = useCallback(async (pixId: string) => {
    const { data } = await api.get(`/restricted/pix/${pixId}`);
    return data;
  }, []);

  const postPix = useCallback(async (body: PixOrder) => {
    const { data } = await api.post<IPix>('/restricted/pix/menu', body);
    return data;
  }, []);

  return <Pix.Provider value={{ getPix, postPix, deletePix }}>{children}</Pix.Provider>;
};

export function usePix(): PixData {
  const context = useContext(Pix);

  if (!context) {
    throw new Error('usePix must be used within PixProvider');
  }

  return context;
}
