/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FiX } from 'react-icons/fi';
import { Header, Overlay, IconButton, ModalWrapper, ContainerModal } from './styles';
import Loading from '../Loading';

interface IModalProps {
  bg?: string;
  open: boolean;
  width?: string;
  zindex?: number;
  loading?: boolean;
  bottom?: boolean;
  children: React.ReactNode;
  allowFullscreen?: boolean;
  onCancel?: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Modal: React.FC<IModalProps> = ({
  bg,
  open,
  width,
  zindex,
  children,
  bottom = false,
  loading = false,
  allowFullscreen = true,
  setOpen,
  onCancel,
}) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleKeyDown = (event: any) => {
      if (event.key === 'Escape') {
        onCancel && onCancel();
        setOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onCancel, setOpen]);

  const modalRoot = document.createElement('div');

  useEffect(() => {
    // Adiciona a div criada após o body
    document.documentElement.appendChild(modalRoot);

    return () => {
      // Remove a div quando o componente for desmontado
      document.documentElement.removeChild(modalRoot);
    };
  }, [modalRoot]);

  return ReactDOM.createPortal(
    <Overlay
      bottom={bottom}
      zindex={zindex}
      isVisible={open}
      onClick={() => (onCancel ? onCancel() : setOpen(false))}
    >
      {loading ? (
        <Loading />
      ) : (
        <ModalWrapper
          bg={bg}
          width={width}
          bottom={bottom}
          isVisible={open}
          onClick={(e) => e.stopPropagation()}
          allowFullscreen={allowFullscreen}
        >
          <ContainerModal bottom={bottom}>
            {!bottom && (
              <Header>
                <IconButton onClick={() => (onCancel ? onCancel() : setOpen(false))}>
                  <FiX size={22} />
                </IconButton>
              </Header>
            )}

            {children}
          </ContainerModal>
        </ModalWrapper>
      )}
    </Overlay>,
    document.body,
  );
};

export default Modal;
