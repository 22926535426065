/* eslint-disable @typescript-eslint/indent */
import CategoryType from '../enum/Category';
import { PizzaComplementsGroupIds } from '../enum/Pizza';
import { IGroupChangedData } from '../models/ICartItem';
import ICategory from '../models/ICategory';
import IComplementsGroup from '../models/IComplementsGroup';
import IOrderItem from '../models/IOrderItem';
import IOrderItemComplementsGroup from '../models/IOrderItemComplementsGroup';
import { IPizzaCrust, IPizzaSize } from '../models/IPizza';
import IProduct, { IProductPizzaSize } from '../models/IProduct';
import { orderArrayByPosition } from './array';

type PizzaOrderItemComplementsGroupsKeys = {
  [key in PizzaComplementsGroupIds]?: {
    key: keyof Pick<IOrderItem, 'pizzaCrusts' | 'pizzaEdges' | 'pizzaSizes'>;
    title: string;
    displayName: string;
  };
};

const pizzaOrderItemComplementsGroupsKeys: PizzaOrderItemComplementsGroupsKeys = {
  [PizzaComplementsGroupIds.FLAVORS]: {
    key: 'pizzaSizes',
    title: 'sizes',
    displayName: 'Sabores',
  },
  [PizzaComplementsGroupIds.CRUSTS]: {
    key: 'pizzaCrusts',
    title: 'crusts',
    displayName: 'Massa',
  },
  [PizzaComplementsGroupIds.EDGES]: {
    key: 'pizzaEdges',
    title: 'edges',
    displayName: 'Borda',
  },
};

export const normalizeOrderItemComplementsGroup = (
  group: IOrderItemComplementsGroup,
  orderItem: IOrderItem,
  products: IProduct[],
  product?: IProduct,
): IComplementsGroup | null => {
  const pizzaKeyGroup = pizzaOrderItemComplementsGroupsKeys[group?.id as PizzaComplementsGroupIds];

  if (!pizzaKeyGroup) {
    return product?.complementsGroups.find((pg) => pg.id === group.id) || null;
  }

  const pizzaCategory = product?.categories.find(
    (category) => category.type === CategoryType.PIZZA,
  );
  const productPizzaSize = product?.pizzaSizes?.find(
    (productSize) => productSize.id === orderItem?.pizzaSizeId,
  );
  const pizzaSize = pizzaCategory?.pizzaSizes?.find(
    (size) => size.id === productPizzaSize?.pizzaSizeId,
  );

  if (pizzaKeyGroup.key === 'pizzaSizes') {
    const sameCategoryProducts = products.filter((currentProduct) =>
      currentProduct.categories.some((currentCategory) => currentCategory.id === pizzaCategory?.id),
    );

    return {
      id: PizzaComplementsGroupIds.FLAVORS,
      category: 'STANDARD',
      isActive: true,
      position: 1,
      title: 'sizes',
      displayName: 'Sabores',
      minAmount: group.minAmount,
      maxAmount: group.maxAmount,
      modules: '',
      complements: sameCategoryProducts.map((currentProduct, index) => {
        return {
          id: productPizzaSize?.id || 0,
          isActive: true,
          position: index - 1,
          unitPrice: (productPizzaSize?.price || 0) / (pizzaSize?.slices || 1),
          title: currentProduct.title,
          description: currentProduct.description,
          maxAmount: pizzaSize?.slices || 1,
          productId: currentProduct.id,
        };
      }),
    };
  }

  const pizzaComplementsGroup: IComplementsGroup | null = {
    id: PizzaComplementsGroupIds.CRUSTS,
    category: 'STANDARD',
    isActive: true,
    position: 1,
    title: pizzaKeyGroup.title,
    displayName: pizzaKeyGroup.displayName,
    minAmount: 1,
    maxAmount: 1,
    modules: '',
    complements: Array.isArray(pizzaSize?.[pizzaKeyGroup.key])
      ? (pizzaSize?.[pizzaKeyGroup.key] as IPizzaCrust[]).map((crust, index) => ({
          id: crust.id,
          title: crust.name,
          description: crust.name,
          unitPrice: crust.price,
          isActive: true,
          maxAmount: 1,
          position: index + 1,
        }))
      : [],
  };

  return pizzaComplementsGroup;
};

interface IValidadeComplementsGroupsOptions {
  pizzaCategory?: ICategory;
  complementsGroups: IComplementsGroup[];
  selectedComplementsGroups: IGroupChangedData[];
  product?: IProduct;
  isCustomPizza?: boolean;
  pizzaSize?: IProductPizzaSize;
  selectedPizzaSize?: IPizzaSize | null;
  selectedPizzaSlicesCount?: number | null;
}

interface IValidadeComplementsGroupsResult {
  errorMessage?: string | null;
  complementGroupId?: number;
}

export const validateComplementsGroups = ({
  pizzaCategory,
  complementsGroups,
  selectedComplementsGroups,
  product,
  isCustomPizza,
  selectedPizzaSize,
  selectedPizzaSlicesCount,
}: IValidadeComplementsGroupsOptions): IValidadeComplementsGroupsResult | null => {
  const mandatoryComplementsGroups = (
    (pizzaCategory ? complementsGroups : orderArrayByPosition(product?.complementsGroups || [])) ||
    []
  ).filter((complementsGroup) => complementsGroup.minAmount > 0);

  if (pizzaCategory) {
    if (isCustomPizza && !selectedPizzaSize) return { errorMessage: 'Selecione um tamanho.' };

    if (isCustomPizza && !selectedPizzaSlicesCount)
      return { errorMessage: 'Selecione uma quantidade de sabores.' };
  }

  if (!mandatoryComplementsGroups) return null;
  // eslint-disable-next-line
  for (const mandatoryGroup of mandatoryComplementsGroups) {
    const mandatorySelectedGroup = selectedComplementsGroups.find(
      (sg) => sg.id === mandatoryGroup.id,
    );

    if (mandatorySelectedGroup && mandatorySelectedGroup.totalAmount) {
      if (mandatoryGroup.minAmount > mandatorySelectedGroup.totalAmount) {
        return {
          errorMessage: `Selecione, ao menos, ${
            mandatoryGroup.minAmount > 1 ? `${mandatoryGroup.minAmount} itens` : '1 item'
          } do grupo ${mandatoryGroup.displayName || mandatoryGroup.title}.`,

          complementGroupId: mandatoryGroup?.id,
        };
      }
    } else {
      return {
        errorMessage: `Selecione, ao menos, ${
          mandatoryGroup.minAmount > 1 ? `${mandatoryGroup.minAmount} itens` : '1 item'
        } do grupo ${mandatoryGroup.displayName || mandatoryGroup.title}.`,

        complementGroupId: mandatoryGroup?.id,
      };
    }
  }

  return { errorMessage: null };
};
