import React, { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { AccordionContent, AccordionHeader, AccordionWrapper, ChevronIcon } from './styles';

interface IAccordionComplementProps {
  title: string;
  children: React.ReactNode;
}

const AccordionComplement: React.FC<IAccordionComplementProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <AccordionWrapper>
      <AccordionHeader onClick={() => setIsOpen((prev) => !prev)}>
        <ChevronIcon isOpen={isOpen}>
          <FiChevronDown size={22} />
        </ChevronIcon>
        <p>{title}</p>
      </AccordionHeader>
      <AccordionContent isOpen={isOpen}>{children}</AccordionContent>
    </AccordionWrapper>
  );
};

export default AccordionComplement;
