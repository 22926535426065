import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react'; // Importação unificada
import initialize from './utils/css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import resizeObserverPolyfill from './utils/polyfills';

serviceWorker.unregister();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [Sentry.browserTracingIntegration()], // Integração funcional:cite[2]
  tracesSampleRate: 0.1,
  enabled: !['development', 'staging'].includes(process.env.NODE_ENV),
});

initialize();
resizeObserverPolyfill();

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}
