import React, { useCallback, useEffect } from 'react';

import qs from 'query-string';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import Lottie from 'lottie-react';
import { useOrder } from '../../hooks/order';

import Container from './styles';
import { useAuth } from '../../hooks/auth';
import objectToQueryParams from '../../utils/uri';
import { useCompany } from '../../hooks/company';
import { LoadingAnimations } from '../../enum/LoadingAnimation';
import getAnimation from '../../utils/loader';

interface ILoadingPageProps {
  message?: string;
}
const LoadingPage: React.FC<ILoadingPageProps> = ({ message = 'Carregando, aguarde...' }) => {
  const [searchParams] = useSearchParams();
  const { company, loading } = useCompany();
  const animationData = getAnimation(company?.loadingAnimation || LoadingAnimations.DEFAULT);
  const navigate = useNavigate();
  const location = useLocation();
  const token = searchParams.get('token');
  const { loadTable, loadCard } = useOrder();
  const { customer, confirmToken } = useAuth();

  const loadData = useCallback(async () => {
    await loadTable();
    navigate('/');
  }, [navigate, loadTable]);

  const loadCardData = useCallback(async () => {
    await loadCard();
    navigate('/');
  }, [navigate, loadCard]);

  const loadUser = useCallback(
    async (token: string) => {
      try {
        if (typeof token === 'string') {
          await confirmToken(token);
        }
      } catch (error) {
        console.error('error', error);
      }
    },
    [confirmToken],
  );

  useEffect(() => {
    if (location.search) {
      if (location.pathname === '/hash') {
        loadData();
      } else if (location.pathname === '/cards/hash') {
        loadCardData();
      } else if (typeof token === 'string') {
        loadUser(token);
      }
    }
  }, [navigate, confirmToken, loadData, loadUser, loadCardData, token, location]);

  useEffect(() => {
    const params = qs.parse(location.search);
    const { redirect } = params;

    if (customer?.firstAccess) {
      navigate('me', {
        state: {
          subroute: 'user',
        },
      });
    } else if (customer && typeof redirect === 'string') {
      delete params.subdomain;
      delete params.redirect;
      delete params.token;

      const queryParams = objectToQueryParams(params);

      navigate(`/${redirect.replace(/\/+/, '')}?${queryParams}`);
    }
  }, [customer, location, navigate]);
  return (
    <Container>
      <div>
        {!loading && (
          <Lottie animationData={animationData} loop autoPlay style={{ width: 300, height: 300 }} />
        )}

        <span>{message}</span>
      </div>
    </Container>
  );
};

export default LoadingPage;
