import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 40px;
  gap: 2rem;

  @media (max-height: 840px) {
    gap: 1rem;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;

  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: end;
  }

  @media (max-height: 800px) {
    height: auto;
  }
`;

export const Button = styled.button`
  display: flex;
  width: 100%;
  padding: 12px;
  background: ${({ theme }) => theme.palette.background};
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  color: white;
  border-radius: 8px;
  gap: 2rem;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  border: none;

  transition: 400ms;

  &:hover {
    transform: scale(1.02);
  }

  @media (max-height: 840px) {
    padding: 8px;
  }
`;

export const Image = styled.div`
  width: 50px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 35px;
    height: 35px;
  }
`;
