import { styled } from 'styled-components';

export const Finish = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    * {
      color: ${({ theme }) => theme.palette.primary_contrast};
    }
    display: flex;
    margin-left: 16px;
    align-items: center;
    border: 0;
    width: 150%;
    padding: 24px;
    background: ${({ theme }) => theme.palette.primary};
    border-radius: 16px 0 0 16px;
    transition: transform 0.3s;
    gap: 16px;
    &:hover {
      transform: translateX(-5%);
    }
    span {
      font-size: 18px;
    }
    svg {
      height: 24px;
      width: 24px;
      margin-left: 32px;
    }
    @media (max-width: 1150px) {
      margin-left: 32px;
    }
  }

  div.go-back {
    margin-left: auto;
    margin-top: 1rem;

    svg {
      margin-left: 0;
    }
  }
`;

export const ProductAmount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
`;
