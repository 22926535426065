/* eslint-disable react/require-default-props */
import React, { useCallback } from 'react';

import { FiMinus, FiPlus } from 'react-icons/fi';

import { Container, RippleLeft, RippleRight } from './styles';

interface IAmountInputProps {
  value: number;
  // active?: boolean;
  disabled?: boolean;
  allowsZero?: boolean;
  onIncreaseAmount: (amount: number) => void;
  onDecreaseAmount: (amount: number) => void;
}

const AmountInput = ({
  value,
  disabled,
  allowsZero = true,
  onIncreaseAmount,
  onDecreaseAmount,
}: IAmountInputProps) => {
  const handleOnIncrease = useCallback(() => {
    if (!disabled) {
      onIncreaseAmount(value + 1);
    }
  }, [onIncreaseAmount, value, disabled]);

  const handleOnDecrease = useCallback(() => {
    if (!allowsZero && value === 1) {
      return;
    }

    if (value === 0) {
      return;
    }

    onDecreaseAmount(value - 1);
  }, [onDecreaseAmount, value, allowsZero]);

  return (
    <Container>
      <RippleLeft color="#f4f4f480" className="ripple-1" onClick={handleOnDecrease}>
        <FiMinus />
      </RippleLeft>

      <span>{value}</span>
      <RippleRight
        color="#f4f4f480"
        onClick={handleOnIncrease}
        className={`ripple-2 ${disabled ? 'disabled' : ''}`}
      >
        <FiPlus />
      </RippleRight>
    </Container>
  );
};

export default AmountInput;
