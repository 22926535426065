/* eslint-disable @typescript-eslint/indent */
import styled, { css } from 'styled-components';

export const ModalTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: -20px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 1rem;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;

  strong {
    font-size: 20px;
    font-weight: 700;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  height: calc(100% - 100px);
  box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 32px 0px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.palette.background_white};
  gap: 1rem;

  h2 {
    font-weight: 700;
  }
`;

export const GridProducts = styled.div<{ hasScroll: boolean }>`
  display: flex;
  max-height: 50vh;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  overflow-y: auto;
  width: 100%;
  padding-bottom: 10px;
  box-shadow: ${({ hasScroll }) =>
    hasScroll ? 'inset rgba(0, 0, 0, 0.45) 0px -25px 20px -35px' : 'none'};

  @media (max-width: 768px) {
    height: 100%;
    max-height: 100%;
  }
`;

export const Product = styled.div<{ complement?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  ${({ complement }) =>
    complement &&
    css`
      margin-bottom: 10px;
    `};

  .info {
    display: flex;
    justify-content: space-between;
  }
`;

export const Values = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: end;

  strong {
    font-weight: 700;
  }

  .service {
    font-size: 18px;
  }

  .total {
    font-size: 22px;
  }

  .value {
    display: flex;
    justify-content: space-between;
  }

  .right {
    display: flex;
    flex-direction: column;
    width: 80%;

    @media (max-width: 700px) {
      width: 50%;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  width: 100%;
  align-items: end;
  gap: 1rem;

  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: end;
  }

  @media (max-height: 800px) {
    height: auto;
  }
`;

export const Button = styled.button<{ outline?: boolean }>`
  display: flex;
  width: 100%;
  padding: 12px;
  background: ${({ theme, outline }) =>
    outline ? theme.palette.background : theme.palette.primary};
  color: white;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  transition: 400ms;

  &:hover {
    transform: scale(1.02);
  }

  border: 1px solid ${({ theme }) => theme.palette.primary};

  p {
    font-weight: 500;
    display: flex;
    justify-content: center;
    text-align: center;
    color: ${({ theme, outline }) =>
      outline ? theme.palette.primary : theme.palette.background_white};
    flex: 3;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-height: 840px) {
    padding: 8px;
  }
`;
