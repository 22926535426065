import React, { SetStateAction, useEffect, useMemo, useState } from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { v4 as uuid } from 'uuid';

import { useNavigate } from 'react-router-dom';
import pixsvg from '../../assets/icons/pix-flat.svg';
import pos from '../../assets/icons/pos.svg';

import { Image, Button, Wrapper, ContainerButtons } from './styles';
import theme from '../../styles/themes/light.theme';

import { useToast } from '../../hooks/toast';
import { PixOrder, usePix } from '../../hooks/pix';
// import { useServiceCommand } from '../../hooks/service-commands';

import { IPix } from '../../models/IOrder';

import Modal from '../Modal';
import ModalPix from '../ModalPix';

interface IModalPaymentsProps {
  open: boolean;
  // hash: string;
  handleCheckBill: () => Promise<void>;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
const ModalPayments: React.FC<IModalPaymentsProps> = ({
  open,
  // hash,
  setOpen,
  setLoading,
  handleCheckBill,
}) => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { postPix, getPix } = usePix();
  // const { getDetailedBill } = useServiceCommand();

  const [openPixModal, setOpenPixModal] = useState(false);
  const [pix, setPix] = useState<IPix>({} as IPix);
  const orderPixTransactionId = useMemo(() => uuid(), []);

  useEffect(() => {
    if (pix.status === 'APPROVED') {
      setTimeout(() => {
        setOpenPixModal(false);
        setOpen(false);
      }, 2800);
    }
  }, [pix.status, setOpen]);

  const handlePayWithPix = async () => {
    try {
      // const detailedBill = await getDetailedBill(hash);
      setLoading(true);

      const body: PixOrder = {
        orderPixTransactionId,
        orderType: 'ON_TABLE',
        total: 10,
      };
      const data = await postPix(body);
      setPix(data);
      setOpenPixModal(true);
    } catch (error) {
      addToast({
        description: `Erro ao gerar chave Pix: ${error}`,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCheckPix = async () => {
    try {
      setLoading(true);
      const data = await getPix(pix.pixId);
      setPix((prev) => ({ ...prev, status: data?.status }));
      if (data.status !== 'PENDING') {
        navigate('/table', { state: { isOrder: false } });
      }
    } catch (error) {
      addToast({
        description: `Erro ao consultar pagamento: ${error}`,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  // TESTAR COLOCAR O LOADING DENTRO DO MODAL
  return (
    <>
      (
      <Modal
        open={open}
        setOpen={setOpen}
        zindex={99999}
        allowFullscreen={false}
        bottom
        bg={theme.palette.background_white}
      >
        <Wrapper>
          <ContainerButtons>
            <Button onClick={handlePayWithPix}>
              <Image>
                <LazyLoadImage src={pixsvg} alt="Pix" />
              </Image>
              <p>Pix</p>
            </Button>
            <Button onClick={handleCheckBill}>
              <Image>
                <LazyLoadImage src={pos} alt="Maquininha" />
              </Image>
              <p>Pagar na mesa</p>
            </Button>
          </ContainerButtons>
        </Wrapper>
      </Modal>
      )
      {openPixModal && (
        <ModalPix
          pix={pix}
          open={openPixModal}
          setLoading={setLoading}
          setOpen={setOpenPixModal}
          handleCheckPix={handleCheckPix}
        />
      )}
    </>
  );
};

export default ModalPayments;
