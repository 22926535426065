/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useMemo, useState, useEffect, useContext, useCallback, createContext } from 'react';

import { useCookies } from 'react-cookie';

import ICompany from '../models/ICompany';
import IMessage from '../models/IMessage';
import IScheduledProduct from '../models/IScheduledProducts';

import api from '../services/api';

interface CompanyContextData {
  times: IScheduledProduct[];
  isOpen: boolean;
  loading: boolean;
  company: ICompany;
  isShowcase: boolean;
  messages: IMessage[];
  visitCompany(): void;
  loadTimes(scheduledType?: string): Promise<void>;
  loadCompany(): Promise<void>;
  checkStatus(): Promise<void>;
  loadMessages(): Promise<void>;
  saveCompany(company: ICompany): void;
}

const getMetaContent = (name: string): string | null => {
  const meta = document.querySelector(`meta[name="${name}"]`);
  return meta ? meta.getAttribute('content') : null;
};

const CompanyContext = createContext<CompanyContextData>({} as CompanyContextData);

const foundCompany = getMetaContent('bsfood-found-company') !== 'false';

interface ICompanyProvider {
  children: React.ReactNode;
}
export const CompanyProvider: React.FC<ICompanyProvider> = ({ children }) => {
  const [, , removeCookie] = useCookies(['visit-date']);

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [data, setData] = useState<ICompany>({} as ICompany);
  const [times, setTimes] = useState<IScheduledProduct[]>([]);

  useEffect(() => {
    setIsOpen(data.isOpen);
  }, [data]);

  const loadCompany = useCallback(async () => {
    if (foundCompany) {
      setLoading(true);

      const response = window?.bsfood_company || (await api.get<ICompany>('/companies'))?.data;

      localStorage.setItem('@BSFOOD:company', JSON.stringify(response));
      setData(response);
      setLoading(false);
    }
  }, []);

  const saveCompany = useCallback(
    (company: ICompany) => {
      setData(company);

      localStorage.setItem('@BSFOOD:company', JSON.stringify(company));
    },
    [setData],
  );

  const checkStatus = useCallback(async () => {
    return api
      .get<boolean>('/companies/is-open')
      .then((response) => {
        setIsOpen(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const visitCompany = useCallback(() => {
    if (foundCompany) {
      try {
        api.post('/companies/visit');
      } catch {
        removeCookie('visit-date');
      }
    }
  }, [removeCookie]);

  const loadMessages = useCallback(async () => {
    const response = await api.get<IMessage[]>('/companies/messages');
    setMessages(response.data);
  }, []);

  const loadTimes = useCallback(
    async (scheduledType?: 'delivery' | 'checkout' | 'drive_thru' | '') => {
      try {
        const formattedScheduledType =
          scheduledType && scheduledType === 'delivery' ? 'delivery' : 'pickup';

        const response = await api.get<IScheduledProduct[]>(
          `/companies/scheduled-products${
            formattedScheduledType && `?scheduledType=${formattedScheduledType}`
          }`,
        );

        setTimes(response.data);
      } catch {
        // todo
      }
    },
    [],
  );

  const isShowcase = useMemo(() => {
    return data?.showCase || false;
  }, [data]);

  return (
    <CompanyContext.Provider
      value={{
        times,
        isOpen,
        loading,
        messages,
        isShowcase,
        company: data,
        loadTimes,
        loadCompany,
        saveCompany,
        checkStatus,
        visitCompany,
        loadMessages,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export function useCompany(): CompanyContextData {
  const context = useContext(CompanyContext);

  if (!context) {
    throw new Error('useCompany must be used within CompanyProvider');
  }

  return context;
}
