import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './styles/global';
import AppProvider from './hooks';
import TableProvider from './providers/table';
import isStorageAvailable from './utils/security';
import { getTheme } from './utils/theme';
import isBrowserSupported from './utils/browser';
import { LocalStorageKeys } from './enum/Storage';
import * as cartOrderData from './stores/cartOrderData';
import Loading from './components/Loading';
import lazyWithRetry from './utils/react';
import AppRoutes from './routes';
import ProductsPage from './pages/ProductsPage';

// Componentes lazy com tipo explícito
const OfflineModal = lazyWithRetry(() => import('./components/OfflineModal'));
const ErrorBoundary = lazyWithRetry(() => import('./components/ErrorBoundary'));
const CookiesUseAlert = lazyWithRetry(() =>
  import('./components/Alert').then((m) => ({ default: m.CookiesUseAlert })),
);
const IncompatibleAlert = lazyWithRetry(() =>
  import('./components/Alert').then((m) => ({ default: m.IncompatibleAlert })),
);
const SecurityAlert = lazyWithRetry(() =>
  import('./components/Alert').then((m) => ({ default: m.SecurityAlert })),
);

const isAllStoragesAvailable =
  isStorageAvailable('localStorage') && isStorageAvailable('sessionStorage');
const theme = getTheme();

const App: React.FC = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(
    window.location.pathname === '/privacy-policy'
      ? 'true'
      : localStorage?.getItem(LocalStorageKeys.COOKIES_ACCEPTED) || '',
  );

  const handleOnCookiesAccept = () => setCookiesAccepted('true');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const couponRef = params.get('coupon');
    if (couponRef) cartOrderData.setValue('coupon', couponRef);
  }, []);

  const renderContent = useMemo(() => {
    if (!isAllStoragesAvailable) return <SecurityAlert default />;
    if (!isBrowserSupported()) return <IncompatibleAlert default />;

    return (
      <AppProvider>
        <TableProvider>
          <React.Suspense fallback={<ProductsPage />}>
            <OfflineModal default />
            <ErrorBoundary>
              {cookiesAccepted !== 'true' && (
                <React.Suspense fallback={null}>
                  <CookiesUseAlert default onAccept={handleOnCookiesAccept} />
                </React.Suspense>
              )}
              <AppRoutes />
            </ErrorBoundary>
          </React.Suspense>
        </TableProvider>
      </AppProvider>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllStoragesAvailable, isBrowserSupported, cookiesAccepted, handleOnCookiesAccept]);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <React.Suspense fallback={<Loading radius={82} />}>
          <GlobalStyle />
          {renderContent}
        </React.Suspense>
      </ThemeProvider>
    </Router>
  );
};

export default App;
