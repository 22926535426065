import { detect, Browser } from 'detect-browser';
import { compare } from 'compare-versions';

type IBrowserMinVersion = {
  [key in Browser]?: string;
};

const browsersMinVersions: IBrowserMinVersion = {
  chrome: '60',
  'chromium-webview': '60',
  edge: '60',
  'edge-chromium': '60',
  'edge-ios': '60',
  firefox: '60',
  safari: '12',
  android: '4.2',
  opera: '15',
  'opera-mini': '15',
  samsung: '12',
};

const isBrowserSupported = (): boolean => {
  const browser = detect();

  if (!browser) {
    return false;
  }

  const minVersion = browsersMinVersions[browser.name as Browser];

  if (!minVersion) {
    return true;
  }

  return compare(browser.version || '', minVersion, '>=');
};
export default isBrowserSupported;
