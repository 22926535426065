export enum LoadingAnimations {
  ASIAN_RICE = 'ASIAN_RICE',
  BEER = 'BEER',
  BREAD = 'BREAD',
  BUILD = 'BUILD',
  CAKE = 'CAKE',
  CEREAL = 'CEREAL',
  CROISSANT = 'CROISSANT',
  COFFEE = 'COFFEE',
  DONUT = 'DONUT',
  DISH = 'DISH',
  DRINK = 'DRINK',
  FISH_AND_CHIPS = 'FISH_AND_CHIPS',
  FRENCH_FRIES = 'FRENCH_FRIES',
  FRIED_PASTRY = 'FRIED_PASTRY',
  HAMBURGUER = 'HAMBURGUER',
  ICE_CREAM = 'ICE_CREAM',
  PANCAKE = 'PANCAKE',
  PIZZA = 'PIZZA',
  POPCORN = 'POPCORN',
  SALAD = 'SALAD',
  SUSHI = 'SUSHI',
  TACOS = 'TACOS',
  TURKEY = 'TURKEY',
  WINE = 'WINE',
  DEFAULT = 'DEFAULT',
}

export type LoadingAnimationType = keyof typeof LoadingAnimations;
