import React, { SetStateAction, useState } from 'react';

import theme from '../../styles/themes/light.theme';
import { Button, Container, GridButtons, Image, SvgWrapper } from './styles';

import { ReactComponent as IconFoodSvg } from '../../assets/food.svg';
import { ReactComponent as IconCleanSvg } from '../../assets/clean.svg';
import { ReactComponent as IconWaiterSvg } from '../../assets/waiter.svg';
import { ReactComponent as IconCloseSvg } from '../../assets/close-check.svg';
import { ReactComponent as CallWaiterSvg } from '../../assets/call-waiter.svg';
import { ReactComponent as IconQuestionSvg } from '../../assets/question-mark.svg';

import { useToast } from '../../hooks/toast';
import { useServiceCommand } from '../../hooks/service-commands';

import { IDetailedBill } from '../../models/IOrder';

import Modal from '../Modal';
// import ModalPooling from '../ModalPooling';
import ModalPayments from '../ModalPayments';
import LoadingPage from '../../pages/LoadingPage';
import ModalDetailCheck from '../ModalDetailCheck';
import PoolingCommand from '../../enum/PoolingCommand';
// import GooglePayButton from '../GooglePayButton';

interface IModalCartWaiterProps {
  hash: string;
  open: boolean;
  isCheckBill: boolean;
  isCallWaiter: boolean;
  isDetailedBill: boolean;
  isTableShowcase: boolean;
  IsCleanTheTable: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
}
const ModalCallWaiter: React.FC<IModalCartWaiterProps> = ({
  open,
  hash,
  isCheckBill,
  isCallWaiter,
  isDetailedBill,
  IsCleanTheTable,
  isTableShowcase,
  setOpen,
}) => {
  const { addToast } = useToast();
  const { pooling, getCheckBill, getCleanTable, getCallWaiter, getDetailedBill } =
    useServiceCommand();

  const [loading, setLoading] = useState(false);
  // const [isOpenPooling, setIsOpenPooling] = useState(false);
  const [detailedCheck, setDetailedCheck] = useState({} as IDetailedBill);
  const [loadingMessage, setLoadingMessage] = useState('Carregando, aguarde...');
  const [openPayments, setOpenPayments] = useState(false);
  const [openDetailCheck, setOpenDetailCheck] = useState(false);

  const handleCallWaiter = async () => {
    try {
      setLoading(true);
      setLoadingMessage('Chamando garçom, aguarde...');
      const data = await pooling(hash, PoolingCommand.CALL_WAITER);
      if (!data) {
        try {
          await getCallWaiter(hash);
          addToast({
            description: 'O Garçom já está vindo!',
            type: 'success',
          });
        } catch (error) {
          addToast({
            description: `Erro ao chamar o garçom ${error}!`,
            type: 'error',
          });
        }
      }
      if (data.status === 'PENDING') {
        addToast({
          description: 'O Garçom já foi chamado aguarde para chamar novamente!',
          type: 'info',
        });
      }
    } catch (error) {
      addToast({
        description: `Erro ao chamar o limpador de mesas!: ${error}`,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCleanTable = async () => {
    try {
      setLoading(true);
      setLoadingMessage('Chamando alguém para limpar a mesa, aguarde...');
      const data = await pooling(hash, PoolingCommand.CLEAN_TABLE);
      if (!data) {
        try {
          await getCleanTable(hash);
          addToast({
            description: 'O Garçom já está vindo limpar a mesa!',
            type: 'success',
          });
        } catch (error) {
          addToast({
            description: `Erro ao chamar o garçom ${error}!`,
            type: 'error',
          });
        }
      }
      if (data.status === 'PENDING') {
        addToast({
          description: 'O Garçom já foi chamado aguarde para chamar novamente!',
          type: 'info',
        });
      }
    } catch (error) {
      addToast({
        description: `Erro ao chamar o limpador de mesas!: ${error}`,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCheckBill = async () => {
    try {
      setLoading(true);
      setLoadingMessage('Fechando sua conta, aguarde...');
      const data = await pooling(hash, PoolingCommand.CHECK_BILL);
      if (!data) {
        try {
          await getCheckBill(hash);
          addToast({
            description: 'Garçom já está vindo fechar sua conta!',
            type: 'success',
          });
        } catch (error) {
          addToast({
            description: 'Erro ao chamar o garçom!',
            type: 'error',
          });
        }
      }
      if (data.status === 'PENDING') {
        addToast({
          description:
            'O Garçom já foi chamado para fechar a conta, aguarde para chamar novamente!',
          type: 'info',
        });
      }
      setOpenDetailCheck(false);
      setOpenPayments(false);
    } catch (error) {
      addToast({
        description: `Erro ao fechar a conta!: ${error}`,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDetailedBill = async () => {
    try {
      setLoading(true);
      setLoadingMessage('Processando pedido, aguarde...');
      const data = await getDetailedBill(hash);
      setOpenDetailCheck(true);
      setDetailedCheck(data);

      addToast({
        description: 'Aqui estão os dados do seu pedido!',
        type: 'success',
      });
    } catch (error) {
      addToast({
        description: `Erro ao buscar pedido!: ${error}`,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingPage message={loadingMessage} />
      ) : (
        <Modal open={open} setOpen={setOpen} bg={theme.palette.background_white}>
          <Container>
            <h1>Como posso ajudar?</h1>
            <Image>
              <CallWaiterSvg className="custom-svg" />
            </Image>
            <GridButtons>
              <Button onClick={() => setOpen(false)}>
                <SvgWrapper>
                  <IconFoodSvg />
                </SvgWrapper>
                {isTableShowcase ? <p>Ver cardápio</p> : <p>Fazer pedido</p>}
              </Button>
              {isCallWaiter && (
                <Button onClick={handleCallWaiter}>
                  <SvgWrapper>
                    <IconWaiterSvg />
                  </SvgWrapper>
                  <p>Chamar garçom</p>
                </Button>
              )}

              {IsCleanTheTable && (
                <Button onClick={handleCleanTable}>
                  <SvgWrapper>
                    <IconCleanSvg />
                  </SvgWrapper>
                  <p>Limpar mesa</p>
                </Button>
              )}
              {isDetailedBill && (
                <Button onClick={handleDetailedBill}>
                  <SvgWrapper>
                    <IconQuestionSvg />
                  </SvgWrapper>
                  <p>O que eu consumi?</p>
                </Button>
              )}

              {isCheckBill && (
                <Button onClick={() => setOpenPayments(true)}>
                  <SvgWrapper>
                    <IconCloseSvg />
                  </SvgWrapper>
                  <p>Fechar conta</p>
                </Button>
              )}

              {/* <GooglePayButton
                merchantId="1212"
                merchantName="Build Solutions"
                totalPrice="123"
              /> */}
            </GridButtons>
          </Container>
        </Modal>
      )}
      {openDetailCheck && (
        <ModalDetailCheck
          open={openDetailCheck}
          setOpen={setOpenDetailCheck}
          setOpenPayments={setOpenPayments}
          detailedCheck={detailedCheck}
        />
      )}

      {openPayments && (
        <ModalPayments
          open={openPayments}
          setOpen={setOpenPayments}
          setLoading={setLoading}
          handleCheckBill={handleCheckBill}
          // hash={hash}
        />
      )}

      {/* {isOpenPooling && (
        <ModalPooling
          pooling={() => pooling(hash, commandPooling)}
          deletePooling={() => deletePooling(hash, commandPooling)}
          open={isOpenPooling}
          setOpen={setIsOpenPooling}
        />
      )} */}
    </>
  );
};

export default ModalCallWaiter;
