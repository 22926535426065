/* eslint-disable @typescript-eslint/indent */
/* eslint-disable indent */
import styled from 'styled-components';
import { mergeColors } from '../../utils/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100px;
  min-width: 100px;

  border-radius: 8px;
  background: ${({ theme }) => theme.palette.white};
  border: 1px solid ${({ theme }) => theme.palette.border};

  user-select: none;

  div.ripple-2.disabled {
    * {
      color: ${({ theme }) =>
        mergeColors(theme.palette.black, theme.palette.background_white, 0.8)};
    }
    &:hover {
      background: #ccc4;
    }
  }
`;

export const RippleLeft = styled.div`
  border-radius: 8px 0 0 8px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.palette.primary};
    * {
      color: ${({ theme }) => theme.palette.primary_contrast};
    }
  }
`;

export const RippleRight = styled.div`
  border-radius: 0 8px 8px 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  cursor: pointer;

  * {
    color: ${({ theme }) => theme.palette.black};
  }

  &:hover {
    background: ${({ theme }) => theme.palette.primary};

    * {
      color: ${({ theme }) => theme.palette.primary_contrast};
    }
  }
`;
