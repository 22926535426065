import { ComponentType, lazy } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ComponentPromise<T = any> = Promise<{ default: ComponentType<T> }>;

const MAX_RETRIES = 5;
const RETRY_INTERVAL_MS = 200;

function retry(fn: () => ComponentPromise, retriesLeft = 5, interval = 1000): ComponentPromise {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export default function lazyWithRetry(
  component: () => ComponentPromise,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): React.LazyExoticComponent<React.ComponentType<any>> {
  return lazy(() => retry(component, MAX_RETRIES, RETRY_INTERVAL_MS));
}
