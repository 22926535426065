import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  gap: 2rem;

  @media (max-height: 840px) {
    gap: 1rem;
  }
`;

export const Image = styled.div.attrs({ className: 'warning-image' })`
  justify-self: center;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
  }

  .custom-svg .background {
    fill: ${({ theme }) => theme.palette.primary};
  }
  .custom-svg .shirt {
    fill: ${({ theme }) => theme.palette.primary};
  }

  @media (max-width: 700px) {
    width: 200px;
    height: 200px;
  }

  @media (max-height: 840px) {
    width: 200px;
    height: 200px;
  }

  @media (max-height: 710px) {
    width: 150px;
    height: 150px;
  }
`;

export const GridButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Button = styled.button`
  display: flex;
  border: none;
  padding: 12px;
  background: ${({ theme }) => theme.palette.background};
  color: white;
  border-radius: 8px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  transition: 400ms;

  &:hover {
    transform: scale(1.02);
  }

  p {
    font-weight: 500;
    flex: 3;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-height: 840px) {
    padding: 8px;
  }
`;

export const SvgWrapper = styled.div`
  flex: 1;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 25%;

  svg {
    width: 100%;
    height: 100%;
    max-width: 50px;
    max-height: 50px;
  }
`;
