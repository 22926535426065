import React, { createContext, useContext, useCallback, useState } from 'react';

import lazyWithRetry from '../utils/react';

const Snack = lazyWithRetry(() => import('../components/Snack'));

interface ToastContextData {
  addToast(message: ToastMessage): void;
  removeToast(id: string): void;
}

interface ToastMessage {
  type?: 'success' | 'error' | 'info';
  description?: string;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

interface IToastProvider {
  children: React.ReactNode;
}
export const ToastProvider: React.FC<IToastProvider> = ({ children }) => {
  const [messages, setMessages] = useState<ToastMessage | null>(null);

  const addToast = useCallback(({ type, description }: ToastMessage) => {
    const toast = {
      type,
      description,
    };

    setMessages(toast);
  }, []);

  const removeToast = useCallback(() => {
    setMessages(null);
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      {messages && <Snack message={messages} onDismiss={removeToast} />}
    </ToastContext.Provider>
  );
};

export function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be usef within ToastProvider');
  }

  return context;
}
