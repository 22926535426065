import React from 'react';

import { FaCheckCircle } from 'react-icons/fa';
import { Container, IconContainer, SizeButton, SizeButtonText } from './styles';
import { formatToMoney } from '../../utils/format';

export interface ISize {
  id: string;
  name: string;
  price: number;
}

interface ISizeSelectorProps {
  sizes: ISize[];
  activeSizeId: string | undefined;
  onSizePress: (size: ISize) => void;
}

const SizeSelector: React.FC<ISizeSelectorProps> = ({
  activeSizeId,
  sizes,
  onSizePress,
}: ISizeSelectorProps) => {
  return (
    <Container>
      {sizes.map((size) => {
        const isActive = activeSizeId === size.id;

        return (
          <SizeButton key={size.id} isActive={isActive} onClick={() => onSizePress(size)}>
            {isActive && (
              <IconContainer>
                <FaCheckCircle />
              </IconContainer>
            )}

            <SizeButtonText as="strong" isActive={isActive}>
              {size.name}
            </SizeButtonText>
            <SizeButtonText isActive={isActive}>{formatToMoney(size.price)}</SizeButtonText>
          </SizeButton>
        );
      })}
    </Container>
  );
};

export default SizeSelector;
