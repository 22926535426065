import gas from '../assets/icons/gas.svg';
import car from '../assets/icons/car.svg';
import tea from '../assets/icons/tea.svg';
import pie from '../assets/icons/pies.svg';
import beer from '../assets/icons/beer.svg';
import bowl from '../assets/icons/bowl.svg';
import crab from '../assets/icons/crab.svg';
import fish from '../assets/icons/fish.svg';
import kids from '../assets/icons/kids.svg';
import soda from '../assets/icons/soda.svg';
import wine from '../assets/icons/wine.svg';
import star from '../assets/icons/star-2.svg';
import house from '../assets/icons/house.svg';
import heart from '../assets/icons/heart.svg';
import plate from '../assets/icons/plate.svg';
import salad from '../assets/icons/salad.svg';
import shake from '../assets/icons/shake.svg';
import sushi from '../assets/icons/sushi.svg';
import table from '../assets/icons/table.svg';
import fries from '../assets/icons/fries.svg';
import money from '../assets/icons/money.svg';
import pizza from '../assets/icons/pizza.svg';
import steak from '../assets/icons/steak.svg';
import juice from '../assets/icons/juice.svg';
import water from '../assets/icons/water.svg';
import bakery from '../assets/icons/bakery.svg';
import coffee from '../assets/icons/coffee.svg';
import drinks from '../assets/icons/drinks.svg';
import hotdog from '../assets/icons/hotdog.svg';
import potato from '../assets/icons/potato.svg';
import shrimp from '../assets/icons/shrimp.svg';
import waiter from '../assets/icons/waiter.svg';
import candies from '../assets/icons/candies.svg';
import company from '../assets/icons/company.svg';
import chinese from '../assets/icons/chinese.svg';
import dessert from '../assets/icons/dessert.svg';
import lobster from '../assets/icons/lobster.svg';
import seafood from '../assets/icons/seafood.svg';
import cocktail from '../assets/icons/cocktail.svg';
import delivery from '../assets/icons/delivery.svg';
import pancakes from '../assets/icons/pancakes.svg';
import sandwich from '../assets/icons/sandwich.svg';
import hotDrink from '../assets/icons/hot-drink.svg';
import champagne from '../assets/icons/champagne.svg';
import ice_cream from '../assets/icons/ice-cream.svg';
import hamburguer from '../assets/icons/hamburger.svg';
import caipirinha from '../assets/icons/caipirinha.svg';
import credit_card from '../assets/icons/credit-card.svg';
import packed_lunch from '../assets/icons/packed-lunch.svg';
import shopping_bag from '../assets/icons/shopping-bag.svg';
import natural_sandwich from '../assets/icons/natural-sandwich.svg';
import cleanProduct from '../assets/icons/clean-product-black.svg';
import coldCuts from '../assets/icons/cold-cuts-black.svg';

import car_white from '../assets/icons/car-white.svg';
import money_white from '../assets/icons/money-white.svg';
import table_white from '../assets/icons/table-white.svg';
import delivery_white from '../assets/icons/delivery-white.svg';
import credit_card_white from '../assets/icons/credit-card-white.svg';
import shopping_bag_white from '../assets/icons/shopping-bag-white.svg';

import vr from '../assets/icons/brands/vr.svg';
import elo from '../assets/icons/brands/elo.svg';
import pix from '../assets/icons/brands/pix.svg';
import tck from '../assets/icons/brands/tck.svg';
import vale from '../assets/icons/brands/vale.svg';
import ame from '../assets/icons/brands/ame.svg';
import amex from '../assets/icons/brands/amex.svg';
import visa from '../assets/icons/brands/visa.svg';
import alelo from '../assets/icons/brands/alelo.svg';
import diners from '../assets/icons/brands/diners.svg';
import sodexo from '../assets/icons/brands/sodexo.svg';
import picpay from '../assets/icons/brands/picpay.svg';
import moneyBrand from '../assets/icons/brands/money.svg';
import whatsapp from '../assets/icons/brands/whatsapp.svg';
import hipercard from '../assets/icons/brands/hipercard.svg';
import coopercard from '../assets/icons/brands/coopercard.svg';
import mastercard from '../assets/icons/brands/mastercard.svg';

import gasWhite from '../assets/icons/gas-white.svg';
import teaWhite from '../assets/icons/tea-white.svg';
import pieWhite from '../assets/icons/pies-white.svg';
import beerWhite from '../assets/icons/beer-white.svg';
import bowlWhite from '../assets/icons/bowl-white.svg';
import crabWhite from '../assets/icons/crab-white.svg';
import fishWhite from '../assets/icons/fish-white.svg';
import kidsWhite from '../assets/icons/kids-white.svg';
import sodaWhite from '../assets/icons/soda-white.svg';
import wineWhite from '../assets/icons/wine-white.svg';
import starWhite from '../assets/icons/star-2-white.svg';
import plateWhite from '../assets/icons/plate-white.svg';
import saladWhite from '../assets/icons/salad-white.svg';
import shakeWhite from '../assets/icons/shake-white.svg';
import sushiWhite from '../assets/icons/sushi-white.svg';
import friesWhite from '../assets/icons/fries-white.svg';
import pizzaWhite from '../assets/icons/pizza-white.svg';
import steakWhite from '../assets/icons/steak-white.svg';
import juiceWhite from '../assets/icons/juice-white.svg';
import waterWhite from '../assets/icons/water-white.svg';
import bakeryWhite from '../assets/icons/bakery-white.svg';
import coffeeWhite from '../assets/icons/coffee-white.svg';
import drinksWhite from '../assets/icons/drinks-white.svg';
import hotdogWhite from '../assets/icons/hotdog-white.svg';
import potatoWhite from '../assets/icons/potato-white.svg';
import shrimpWhite from '../assets/icons/shrimp-white.svg';
import waiterWhite from '../assets/icons/waiter-white.svg';
import candiesWhite from '../assets/icons/candies-white.svg';
import chineseWhite from '../assets/icons/chinese-white.svg';
import dessertWhite from '../assets/icons/dessert-white.svg';
import lobsterWhite from '../assets/icons/lobster-white.svg';
import seafoodWhite from '../assets/icons/seafood-white.svg';
import cocktailWhite from '../assets/icons/cocktail-white.svg';
import pancakesWhite from '../assets/icons/pancakes-white.svg';
import sandwhichWhite from '../assets/icons/sandwich-white.svg';
import hotDrinkWhite from '../assets/icons/hot-drink-white.svg';
import champagneWhite from '../assets/icons/champagne-white.svg';
import icecreamWhite from '../assets/icons/ice-cream-white.svg';
import hamburgerWhite from '../assets/icons/hamburger-white.svg';
import caipirinhaWhite from '../assets/icons/caipirinha-white.svg';
import packedLunchWhite from '../assets/icons/packed-lunch-white.svg';
import naturalSandwichWhite from '../assets/icons/natural-sandwich-white.svg';
import cleanProductWhite from '../assets/icons/clean-product-white.svg';
import coldCutsWhite from '../assets/icons/cold-cuts-white.svg';

import promotion from '../assets/icons/discount.svg';
import promotionWhite from '../assets/icons/discount-white.svg';

import car_flat from '../assets/icons/car-flat.svg';
import take_away from '../assets/icons/take-away-flat.svg';
import delivery_bike from '../assets/icons/delivery-bike.svg';
import table_flat from '../assets/icons/table-flat.svg';

import money_flat from '../assets/icons/money-flat.svg';
import credit_card_flat from '../assets/icons/credit-card-flat.svg';
import pix_flat from '../assets/icons/pix-flat.svg';
import picpay_flat from '../assets/icons/picpay-flat.svg';
import ame_flat from '../assets/icons/ame-flat.svg';
import whatsapp_flat from '../assets/icons/whatsapp-flat.svg';
import check_flat from '../assets/icons/bank-check-flat.svg';
import voucher_flat from '../assets/icons/voucher-flat.svg';
import monthly_payer_flat from '../assets/icons/monthly-payer-flat.svg';

import { normalizeString } from './string';
import { chooseBsFoodOrVarejo } from './subdomain';

interface IconOption {
  check: string[];
  icon: string;
  whiteIcon?: string;
}

const categoryIconOptions: IconOption[] = [
  {
    check: ['folha', 'salada', 'vegano', 'vegetariano'],
    icon: salad,
    whiteIcon: saladWhite,
  },
  {
    check: ['pizza'],
    icon: pizza,
    whiteIcon: pizzaWhite,
  },
  {
    check: ['bebidas quentes'],
    icon: hotDrink,
    whiteIcon: hotDrinkWhite,
  },
  {
    check: ['cação', 'peixe', 'salmao', 'salmon', 'tilapia', 'abadejo', 'pescada', 'pescado'],
    icon: fish,
    whiteIcon: fishWhite,
  },
  {
    check: ['camarao', 'camaroes'],
    icon: shrimp,
    whiteIcon: shrimpWhite,
  },
  {
    check: ['porção', 'porcoes'],
    icon: fries,
    whiteIcon: friesWhite,
  },
  {
    check: ['carne', 'bifes'],
    icon: steak,
    whiteIcon: steakWhite,
  },
  {
    check: ['marmita', 'quentinha'],
    icon: packed_lunch,
    whiteIcon: packedLunchWhite,
  },
  {
    check: ['café', 'manhã', 'matinal', 'matinais'],
    icon: coffee,
    whiteIcon: coffeeWhite,
  },
  {
    check: ['doce', 'chocolate', 'bala'],
    icon: candies,
    whiteIcon: candiesWhite,
  },
  {
    check: ['lanche', 'burguer', 'hamburguer', 'hamburguers', 'hamburgueres'],
    icon: hamburguer,
    whiteIcon: hamburgerWhite,
  },
  {
    check: [
      'sanduba',
      'sanduiche',
      'lanche natural',
      'lanches naturais',
      'lanchinho natural',
      'lanchinhos naturais',
    ],
    icon: natural_sandwich,
    whiteIcon: naturalSandwichWhite,
  },
  {
    check: ['kids', 'junior', 'infantil'],
    icon: kids,
    whiteIcon: kidsWhite,
  },
  {
    check: ['sorvete', 'sorvetes', 'gelato', 'gelatos'],
    icon: ice_cream,
    whiteIcon: icecreamWhite,
  },
  {
    check: ['fruto do mar', 'frutos do mar'],
    icon: seafood,
    whiteIcon: seafoodWhite,
  },
  {
    check: [
      'lata',
      'refrigerante',
      'refri',
      'coca',
      'cola',
      'fanta',
      'guarana',
      'retornaveis',
      'retornavel',
      'energetico',
    ],
    icon: soda,
    whiteIcon: sodaWhite,
  },
  {
    check: ['chop', 'chopp', 'cerveja'],
    icon: beer,
    whiteIcon: beerWhite,
  },
  {
    check: [
      'dose',
      'drink',
      'vodka',
      'bebida',
      'whisky',
      'uisque',
      'cachaca',
      'licores',
      'tequila',
      'conhaque',
      'whiskeys',
      'digestivo',
      'destilados',
    ],
    icon: drinks,
    whiteIcon: drinksWhite,
  },
  {
    check: ['suco'],
    icon: juice,
    whiteIcon: juiceWhite,
  },
  {
    check: ['coquetel', 'coqueteis'],
    icon: cocktail,
    whiteIcon: cocktailWhite,
  },
  {
    check: ['caipirinha', 'caipiroska', 'caipirosca'],
    icon: caipirinha,
    whiteIcon: caipirinhaWhite,
  },
  {
    check: ['sushi', 'japonesa', 'oriental', 'orientais'],
    icon: sushi,
    whiteIcon: sushiWhite,
  },
  {
    check: ['chines', 'chinesa', 'yakisoba', 'yakissoba'],
    icon: chinese,
    whiteIcon: chineseWhite,
  },
  {
    check: ['agua'],
    icon: water,
    whiteIcon: waterWhite,
  },
  {
    check: ['vinho'],
    icon: wine,
    whiteIcon: wineWhite,
  },
  {
    check: ['chá'],
    icon: tea,
    whiteIcon: teaWhite,
  },
  {
    check: ['sobremesa'],
    icon: dessert,
    whiteIcon: dessertWhite,
  },
  {
    check: ['pão', 'pães', 'padaria'],
    icon: bakery,
    whiteIcon: bakeryWhite,
  },
  {
    check: ['champagne', 'champanhe', 'espumante'],
    icon: champagne,
    whiteIcon: champagneWhite,
  },
  {
    check: ['lagosta'],
    icon: lobster,
    whiteIcon: lobsterWhite,
  },
  {
    check: ['siri', 'carangueijo'],
    icon: crab,
    whiteIcon: crabWhite,
  },
  {
    check: ['lanchonete'],
    icon: sandwich,
    whiteIcon: sandwhichWhite,
  },
  {
    check: ['panqueca'],
    icon: pancakes,
    whiteIcon: pancakesWhite,
  },
  {
    check: ['torta', 'tortinha'],
    icon: pie,
    whiteIcon: pieWhite,
  },
  {
    check: ['bolo'],
    icon: dessert,
    whiteIcon: dessertWhite,
  },
  {
    check: ['prato', 'almoço', 'executivo'],
    icon: plate,
    whiteIcon: plateWhite,
  },
  {
    check: ['hotdog', 'hot dog', 'hot-dog', 'cachorro quente', 'cachorro-quente'],
    icon: hotdog,
    whiteIcon: hotdogWhite,
  },
  {
    check: ['batata'],
    icon: potato,
    whiteIcon: potatoWhite,
  },
  {
    check: ['batida', 'shake'],
    icon: shake,
    whiteIcon: shakeWhite,
  },
  {
    check: ['sopa', 'caldo'],
    icon: bowl,
    whiteIcon: bowlWhite,
  },
  {
    check: ['limpeza'],
    icon: cleanProduct,
    whiteIcon: cleanProductWhite,
  },
  {
    check: ['frios', 'presunto', 'salame', 'queijo'],
    icon: coldCuts,
    whiteIcon: coldCutsWhite,
  },
  {
    check: ['gas'],
    icon: gas,
    whiteIcon: gasWhite,
  },
  {
    check: ['mais vendidos'],
    icon: star,
    whiteIcon: starWhite,
  },
  {
    check: ['promoções'],
    icon: promotion,
    whiteIcon: promotionWhite,
  },
];

const findOption = (options: IconOption[], string: string): IconOption | null => {
  let result: IconOption | null = null;

  options.every((option) => {
    const normalizedCheck = option.check.map((item) => normalizeString(item));

    if (normalizedCheck.some((item) => normalizeString(string).includes(item))) {
      result = option;
      return false;
    }
    return true;
  });

  return result;
};

const addressIconOptions: IconOption[] = [
  {
    check: ['loja', 'firma', 'empresa', 'comércio', 'trabalho', 'escritório', 'estabelecimento'],
    icon: company,
  },
  {
    check: ['amor', 'vida', 'paixão', 'coração', 'namorad', 'love'],
    icon: heart,
  },
];

const brandIconOptions: IconOption[] = [
  { check: ['alelo'], icon: alelo },
  { check: ['amex'], icon: amex },
  { check: ['cheque'], icon: check_flat },
  { check: ['cooper'], icon: coopercard },
  { check: ['diners'], icon: diners },
  { check: ['elo'], icon: elo },
  { check: ['hipercard'], icon: hipercard },
  { check: ['mastercard'], icon: mastercard },
  { check: ['sodexo'], icon: sodexo },
  { check: ['visa'], icon: visa },
  { check: ['vr'], icon: vr },
  { check: ['ticket restaurante', 'tck'], icon: tck },
  { check: ['voucher'], icon: voucher_flat },
  { check: ['vale'], icon: vale },
  { check: ['pix'], icon: pix },
  { check: ['ame'], icon: ame },
  { check: ['picpay'], icon: picpay },
  { check: ['facebook', 'whatsapp'], icon: whatsapp },
  { check: ['debito', 'credito'], icon: credit_card_flat },
];

export const chooseCategoryIcon = (name: string, white?: boolean): string => {
  const defaultOption: Omit<IconOption, 'check'> = {
    icon: chooseBsFoodOrVarejo(waiter, shopping_bag),
    whiteIcon: chooseBsFoodOrVarejo(waiterWhite, shopping_bag_white),
  };

  const { icon, whiteIcon } = findOption(categoryIconOptions, name) || defaultOption;

  return (white ? whiteIcon : icon) || '';
};

export const chooseAddressIcon = (name = ''): string => {
  const option = findOption(addressIconOptions, name);
  return option?.icon || house;
};

export const choosePaymentTypeIcon = (ref: string, white: boolean): string => {
  switch (ref) {
    case 'CREDIT_CARD':
    case 'DEBIT_CARD':
      return white ? credit_card_white : credit_card;
    default:
      return white ? money_white : money;
  }
};

export const chooseDeliveryTypeIcon = (ref: string, white: boolean): string => {
  switch (ref) {
    case 'DELIVERY':
      return white ? delivery_white : delivery;
    case 'CHECKOUT':
      return white ? shopping_bag_white : shopping_bag;
    case 'ON_TABLE':
      return white ? table_white : table;
    default:
      return white ? car_white : car;
  }
};

export const chooseBrandIcon = (brand: string): string => {
  const option = findOption(brandIconOptions, brand);
  return option?.icon || moneyBrand;
};

export const getPaymentTypeIcon = (ref: string): string => {
  switch (ref) {
    case 'CREDIT_CARD':
    case 'DEBIT_CARD':
      return credit_card_flat;
    case 'PIX':
      return pix_flat;
    case 'PIC_PAY':
      return picpay_flat;
    case 'AME':
      return ame_flat;
    case 'FB_PAYMENT':
      return whatsapp_flat;
    case 'CHECK':
      return check_flat;
    case 'VOUCHER':
      return voucher_flat;
    case 'MONTHLY_PAYER':
      return monthly_payer_flat;
    default:
      return money_flat;
  }
};

export const getDeliveryTypeIcon = (type: string): string => {
  switch (type) {
    case 'CHECKOUT':
      return take_away;
    case 'DELIVERY':
      return delivery_bike;
    case 'ON_TABLE':
      return table_flat;
    default:
      return car_flat;
  }
};
