/* eslint-disable @typescript-eslint/ban-ts-comment */
async function resizeObserverPolyfill(): Promise<void> {
  if ('ResizeObserver' in window === false) {
    const module = await import('@juggle/resize-observer');
    // @ts-ignore
    window.ResizeObserver = module.ResizeObserver;
  }
}

export default resizeObserverPolyfill;
