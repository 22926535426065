import React, { useEffect } from 'react';

import { DateTime } from 'luxon';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOrder } from '../hooks/order';

interface ITableProvider {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
}

const TableProvider: React.FC<ITableProvider> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { table, card } = useOrder();

  useEffect(() => {
    const tableDate = sessionStorage.getItem('@BSFOOD:table_session');
    const cardDate = sessionStorage.getItem('@BSFOOD:card_session');
    if (tableDate && table.tableNumber > 0) {
      if (tableDate && typeof tableDate === 'string') {
        const { minutes } = DateTime.fromMillis(Number(tableDate)).diffNow('minutes').toObject();

        if (minutes && minutes < -15) {
          sessionStorage.removeItem('@BSFOOD:table_session');
          navigate('/table', { state: { expired: true } });
        }
      }
    }

    if (card.cardNumber > 0 && cardDate) {
      if (cardDate && typeof cardDate === 'string') {
        const { hours } = DateTime.fromMillis(Number(cardDate)).diffNow('hours').toObject();

        if (hours && hours < -1) {
          sessionStorage.removeItem('@BSFOOD:card_session');
          navigate('/table', { state: { expired: true } });
        }
      }
    }
  }, [navigate, location, card, table]);

  // Verifique se children existe, caso contrário, retorne null
  return children || null;
};
export default TableProvider;
