import React, { SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import Modal from '../Modal';

import {
  Title,
  Button,
  Values,
  Product,
  Wrapper,
  Container,
  ModalTitle,
  GridProducts,
  ContainerButtons,
} from './styles';

import AccordionComplement from '../AccordionComplement';
import { IDetailedBill } from '../../models/IOrder';
import { formatToMoney } from '../../utils/format';

interface IModalDetailCheckProps {
  open: boolean;
  detailedCheck: IDetailedBill;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  setOpenPayments: React.Dispatch<React.SetStateAction<boolean>>;
}
const ModalDetailCheck: React.FC<IModalDetailCheckProps> = ({
  open,
  detailedCheck,
  setOpen,
  setOpenPayments,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [hasScroll, setHasScroll] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      if (containerRef.current) {
        setHasScroll(containerRef.current.scrollHeight > containerRef.current.clientHeight);
      }
    };
    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => {
      window.removeEventListener('resize', checkScroll);
    };
  }, []);

  const checkTime = useMemo(() => {
    const createdAt = DateTime.fromISO(detailedCheck.createdAt, {
      locale: 'pt-BR',
    });
    const now = DateTime.now(); // Data atual

    // Calcular a diferença
    const difference = now.diff(createdAt, ['days', 'hours', 'minutes', 'seconds']).toObject();

    if ((difference?.days ?? 0) > 0) {
      return `${difference.days} dias atrás`;
    }
    if ((difference.hours ?? 0) > 0) {
      return `${difference.hours} horas atrás`;
    }
    if ((difference.minutes ?? 0) > 0) {
      return `${difference.minutes} minutos atrás`;
    }
    return 'poucos segundos atrás';
  }, [detailedCheck]);

  return (
    <Modal open={open} setOpen={setOpen} zindex={99998}>
      <Wrapper>
        <ModalTitle>
          <h2>Detalhes da conta</h2>
        </ModalTitle>
        <Title>
          <strong>Mesa 10</strong>
          <p>{`Aberto á ${checkTime}`}</p>
        </Title>
        <Container>
          <h2>Produtos</h2>
          <GridProducts className="has-custom-scroll-bar2" ref={containerRef} hasScroll={hasScroll}>
            {detailedCheck?.items?.map((prod) => (
              <Product key={prod.id} complement={(prod?.complementsGroups?.length ?? 0) > 0}>
                <div className="info">
                  <p>{`${prod.qty}X ${prod.title}`}</p>
                  <p>{formatToMoney(prod?.total ?? 0)}</p>
                </div>
                {prod?.isCustomPizza && (
                  <>
                    {(prod?.pizzaSizes?.length ?? 0) > 0 && (
                      <AccordionComplement title="Sabores">
                        {prod?.pizzaSizes?.map((complement) => (
                          <Product key={complement.id}>
                            <div className="info">
                              <p>{`${complement.amount}/${prod?.pizzaSizes?.length} ${complement.title}`}</p>
                              <p>
                                {formatToMoney((complement.unitPrice ?? 1) * complement?.amount)}
                              </p>
                            </div>
                          </Product>
                        ))}
                      </AccordionComplement>
                    )}

                    {(prod?.pizzaCrusts?.length ?? 0) > 0 && (
                      <AccordionComplement title="Borda">
                        {prod?.pizzaCrusts?.map((complement) => (
                          <Product key={complement.id}>
                            <div className="info">
                              <p>{complement.title}</p>
                              <p>
                                {formatToMoney((complement.unitPrice ?? 1) * complement?.amount)}
                              </p>
                            </div>
                          </Product>
                        ))}
                      </AccordionComplement>
                    )}

                    {(prod?.pizzaEdges?.length ?? 0) > 0 && (
                      <AccordionComplement title="Massa">
                        {prod?.pizzaEdges?.map((complement) => (
                          <Product key={complement.id}>
                            <div className="info">
                              <p>{complement.title}</p>
                              <p>
                                {formatToMoney((complement.unitPrice ?? 1) * complement?.amount)}
                              </p>
                            </div>
                          </Product>
                        ))}
                      </AccordionComplement>
                    )}
                  </>
                )}

                {(prod?.complementsGroups?.length ?? 0) > 0 && (
                  <AccordionComplement title="Complementos">
                    {prod.complementsGroups.map((compGroup) =>
                      compGroup.complements.map((complement) => (
                        <Product key={complement.id}>
                          <div className="info">
                            <p>{complement.title}</p>
                            <p>{formatToMoney((complement.unitPrice ?? 1) * complement?.amount)}</p>
                          </div>
                        </Product>
                      )),
                    )}
                  </AccordionComplement>
                )}
              </Product>
            ))}
          </GridProducts>

          <Values>
            <div className="right">
              <div className="value">
                <strong className="service">Taxa serviço</strong>
                <strong className="service">
                  {formatToMoney(Number(detailedCheck.serviceFee))}
                </strong>
              </div>
              <div className="value">
                <strong className="total">Total</strong>
                <strong className="total">{formatToMoney(Number(detailedCheck.total))}</strong>
              </div>
            </div>
          </Values>

          <ContainerButtons>
            <Button onClick={() => setOpenPayments(true)}>
              <p>Fechar a conta</p>
            </Button>
            <Button outline onClick={() => setOpen(false)}>
              <p>Voltar</p>
            </Button>
          </ContainerButtons>
        </Container>
      </Wrapper>
    </Modal>
  );
};

export default ModalDetailCheck;
