/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState, useContext, useCallback } from 'react';

import { AxiosResponse } from 'axios';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';

import ICard from '../models/ICard';
import IOrder from '../models/IOrder';
import ITable from '../models/ITable';

import IPostTableOrderDTO from '../dtos/IPostTableOrderDTO';
import IPostUnauthedOrderDTO from '../dtos/IPostUnauthedOrderDTO';
import { IPostDeliveryOrderDTO } from '../dtos/IPostDeliveryOrderDTO';

import api from '../services/api';
import { normalizeItem } from '../utils/orders';

interface OrderContextData {
  card: ICard;
  table: ITable;
  resetCard: (quit?: boolean) => void;
  resetTable: (quit?: boolean) => void;
  loadCard: () => Promise<void>;
  loadTable: () => Promise<void>;
  postDeliveryOrder: (data: IPostDeliveryOrderDTO) => Promise<IOrder>;
  postTableOrder: (data: IPostTableOrderDTO) => Promise<AxiosResponse>;
  postUnauthedDeliveryOrder: (data: IPostUnauthedOrderDTO) => Promise<IOrder>;
}

interface ITableResponse {
  tableNumber: number;
}

interface ICardResponse {
  cardNumber: number;
}

const OrderContext = createContext<OrderContextData>({} as OrderContextData);

interface IOrderProvider {
  children: React.ReactNode;
}
export const OrderProvider: React.FC<IOrderProvider> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [table, setTable] = useState<ITable>(() => {
    const previous = sessionStorage.getItem('@BSFOOD:table');

    if (previous) {
      return JSON.parse(previous) as ITable;
    }

    return {} as ITable;
  });

  const [card, setCard] = useState<ICard>(() => {
    const previous = sessionStorage.getItem('@BSFOOD:card');

    if (previous) {
      return JSON.parse(previous) as ICard;
    }

    return {} as ICard;
  });

  const loadTable = useCallback(async () => {
    try {
      const parsed = queryString.parse(location.search);

      const response = await api.get<ITableResponse>(`/hash?token=${parsed.token?.toString()}`);

      sessionStorage.setItem(
        '@BSFOOD:table',
        JSON.stringify({
          tableToken: parsed.token?.toString(),
          tableNumber: response.data.tableNumber,
        }),
      );

      setTable({
        tableToken: parsed.token?.toString() || '',
        tableNumber: response.data.tableNumber,
      });

      sessionStorage.setItem('@BSFOOD:table_session', Date.now().toString());
    } catch (err) {
      console.log((err as any)?.message);
    }
  }, [location.search]);

  const loadCard = useCallback(async () => {
    try {
      const parsed = queryString.parse(location.search);

      const response = await api.get<ICardResponse>(
        `/cards/hash?token=${parsed.token?.toString()}`,
      );

      sessionStorage.setItem(
        '@BSFOOD:card',
        JSON.stringify({
          cardToken: parsed.token?.toString(),
          cardNumber: response.data.cardNumber,
        }),
      );

      setCard({
        cardToken: parsed.token?.toString() || '',
        cardNumber: response.data.cardNumber,
      });

      sessionStorage.setItem('@BSFOOD:card_session', Date.now().toString());
    } catch (err) {
      console.log((err as any)?.message);
    }
  }, [location.search]);

  const postTableOrder = useCallback(
    async ({ items, tableNo, personAmount, cardNo }: IPostTableOrderDTO) => {
      return api.post('/table-orders', {
        items: items.map(normalizeItem),
        ...(cardNo && { cardNo }),
        tableNo,
        personAmount,
        orderType: 'ON_TABLE',
      });
    },
    [],
  );

  const postDeliveryOrder = useCallback(
    async ({
      type,
      total,
      items,
      address,
      comments,
      couponId,
      discount,
      customer,
      cashAdvance,
      deliveryFee,
      paymentType,
      phoneNumber,
      deliveryType,
      addressDefault,
      deliveryPlaceId,
      receiverDocument,
      scheduledProduct,
      cardBrandId,
    }: IPostDeliveryOrderDTO) => {
      const response = await api.post('/external-orders', {
        items: items.map(normalizeItem),
        total,
        comments,
        discount,
        deliveryFee,
        paymentType,
        deliveryType,
        addressDefault,
        scheduledProduct,
        cardBrandId,
        orderType: type,
        receiverName: customer?.name,
        cashAdvance: Number(cashAdvance),
        city: address.city,
        state: address.state,
        addressId: address.id,
        address2: address.address2,
        cityIbgeCode: address.ibgeCode,
        postalCode: address.postalCode,
        streetName: address.streetName,
        phoneNumber: address.phoneNumber,
        neighborhood: address.neighborhood,
        streetNumber: address.streetNumber,
        addressReference: address.reference,
        ...(couponId > 0 && { couponId }),
        ...(deliveryPlaceId > 0 && { deliveryPlaceId }),
        ...(receiverDocument && { receiverDocument }),
        ...(phoneNumber && { phoneNumber }),
      });

      return response.data;
    },
    [],
  );

  const postUnauthedDeliveryOrder = useCallback(
    async ({
      type,
      total,
      items,
      address,
      comments,
      discount,
      cashAdvance,
      deliveryFee,
      paymentType,
      phoneNumber,
      customerName,
      deliveryType,
      deliveryPlaceId,
      receiverDocument,
      scheduledProduct,
      cardBrandId,
    }: IPostUnauthedOrderDTO) => {
      const response = await api.post('/external-orders/unauthed', {
        items: items.map(normalizeItem),
        total,
        comments,
        discount,
        deliveryFee,
        paymentType,
        deliveryType,
        scheduledProduct,
        cardBrandId,
        orderType: type,
        receiverName: customerName,
        cashAdvance: Number(cashAdvance),
        city: address.city,
        state: address.state,
        addressId: address.id,
        address2: address.address2,
        cityIbgeCode: address.ibgeCode,
        postalCode: address.postalCode,
        streetName: address.streetName,
        phoneNumber: address.phoneNumber,
        neighborhood: address.neighborhood,
        streetNumber: address.streetNumber,
        addressReference: address.reference,
        ...(deliveryPlaceId > 0 && { deliveryPlaceId }),
        ...(receiverDocument && { receiverDocument }),
        ...(phoneNumber && { phoneNumber }),
      });

      return response.data;
    },
    [],
  );

  const resetTable = useCallback(
    (quit?: boolean) => {
      sessionStorage.removeItem('@BSFOOD:table');
      sessionStorage.removeItem('@BSFOOD:table_session');
      setTable({} as ITable);

      if (!quit) {
        navigate('/table');
      }
    },
    [navigate],
  );

  const resetCard = useCallback(
    (quit?: boolean) => {
      sessionStorage.removeItem('@BSFOOD:card');
      sessionStorage.removeItem('@BSFOOD:card_session');
      setCard({} as ICard);

      if (!quit) {
        navigate('/table');
      }
    },
    [navigate],
  );

  return (
    <OrderContext.Provider
      value={{
        card,
        table,
        loadCard,
        loadTable,
        resetCard,
        resetTable,
        postTableOrder,
        postDeliveryOrder,
        postUnauthedDeliveryOrder,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export function useOrder(): OrderContextData {
  const context = useContext(OrderContext);

  if (!context) {
    throw new Error('useOrder must be used within OrderProvider');
  }

  return context;
}
