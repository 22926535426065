import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

interface IActiveProp {
  isActive?: boolean;
}

export const SizeButton = styled.button<IActiveProp>`
  position: relative;
  border: none;
  padding: 0.5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  margin: 0 0.5rem;
  background: ${({ theme, isActive }) =>
    isActive ? theme.palette.primary : theme.palette.control};
  opacity: ${({ isActive }) => (isActive ? 1 : 0.8)};
`;

export const SizeButtonText = styled.span<IActiveProp>`
  font-size: 1rem;
  color: ${({ theme, isActive }) =>
    isActive ? theme.palette.primary_contrast : theme.palette.black};
`;

const iconAnimationKeyframes = keyframes`
  from { transform: scale(0) rotateZ(-90deg); }
  to { transform: none; }
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 0.4rem;
  top: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: 1rem;
  height: 1rem;
  animation: ${iconAnimationKeyframes} 400ms cubic-bezier(0.56, 0.2, 0, 1.26);

  svg {
    width: 100%;
    height: 100%;

    fill: ${({ theme }) => theme.palette.primary_contrast};
  }
`;
