import styled from 'styled-components';

export const AccordionWrapper = styled.div`
  width: 100%;

  border-radius: 8px;
  overflow: hidden;
`;

export const AccordionHeader = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  text-align: center;
  gap: 5px;
`;

export const AccordionContent = styled.div<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? '200px' : '0')};
  overflow: hidden;
  transition: 600ms;
  padding: 0 16px;
  border-left: 1px solid;
`;

export const ChevronIcon = styled.span<{ isOpen: boolean }>`
  display: flex;
  height: auto;
  transition: 600ms;
  transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0')});
`;
