import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 70px;

  justify-content: center;
`;
export const Container = styled.div`
  display: flex;
  padding: 18px;
  justify-content: center;
  align-items: center;

  @media (max-width: 700px) {
    padding: 12px;
  }

  background-color: #4db6ac;

  border-radius: 4px;
  gap: 0.25rem;
  justify-content: center;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;

  h1 {
    color: ${({ theme }) => theme.palette.background_white};

    @media (max-width: 700px) {
      font-size: 28px;
    }
  }
`;
