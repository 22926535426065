/* eslint-disable @typescript-eslint/indent */
import styled, { keyframes, css } from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import CompanyNameLoader from '../../components/Loaders/CompanyNameLoader';

interface IContainerProps {
  showcase: boolean;
}

interface IHeaderProps {
  showcase: boolean;
}

interface ITabContainerProps {
  showcase: boolean;
  isSubcategoryTab?: boolean;
}

interface IProductsContainerProps {
  visible: boolean;
  companyOpen: boolean;
}

interface IContentProps {
  visible: boolean;
}

interface IFooterProps {
  visible: boolean;
  hasMarginBottom: boolean;
}

interface ICategoriesContainerProps {
  visible: boolean;
}

interface ICategorySubcategoryTitleProps {
  isSubcategory?: boolean;
}

interface ICategoriesHeaderProps {
  visible: boolean;
}

interface IScrollToTopButtonProps {
  visible?: boolean;
}

interface IAddressIcon {
  dismissable?: boolean;
}

interface IIsFocused {
  isFocused?: boolean;
}

interface IScrollToTopButton {
  right?: boolean;
  size?: 'BIG' | 'SMALL';
}

interface IDistanceMessageContainer {
  visible: boolean;
}

const dropFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-40px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div<IContainerProps>`
  min-height: var(--window-height);
  background: ${({ theme }) => theme.palette.background};

  display: flex;
  overflow: hidden;
  flex-direction: column;

  ${({ showcase }) =>
    !showcase &&
    css`
      margin-right: 475px;
    `}

  @media (max-width: 1150px) {
    margin-right: 0;
  }

  @media (min-width: 1151px) {
    height: var(--window-height);
  }
`;

export const Content = styled.div<IContentProps>`
  max-height: var(--window-height);
  position: relative;

  flex: 1;
  display: flex;
  flex-direction: column;

  padding-top: 93px;

  @media (max-width: 1150px) {
    display: flex;
  }
`;

export const Header = styled.header<IHeaderProps>`
  height: 93px;
  max-height: 93px;
  display: flex;

  z-index: 999;
  align-items: center;
  justify-content: space-between;

  left: 0;
  top: 1px;
  position: fixed;
  padding: 16px 32px;

  box-shadow: 0 4px 16px 0px rgba(0, 0, 0, 0.1);
  background: ${({ theme }) => theme.palette.control_lighter};
  width: 100%;

  ${({ showcase }) =>
    !showcase &&
    css`
      width: unset;
      right: 475px;
    `}

  @media (max-width: 1150px) {
    right: 0;
  }

  @media (max-width: 650px) {
    padding: 16px;
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  align-items: center;

  border-radius: 8px;

  border: 2px solid #ccc5;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03);

  @media (max-width: 650px) {
    display: none;
  }
`;

export const AddressIcon = styled.div<IAddressIcon>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px;
  cursor: pointer;

  ${({ dismissable }) =>
    dismissable &&
    css`
      @media (max-width: 430px) {
        display: none;
      }
    `}
`;

export const AddressWrapper = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;

  border-right: 2px solid #ccc5;
`;

export const AddressTitle = styled.div`
  color: #aaa;
  font-size: 12px;
`;

export const Address = styled.span`
  font-size: 15px;
`;

export const SmallAddressContainer = styled.div`
  flex: 1;
  height: 100px;

  display: none;
  align-items: center;

  background: ${({ theme }) => theme.palette.control_lighter};
  box-shadow: 0 4px 16px 0px rgba(0, 0, 0, 0.1);

  margin-top: -42px;
  margin-left: 32px;

  padding: 12px;
  padding-top: 32px;

  border-radius: 0 0 8px 8px;

  span {
    font-size: 13px;
  }

  @media (max-width: 650px) {
    display: flex;
  }

  @media (max-width: 430px) {
    span {
      font-size: 12px;
    }

    strong {
      font-size: 13px;
    }
  }
`;

export const SmallAddressWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  margin-right: 8px;
  border-right: 2px solid #ccc5;

  @media (max-width: 510px) {
    border-right: 0;
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  > svg {
    width: 24px;
    height: 24px;
  }

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const MinValue = styled.div`
  height: 100px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;

  background: ${({ theme }) => theme.palette.control_lighter};
  box-shadow: 0 4px 16px 0px rgba(0, 0, 0, 0.1);

  margin-top: -42px;
  margin-left: 16px;
  margin-right: 32px;

  padding: 12px;
  padding-top: 32px;

  border-radius: 0 0 8px 8px;

  span {
    font-size: 13px;
  }

  @media (max-width: 500px) {
    margin-right: 16px;
  }

  @media (max-width: 430px) {
    span {
      font-size: 12px;
    }

    strong {
      font-size: 13px;
    }
  }
`;

export const TabsContainer = styled.div<ITabContainerProps>`
  display: flex;

  top: 0;

  ${({ isSubcategoryTab }) =>
    !isSubcategoryTab &&
    css`
      position: sticky;
      z-index: 9;
    `}

  overflow-x: auto;
  max-width: calc(100vw - 481px);

  animation: ${dropFromTop} 0.4s 1 cubic-bezier(0.19, 1.16, 0.27, 0.98) backwards;

  @media (max-width: 1150px) {
    right: 0;
    max-width: calc(100vw);
  }

  ${({ showcase }) =>
    showcase &&
    css`
      max-width: 100vw;
      width: 100vw;
    `}

  @media (min-width: 430px) {
    width: 100%;
    position: fixed;
    top: 90px;
  }
`;

export const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: calc(-1vw + 32px);
`;

export const CompanyNamePlaceholder = styled(CompanyNameLoader)`
  display: flex;
  align-items: center;

  @media (max-width: 1150px) {
    margin-right: 0px;
  }
`;

export const Companies = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.palette.black};
    margin-right: 12px;
  }
`;

export const Border = styled.div`
  width: 1.5px;
  height: 100%;
  margin-left: 16px;
  background: ${({ theme }) => theme.palette.black};

  @media (max-width: 500px) {
    display: none;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
`;

export const OurLogo = styled(LazyLoadImage)`
  width: 70px;
  height: 70px;

  @media (max-width: 387px) {
    width: 50px;
    height: 50px;
    margin-right: 16px;
  }
`;

export const CompanyLogo = styled(LazyLoadImage)`
  width: 70px;
  height: 70px;
  margin-left: 16px;

  @media (max-width: 500px) {
    display: none;
  }
`;

export const CompanyName = styled.div<IIsFocused>`
  display: flex;
  font-size: 24px;
  align-self: flex-end;
  cursor: pointer;
  border-bottom: 2px solid ${({ theme }) => theme.palette.primary};

  transform: ${({ isFocused }) => (isFocused ? 'translate(-4vw)' : 'none')};
  transition: opacity 150ms ease-out, 100ms ease;
  will-change: opacity;
  opacity: ${({ isFocused }) => (isFocused ? '0' : '1')};

  strong {
    max-width: 350px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;

    overflow: hidden;

    @media (min-width: 701px) {
      max-width: 350px;
    }
  }

  @media (max-width: 1150px) {
    margin-right: 0px;
  }

  @media (max-width: 700px) {
    font-size: 18px;
  }

  @media (max-width: 500px) {
    margin-left: 0px;
  }
`;

export const CategoryName = styled.strong`
  width: 220px;

  font-size: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CartButton = styled.button`
  width: 40px;
  height: 40px;

  display: none;
  align-items: center;
  justify-content: flex-end;

  border: 0;
  outline: 0;
  background: none;

  position: relative;

  @media (max-width: 1150px) {
    display: flex;
  }
`;

export const Badges = styled.div`
  margin-bottom: 32px;
`;

export const Badge = styled.span`
  width: 19px;
  height: 19px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2px;
  font-size: 10px;
  border-radius: 50%;

  color: ${({ theme }) => theme.palette.primary_contrast};
  background: ${({ theme }) => theme.palette.badge};

  top: 0;
  right: -8px;
  position: absolute;
`;

export const Icons = styled.div`
  display: flex;
`;

export const Search = styled.div`
  flex: 1;
  display: flex;

  margin-right: 0;
  padding: 8px 16px;
  margin: 16px 32px;

  border-radius: 8px;
  background: ${({ theme }) => theme.palette.control_lighter};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow};

  svg {
    width: 25px;
    height: 25px;
    margin-right: 16px;
    color: ${({ theme }) => theme.palette.black};
  }

  input {
    width: 100%;
    border: 0;
    color: #a8a8a8;
    background: none;
  }

  input + svg {
    margin-right: 0;
    margin-left: 8px;
    cursor: pointer;
  }

  @media (max-width: 650px) {
    display: none;
  }
`;

export const SearchSmall = styled.div<IIsFocused>`
  flex: 1;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  padding: 24px 16px;
  margin-left: 8px;
  margin-top: 12px;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.control_lighter};
  background: transparent;
  width: calc(100% - 55px);

  transform: translateY(-10vh);
  transition: transform ease 240ms, opacity 120ms ease;
  will-change: transform opacity;
  background: ${({ theme }) => theme.palette.background};

  ${({ isFocused }) =>
    isFocused &&
    css`
      opacity: 1;
      transform: none;
    `}
  svg {
    width: 25px;
    height: 25px;
    margin-right: 16px;
    color: ${({ theme }) => theme.palette.black};
  }

  input {
    width: 100%;

    border: 0;
    color: #a8a8a8;
    background: none;
  }

  input + svg {
    margin-right: 0;
    margin-left: 8px;
    cursor: pointer;
  }

  @media (max-width: 650px) {
    display: flex;
  }
`;

export const SearchButton = styled.label<IIsFocused>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  border: 0;
  outline: 0;

  @media (min-width: 650px) {
    display: none;
  }
`;

export const ShareButton = styled.button`
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  border: 0;
  outline: 0;
  background: none;
`;

export const Share = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;

  svg {
    width: 25px;
    height: 25px;
    color: ${({ theme }) => theme.palette.black};
  }

  @media (max-width: 1150px) {
    margin-right: 16px;
  }

  @media (min-width: 1151px) {
    display: none;
  }
`;

export const Main = styled.main`
  /* min-height: calc(var(--window-height) - 42px);
  max-height: calc(var(--window-height) - 42px); */
  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-width: 500px) {
    max-height: var(--window-height);
    height: auto;
    margin-top: 0;
    position: relative;
  }
`;

export const CategoriesContainer = styled.div<ICategoriesContainerProps>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  position: relative;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 430px) {
    display: none;
  }
`;

export const CategorySubcategoryTitle = styled.div<ICategorySubcategoryTitleProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 16px 32px;

  ${({ isSubcategory }) =>
    isSubcategory &&
    css`
      margin-bottom: 0;
      padding-top: 0;
    `}

  strong {
    font-size: 18px;
  }

  button {
    border: 0;
    background: none;
    color: ${({ theme }) => theme.palette.black};

    @media (max-width: 430px) {
      padding: 2px 8px;
      border-radius: 8px;
      background: ${({ theme }) => theme.palette.primary};
      color: ${({ theme }) => theme.palette.primary_contrast};
    }
  }

  @media (max-width: 500px) {
    padding: 16px;

    ${({ isSubcategory }) =>
      isSubcategory &&
      css`
        padding-top: 0;
      `}
  }

  @media (max-width: 430px) {
    padding-bottom: 8px;
    margin-bottom: 0;
  }
`;

export const CategoryHeader = styled.h3<ICategoriesHeaderProps>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
`;

export const CategoriesSubcategories = styled.div`
  display: flex;
  align-content: flex-start;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  > button + button {
    margin-left: 16px;
  }

  .start {
    min-width: 32px;
    height: 4px;

    @media (max-width: 500px) {
      min-width: 16px;
    }
  }

  .end {
    min-width: 32px;
    height: 4px;

    @media (max-width: 500px) {
      min-width: 16px;
    }
  }
`;

export const SubcategoriesSummary = styled.div`
  margin-top: 16px;
`;

export const SubcategoryName = styled.button`
  width: 100%;

  display: flex;
  align-items: center;

  padding: 8px;
  margin-top: 8px;

  border: 0;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.control_lighter};

  button {
    border: 0;
    background: none;
    margin-left: 8px;
  }
`;

export const Subcategories = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const Subcategory = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const SubcategoryTitle = styled.div`
  width: 100%;
  scroll-margin-top: 180px;

  display: flex;
  align-items: center;

  background: ${({ theme }) => theme.palette.primary};

  font-size: 20px;
  padding: 16px 8px;
  border-radius: 8px;

  box-shadow: ${({ theme }) => theme.shadow.box_shadow};

  img {
    height: 40px;
    width: 40px;
    margin-left: 8px;
    margin-right: 16px;
  }
  margin-bottom: 16px;

  span {
    color: ${({ theme }) => theme.palette.primary_contrast};
  }

  @media (max-width: 700px) {
    padding: 10px;
    font-size: 18px;

    img {
      height: 28px;
      width: 28px;
    }
  }
`;

interface ICollapsibleButtonProps {
  isOpen: boolean;
}

export const CollapsibleButton = styled.button<ICollapsibleButtonProps>`
  margin-left: auto;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 32px;
  padding: 8px;

  :hover {
    background: rgba(0, 0, 0, 0.06);
  }

  svg {
    stroke: ${({ theme }) => theme.palette.primary_contrast};
    transition: transform 400ms ease;
    transform: ${({ isOpen }) => (isOpen ? 'none' : 'rotateZ(180deg)')};
  }
`;

export const SubcategoryGroupTitle = styled(SubcategoryTitle)`
  background: transparent;
  border: solid 2px ${({ theme }) => theme.palette.primary};
  padding: 8px 12px;
  font-size: 16px;

  span {
    color: ${({ theme }) => theme.palette.primary};
  }
`;

export const NoSubcategoryTitle = styled.div`
  display: flex;
  align-items: center;

  border-radius: 8px;

  width: 100%;
  color: ${({ theme }) => theme.palette.white};
  background: ${({ theme }) => theme.palette.primary};
  padding: 16px 8px;
  margin-top: 16px;
  font-size: 20px;
  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);

  scroll-margin: 20px;

  img {
    height: 40px;
    width: 40px;
    margin-right: 16px;
  }

  margin-bottom: 16px;

  @media (max-width: 700px) {
    padding: 10px;
    font-size: 18px;

    img {
      height: 28px;
      width: 28px;
    }
  }

  span {
    color: ${({ theme }) => theme.palette.primary_contrast};
  }
`;

export const ProductsContainer = styled.div<IProductsContainerProps>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;

  padding: 16px 32px;
  margin-bottom: 64px;

  ${({ companyOpen }) =>
    !companyOpen &&
    css`
      padding-bottom: 72px;
    `}

  @media (max-width: 520px) {
    padding: 16px;
    margin-bottom: 0;
  }
`;

export const ProductsTitle = styled.div`
  margin-bottom: 16px;

  strong {
    font-size: 18px;
  }

  span {
    font-size: 14px;
  }
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  outline: 0;
  border: 0;
  background: none;

  svg {
    margin-right: 16px;
    height: 24px;
    width: 24px;
  }
`;

export const ProductsHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;

  svg {
    margin-right: 16px;
  }
`;

export const Products = styled.div`
  height: 100%;

  flex: 1;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 16px;
`;

export const ProductPlaceholder = styled.div`
  height: 0;

  width: calc(100% / 3 - 12px);

  @media (max-width: 1780px) {
    width: calc(100% / 2 - 12px);
  }

  @media (max-width: 1280px) {
    width: 100%;
  }
`;

export const Customer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Welcome = styled.span`
  width: 100%;
  font-size: 12px;
  text-align: right;
`;

export const CustomerName = styled.span`
  font-size: 18px;
`;

export const NothingFound = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 128px;
`;

export const Footer = styled.footer<IFooterProps>`
  width: 100%;
  max-height: 120px;

  display: flex;
  justify-content: space-between;

  margin-top: 16px;
  padding: 8px 32px;

  left: 0;
  bottom: 0;
  position: absolute;

  z-index: 999;

  background: ${({ theme }) => theme.palette.white};
  border-top: 2px solid ${({ theme }) => theme.palette.control_lighter};
  border-top: 1px solid ${({ theme }) => theme.palette.outline};

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.primary};

    @media (max-width: 520px) {
      text-align: center;
    }
  }

  @media (max-width: 1150px) {
    display: ${({ visible }) => (visible ? 'flex' : 'none')};

    position: fixed;
    bottom: 0;
  }

  @media (max-width: 520px) {
    position: relative;
    flex-direction: column;
    max-height: 80px;
    font-size: 14px;
    z-index: auto;

    ${({ hasMarginBottom }) =>
      hasMarginBottom &&
      css`
        margin-bottom: 50px;
      `}
  }

  span.company {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-right: auto;
    margin-left: 16px;
  }
`;

export const FinishButtonContainer = styled.div`
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 38px;
  position: absolute;

  * {
    color: ${({ theme }) => theme.palette.primary_contrast};
  }

  @media (max-width: 1150px) {
    position: fixed;
  }

  @media (max-width: 520px) {
    bottom: 0;
  }
`;

export const FinishButton = styled.button`
  width: 100%;
  height: 50px;
  max-height: 50px;

  display: flex;
  align-items: center;

  border: 0;
  border-radius: 8px 8px 0 0;
  color: ${({ theme }) => theme.palette.white};
  background: ${({ theme }) => theme.palette.primary};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};

  div {
    svg {
      margin-left: 16px;
    }

    display: flex;
    align-items: center;
  }

  div.cart-text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  span {
    font-size: 17px;
    margin: 0 16px 0 12px;
  }

  span + span {
    justify-self: flex-end;
  }

  @media (min-width: 1151px) {
    display: none;
    visibility: none;
  }
`;

export const IconContainer = styled.div``;

export const DoneBy = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin: 0 6px;
    fill: ${({ theme }) => theme.palette.primary};
  }

  a {
    margin-left: 6px;
  }

  @media (max-width: 520px) {
    margin-top: 8px;
    justify-content: center;
  }
`;

const appearFromRight = keyframes`
  from {
    transform: translateX(110%);
  }
  to{
    transform: translateX(0);
  }
`;

const hideToRight = keyframes`
  from {
    transform: translateX(-116px);
  }
  to{
    transform: translateX(0);
  }
`;

export const ScrollToTopButton = styled.button<IScrollToTopButtonProps>`
  width: 50px;
  height: 50px;

  bottom: 60px;
  right: -100px;
  position: fixed;
  border-radius: 50%;

  z-index: 1000;

  border: 0;
  outline: 0;
  background: ${({ theme }) => theme.palette.primary};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow};

  svg {
    stroke: ${({ theme }) => theme.palette.primary_contrast};
    transform: translateY(3px);
  }

  ${({ visible }) =>
    visible
      ? css`
          animation: ${appearFromRight} 0.5s;
          right: calc(475px + 16px);

          @media (max-width: 1150px) {
            right: 16px;
          }
        `
      : css`
          animation: ${hideToRight} 0.5s;
          right: -100px;
        `}

  @media(min-width: 521px) {
    bottom: 104px;
  }
`;

export const ScrollCategorySubcategoryButton = styled.button<IScrollToTopButton>`
  border: none;
  background: ${({ theme }) => theme.palette.background};
  width: 42px;
  height: 42px;
  border-radius: 21px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 42px);
  bottom: 0;
  margin: auto 0;
  border: 1px solid ${({ theme }) => theme.palette.outline};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow};

  ${({ size }) =>
    size === 'SMALL' &&
    css`
      width: 35px;
      height: 35px;
      top: calc(50% - 20px);
    `}

  ${({ right }) =>
    !right
      ? css`
          left: 8px;
          right: 0;
        `
      : css`
          left: calc(100% - 52px);
        `}

  @media (max-width: 500px) {
    display: none;
  }
`;

export const HighlightsContainer = styled.div`
  height: 50vh;
  width: calc(100% - 48px);

  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;

  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 16px;

  @media (max-width: 500px) {
    width: calc(100% - 32px);
  }
`;

interface IHighlightBlur {
  uri?: string;
  hasImage?: boolean;
}

export const Highlight = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  transition: transform ease 460ms;
  display: flex;
  justify-content: center;
  align-items: center;

  .image-container {
    position: absolute;
    top: 10px;
    right: 0;
    overflow: hidden;
    z-index: 99;

    svg {
      .primary {
        fill: ${({ theme }) => theme.palette.primary};
      }

      height: 75px;

      transform: translateX(10px);
    }
  }

  &:hover {
    transform: scale(1.02);
  }

  .product-icon {
    height: 100%;
    min-width: 50%;
    max-width: 50%;
    z-index: 5;
    object-fit: cover;
  }

  @media (max-width: 500px) {
    flex-direction: column-reverse;
    .product-icon {
      position: absolute;
      min-width: 100%;
      max-width: 100%;
      z-index: 4;
    }
  }

  * {
    color: #fff;
  }
`;

export const HighlightBlur = styled.div<IHighlightBlur>`
  background: #fff;
  background-image: url(${({ uri }) => uri});
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  filter: blur(3px) ${({ hasImage }) => (hasImage ? 'brightness(0.5)' : 'brightness(0.6)')};

  @media (max-width: 500px) {
    background: transparent;
    height: 75%;
    bottom: 0;
    z-index: 5;
    filter: none;
    background-image: none;
    backdrop-filter: blur(3px) brightness(0.6);
    will-change: filter backdrop-filter;
  }
`;

export const HighlightText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  z-index: 8;
  padding: 48px;
  padding-bottom: 0;
  transition: opacity 500ms ease-out, transform 280ms ease;

  h1 {
    font-size: 30px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  p {
    font-size: 22px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  button {
    border: none;
    padding: 4px 16px;
    background: ${({ theme }) => theme.palette.primary};
    color: ${({ theme }) => theme.palette.primary_contrast};
    border-radius: 6px;
    align-self: flex-end;
    box-shadow: ${({ theme }) => theme.shadow.box_shadow_large};
    margin-top: 12px;
  }

  @media (max-width: 500px) {
    z-index: 6;
    height: 75%;
    bottom: 0;
    padding: 24px;
    padding-top: 48px;
    margin-top: 16px;

    h1 {
      font-size: 28px;
    }

    p {
      font-size: 16px;
    }

    span {
      font-size: 24px;
    }

    button {
      margin-top: 8px;
      font-size: 18px;
    }
  }
`;

export const HighlightPrice = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 8px;

  .suggestedPrice {
    font-size: 1.5rem;
  }

  .salePrice {
    font-size: 1.5rem;
  }

  &.hasSale {
    .suggestedPrice {
      font-size: 1rem;
      margin-bottom: -6px;
      opacity: 0.8;

      span {
        text-decoration: line-through;
        font-size: 1rem;
      }
    }
  }
`;

export const BestSellingContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 24px;

  h1 {
    width: 100%;
    font-size: 18px;
    margin-bottom: 16px;
  }
`;

export const PaddingBottom = styled.div`
  height: 168px;

  @media (max-width: 1150px) {
    height: 16px;
  }
`;

export const DistanceMessageContainer = styled.div<IDistanceMessageContainer>`
  ${({ visible }) =>
    visible
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}

  margin: 0 32px;
  margin-bottom: 16px;
`;

export const ClosedCompany = styled.div`
  width: 100%;
  height: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 8px 8px 0 0;
  color: ${({ theme }) => theme.palette.white};
  background: ${({ theme }) => theme.palette.primary};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};

  span {
    font-size: 17px;
    margin: 0 16px 0 16px;
  }
`;
