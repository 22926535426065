/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import { useCompany } from '../hooks/company';
import lazyWithRetry from '../utils/react';

const PrivateRoute = lazyWithRetry(() =>
  import('../components/PrivateRoute').then((module) => ({ default: module.default })),
);

// Importações de páginas com lazyWithRetry
const ScanPage = lazyWithRetry(() => import('../pages/ScanPage'));
const LinkPage = lazyWithRetry(() => import('../pages/LinkPage'));
const ErrorPage = lazyWithRetry(() => import('../pages/ErrorPage'));
const TablePage = lazyWithRetry(() => import('../pages/TablePage'));
const SignInPage = lazyWithRetry(() => import('../pages/SignInPage'));
const FinishPage = lazyWithRetry(() => import('../pages/FinishPage'));
const ProfilePage = lazyWithRetry(() => import('../pages/ProfilePage'));
const LoadingPage = lazyWithRetry(() => import('../pages/LoadingPage'));
const HistoryPage = lazyWithRetry(() => import('../pages/HistoryPage'));
const ProductsPage = lazyWithRetry(() => import('../pages/ProductsPage'));
const OrderSentPage = lazyWithRetry(() => import('../pages/OrderSentPage'));
const ServerErrorPage = lazyWithRetry(() => import('../pages/ServerErrorPage'));
const PrivacyPolicyPage = lazyWithRetry(() => import('../pages/PrivacyPolicyPage'));

const SuspenseWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <React.Suspense fallback={<ProductsPage />}>{children}</React.Suspense>
);

const AppRoutes: React.FC = () => {
  const { customer } = useAuth();
  const { isShowcase } = useCompany();

  // Componente auxiliar para Suspense
  return (
    <Routes>
      {/* Rotas públicas com Suspense */}
      <Route
        path="/"
        element={
          <React.Suspense fallback={<ProductsPage />}>
            <ProductsPage />
          </React.Suspense>
        }
      />

      {/* Rota privada para carrinho */}
      <Route
        path="/cart"
        element={
          <React.Suspense fallback={<ProductsPage />}>
            <PrivateRoute redirect="/" authed={!isShowcase}>
              <ProductsPage />
            </PrivateRoute>
          </React.Suspense>
        }
      />

      {/* Demais rotas públicas */}
      <Route
        path="/privacy-policy"
        element={
          <React.Suspense fallback={<ProductsPage />}>
            <PrivacyPolicyPage />
          </React.Suspense>
        }
      />

      {/* Rotas restantes seguindo o mesmo padrão */}
      <Route
        path="/filter"
        element={
          <SuspenseWrapper>
            <ProductsPage />
          </SuspenseWrapper>
        }
      />
      <Route
        path="/product"
        element={
          <SuspenseWrapper>
            <ProductsPage />
          </SuspenseWrapper>
        }
      />
      <Route
        path="/category"
        element={
          <SuspenseWrapper>
            <ProductsPage />
          </SuspenseWrapper>
        }
      />
      <Route
        path="/scan"
        element={
          <SuspenseWrapper>
            <ScanPage />
          </SuspenseWrapper>
        }
      />
      <Route
        path="/hash"
        element={
          <SuspenseWrapper>
            <LoadingPage />
          </SuspenseWrapper>
        }
      />
      <Route
        path="/cards/hash"
        element={
          <SuspenseWrapper>
            <LoadingPage />
          </SuspenseWrapper>
        }
      />
      <Route
        path="/loading"
        element={
          <SuspenseWrapper>
            <LoadingPage />
          </SuspenseWrapper>
        }
      />

      <Route
        path="/signin"
        element={
          <SuspenseWrapper>
            <SignInPage />
          </SuspenseWrapper>
        }
      />
      <Route
        path="/session-expired"
        element={
          <SuspenseWrapper>
            <SignInPage />
          </SuspenseWrapper>
        }
      />
      <Route
        path="/server-error"
        element={
          <SuspenseWrapper>
            <ServerErrorPage />
          </SuspenseWrapper>
        }
      />
      <Route
        path="/finish"
        element={
          <SuspenseWrapper>
            <FinishPage />
          </SuspenseWrapper>
        }
      />
      <Route
        path="/order-sent"
        element={
          <SuspenseWrapper>
            <OrderSentPage />
          </SuspenseWrapper>
        }
      />
      <Route
        path="/error"
        element={
          <SuspenseWrapper>
            <ErrorPage />
          </SuspenseWrapper>
        }
      />
      <Route
        path="/links"
        element={
          <SuspenseWrapper>
            <LinkPage />
          </SuspenseWrapper>
        }
      />
      <Route
        path="/about-us"
        element={
          <SuspenseWrapper>
            <HistoryPage />
          </SuspenseWrapper>
        }
      />
      <Route
        path="/table"
        element={
          <SuspenseWrapper>
            <TablePage />
          </SuspenseWrapper>
        }
      />

      {/* Rota privada para perfil */}
      <Route
        path="/me"
        element={
          <React.Suspense fallback={<ProductsPage />}>
            <PrivateRoute redirect="/signin" authed={!!customer}>
              <ProfilePage />
            </PrivateRoute>
          </React.Suspense>
        }
      />

      {/* Redirecionamento padrão */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;
