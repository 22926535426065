import React from 'react';

import Container from './styles';

const CompanyNameLoader: React.FC = () => {
  return (
    <Container>
      <rect x="0" y="0" rx="3" ry="3" width="250" height="40" />
    </Container>
  );
};

export default CompanyNameLoader;
