import React from 'react';

import { AuthProvider } from './auth';
import { CartProvider } from './cart';
import { OrderProvider } from './order';
import { ToastProvider } from './toast';
import { CouponProvider } from './coupon';
import { CompanyProvider } from './company';
import { ProductsProvider } from './products';
import { CategoriesProvider } from './categories';

import { ProductGroupsProvider } from './product-groups';
import { ServiceCommandProvider } from './service-commands';
import { PixProvider } from './pix';

interface IAppProvider {
  children: React.ReactNode;
}

const AppProvider: React.FC<IAppProvider> = ({ children }) => (
  <ToastProvider>
    <CompanyProvider>
      <AuthProvider>
        <CategoriesProvider>
          <ProductGroupsProvider>
            <ProductsProvider>
              <CartProvider>
                <OrderProvider>
                  <CouponProvider>
                    <ServiceCommandProvider>
                      <PixProvider>{children}</PixProvider>
                    </ServiceCommandProvider>
                  </CouponProvider>
                </OrderProvider>
              </CartProvider>
            </ProductsProvider>
          </ProductGroupsProvider>
        </CategoriesProvider>
      </AuthProvider>
    </CompanyProvider>
  </ToastProvider>
);

export default AppProvider;
