import React, { useEffect, useState } from 'react';
import { Container, Wrapper } from './styles';
import { useToast } from '../../hooks/toast';

interface IPixCounterProps {
  handleClose: () => Promise<void>;
}
const PixCounter: React.FC<IPixCounterProps> = ({ handleClose }) => {
  const { addToast } = useToast();
  const [time, setTime] = useState(300);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [handleClose]);

  if (time <= 0) {
    handleClose();
    addToast({ description: 'O código PIX gerado expirou!', type: 'error' });
  }

  const minutes = Math.floor(time / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (time % 60).toString().padStart(2, '0');
  return (
    <Wrapper>
      <Container>
        <h1>{minutes}</h1>
        <h1>:</h1>
        <h1>{seconds}</h1>
      </Container>
    </Wrapper>
  );
};

export default PixCounter;
