/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useContext, createContext, useCallback } from 'react';
import api from '../services/api';
import PoolingCommand from '../enum/PoolingCommand';

export interface IPooling {
  eventType: string;
  hash: string;
  status: string;
}

interface ServiceCommandData {
  getCheckBill: (hash: string) => Promise<void>;
  getCallWaiter: (hash: string) => Promise<void>;
  getCleanTable: (hash: string) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDetailedBill: (hash: string) => Promise<any>;
  pooling: (hash: string, command: PoolingCommand) => Promise<IPooling>;
  deletePooling: (hash: string, command: PoolingCommand) => Promise<IPooling>;
}

const ServiceCommand = createContext<ServiceCommandData>({} as ServiceCommandData);

interface IServiceCommandProvider {
  children: React.ReactNode;
}

export const ServiceCommandProvider: React.FC<IServiceCommandProvider> = ({ children }) => {
  const getCallWaiter = useCallback(async (hash: string) => {
    await api.post(`/table-actions/call-waiter/${hash}`, {
      headers: { hash },
    });
  }, []);

  const pooling = useCallback(async (hash: string, command: PoolingCommand): Promise<IPooling> => {
    const response = await api.get(`/table-actions/pooling/${hash}/event/${command}`);
    return response.data as IPooling;
  }, []);

  const deletePooling = useCallback(
    async (hash: string, command: PoolingCommand): Promise<IPooling> => {
      const response = await api.delete(`/table-actions/pooling/${hash}/event/${command}`);
      return response.data as IPooling;
    },
    [],
  );
  const getCheckBill = useCallback(async (hash: string) => {
    const { data } = await api.post(`/table-actions/check-bill/${hash}`, {
      headers: { hash },
    });
    return data;
  }, []);

  const getDetailedBill = useCallback(async (hash: string) => {
    const { data } = await api.get(`/table-actions/detailed-bill/${hash}`, {
      headers: { hash },
    });
    return data;
  }, []);
  const getCleanTable = useCallback(async (hash: string) => {
    await api.post(`/table-actions/clean-table/${hash}`, {
      headers: { hash },
    });
  }, []);

  return (
    <ServiceCommand.Provider
      value={{
        pooling,
        getCheckBill,
        getCallWaiter,
        getCleanTable,
        deletePooling,
        getDetailedBill,
      }}
    >
      {children}
    </ServiceCommand.Provider>
  );
};

export function useServiceCommand(): ServiceCommandData {
  const context = useContext(ServiceCommand);

  if (!context) {
    throw new Error('useServiceCommand must be used within ServiceCommandProvider');
  }

  return context;
}
